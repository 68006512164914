import React, { useEffect } from "react";
import NotificationToast from "./NotificationToast/NotificationToast";
import useStrings from "../../hooks/useStrings";
import { useStore } from "../../providers/StoreContext";
import { Notice } from "../../store/notifications/type";
import { observer } from "mobx-react-lite";

function Notifications() {
  const store = useStore();
  const { notice } = useStrings(store);
  const notifications = store.notifications.notifications;

  useEffect(() => {
    store.notifications.getAllNotifications();
  }, []);

  return (
    <div
      style={{
        maxHeight: "400px",
        overflow: "auto",
      }}
    >
      {notifications.length > 0 ? (
        notifications.map((notice, index: number) => (
          <NotificationToast key={index} notice={notice} />
        ))
      ) : (
        <h5>{notice.noNotifications}</h5>
      )}
    </div>
  );
}

export default observer(Notifications);
