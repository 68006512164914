import * as yup from 'yup';

export const userValidationSchema = yup.object().shape({
  isAdmin: yup.boolean().required(),

  login: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  email: yup
    .string()
    .email('Please enter a valid email format !')
    .required('field is required please !'),

  fam: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  name: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  otch: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  birthday: yup
    .date()
    .required('field is required please !'),

  position: yup
    .string()
    .max(20, 'the value is too long')
    .required('field is required please !'),

  user_lexema8: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .uuid('field have uuid type')
    .optional(),

  guid_license: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .uuid('field have uuid type')
    .optional(),
});