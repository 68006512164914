import React from "react";
import useInput from "../../hooks/useInput";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { useStore } from "../../providers/StoreContext";

type result = {
  message: string
}

const RecoveryPassword = () => {
  const login = useInput();
  const email = useInput();
  const history = useHistory();
  const store = useStore();

  const validateForm = () => login.value.length > 0 && email.value.length > 0;

  const queryPasswordRecovery = () => {
    const http = injectHttpClient();
    return http.post("/api/auth/password_recovery", {
      login: login.value,
      email: email.value,
    }).then((result) => {
      const { message } = result as result;
      store.alert.info(message);
      return result;
    }).catch((err) => {
      store.alert.error(err.message);
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resPassRec = await queryPasswordRecovery();
    history.push("/recovery_password/check_code", {
      login: login.value,
      email: email.value,
    });
  };

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="login1">
          <Form.Label>Логин</Form.Label>
          <Form.Control autoFocus type="login" {...login} />
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label>E-mail</Form.Label>
          <Form.Control type="email" {...email} />
        </Form.Group>
        <Row className="pt-1 pb-1 mt-2">
          <Col>
            <Button
              style={{ width: "inherit" }}
              size={"lg"}
              variant={"success"}
              type="submit"
              disabled={!validateForm()}
            >
              Восстановить пароль
            </Button>
          </Col>
        </Row>
        <Row className="pt-1 pb-1">
          <Col>
            <Button
              style={{ width: "inherit" }}
              size={"lg"}
              variant={"primary"}
              onClick={() => history.push("/registration")}
            >
              У меня еще нет логина и пароля
            </Button>
          </Col>
        </Row>
        <Row className="pt-1 pb-1">
          <Col>
            <Button
              style={{ width: "inherit" }}
              size={"lg"}
              variant={"primary"}
              onClick={() => history.push("/login")}
            >
              Я вспомнил пароль от аккаунта
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default RecoveryPassword;
