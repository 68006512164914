import { makeAutoObservable, flow } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { LoadStatesType } from "../../types/load-states";
import { HttpLog, IHttpLog } from "./log";

const ONE_STEP_SIZE = 100;
class HttpLogsStore {
  state: LoadStatesType = "INITIAL";
  logs = new Map<number, IHttpLog[]>();
  count = 0;
  offset = 0;
  currentPage = 1;

  constructor() {
    makeAutoObservable(this);
  }

  get isError() {
    return this.state === "FAILED";
  }

  get isLoading() {
    return this.state === "LOADING";
  }

  get isInitial() {
    return this.state === "INITIAL";
  }

  get pageLogs() {
    const currentPage = this.currentPage;
    return this.logs.get(currentPage) ?? [];
  }

  get steps() {
    return Math.ceil(this.count / ONE_STEP_SIZE);
  }

  get paginationSteps() {
    const steps = this.steps;
    const array = [];

    const start = Math.max(1, this.currentPage - 2);
    const end = Math.min(steps, this.currentPage + 2);

    for (let i = start; i <= end; i += 1) {
      array.push(i);
    }

    return array;
  }

  getAllHttpLogs = flow(function* (
    this: HttpLogsStore,
    page = 1,
    signal?: AbortSignal
  ) {
    try {
      const http = injectHttpClient();
      this.state = "LOADING";
      const offset = (page - 1) * ONE_STEP_SIZE;
      const response: { count: number; rows: IHttpLog[] } = yield http.get(
        "/api/logger/http",
        {
          limit: ONE_STEP_SIZE,
          offset,
        },
        // {
        //   signal,
        // }
      );

      if (this.logs.get(page) == null) {
        this.logs.set(page, []);
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const array: IHttpLog[] = this.logs.get(page)!;

      this.count = response.count;
      response.rows.forEach((log) => {
        array.push(new HttpLog().fromJSON(log));
      });
      this.state = "COMPLETED";
    } catch (err) {
      this.state = "FAILED";
    }
  });

  nextPage() {
    if (this.steps === this.currentPage) {
      return;
    }
    this.currentPage += 1;
  }
  previousPage() {
    if (this.currentPage === 1) {
      return;
    }
    this.currentPage -= 1;
  }
  firstPage() {
    this.currentPage = 1;
  }
  lastPage() {
    this.currentPage = this.steps;
  }
  setPage(page: number) {
    const amountOfSteps = this.steps;
    if (page < 1 || page > amountOfSteps) {
      return;
    }
    this.currentPage = page;
  }
}

export default HttpLogsStore;
