import * as yup from 'yup';

export const registrationSchema = yup.object().shape({
  login: yup
    .string()
    .min(4, 'Слишком короткий')
    .max(20, 'Слишком длинный')
    .required('Обязательное поле'),

  email: yup
    .string()
    .email('Недопустимый формат email')
    .required('Обязательное поле'),

  fam: yup
    .string()
    .min(4, 'Слишком короткий')
    .max(20, 'Слишком длинный')
    .required('Обязательное поле'),

  name: yup
    .string()
    .min(4, 'Слишком короткий')
    .max(20, 'Слишком длинный')
    .required('Обязательное поле'),

  otch: yup
    .string()
    .min(4, 'Слишком короткий')
    .max(20, 'Слишком длинный')
    .required('Обязательное поле'),

  birthday: yup
    .date()
    .required('Обязательное поле'),

  position: yup
    .string()
    .max(20, 'Слишком длинный')
    .required('Обязательное поле'),


  guid_license: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .uuid('Поле должно быть UUID')
    .optional(),

  password: yup.string()
    .required('Пароль обязателен.')
    .min(4, 'Пароль слишком короткий - минимальная длинна 4 символа.')
    .matches(/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$/, `Пароль должен включать как минимум одну цифру, 
      одну заглавную и одну строчную латинские буквы`),

  passwordTwo: yup.string()
    .oneOf([yup.ref('password'), null], 'Пароли не совпадают'),
});