import React from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useStore } from '../../../../providers/StoreContext';
import { Robot } from '../../../../store';
import { observer } from 'mobx-react-lite';

interface IDeleteRobotProps {
  show: boolean,
  onHide: () => void,
  robot: Robot | null,
  newOwnerId: string | null
}

const PassOwnerModal = ({ show, onHide, robot, newOwnerId }: IDeleteRobotProps) => {
  const store = useStore();

  const passRobotOwner = () => {
    if (robot?.id && newOwnerId) {
      store.robots.passRobotOwner(robot.id, newOwnerId)
      onHide();
    }
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Подтвердите действие
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Вы действительно хотите полностью передать робота новому владельцу?</p>
        <p>Этот робот больше не будет пренадлежать вам.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={onHide}>Закрыть</Button>
        <Button variant="outline-danger" onClick={passRobotOwner}>Передать робота</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(PassOwnerModal);
