import React from "react";
import useInput from "../../../hooks/useInput";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { injectHttpClient } from "../../../features/requests/inject-http-client";
import { useStore } from "../../../providers/StoreContext";

export interface CheckCodeProps {
  location: { state: { login: string; email: string; code?: string } };
}

const CheckCode = (props: CheckCodeProps) => {
  const store = useStore();
  const {
    location: {
      state: { login, email },
    },
  } = props;
  const code = useInput("");
  const history = useHistory();

  const validateForm = () => code.value.length > 0;

  const queryCheckCode = () => {
    const http = injectHttpClient();
    const _data = {
      login,
      email,
      code: code.value,
    };

    return http.post<typeof _data, { check: unknown; code: string }>(
      "/api/auth/check_code",
      _data
    );
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resCheckCode = await queryCheckCode();
    if (resCheckCode.check) {
      const newCode = resCheckCode.code;
      history.push("/recovery_password/change_password", {
        login,
        email,
        code: newCode,
      });
    }
    if (!resCheckCode.check) {
      store.alert.error('Код неправильный')
    }
  };

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="login">
          <Form.Label>Код из письма</Form.Label>
          <Form.Control autoFocus type="text" {...code} />
        </Form.Group>
        <Row className="pt-1 pb-1 mt-2">
          <Col>
            <Button
              style={{ width: "inherit" }}
              size={"lg"}
              variant={"success"}
              type="submit"
              disabled={!validateForm()}
            >
              Подтвердить
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CheckCode;
