import { AxiosError } from "axios";
import { makeAutoObservable, flow } from "mobx";
import moment from "moment";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { AlertTypes } from "../alert/type";
import License from "./license";
import { ILicense } from ".";
import { resolve, wire } from "../../di/di";
import HttpClient from "../../features/requests/http-client";
import { diConstants } from "../../di/di.constants";
import { LangStore } from "../lang";
import { AlertStore } from "../alert";
import { UsersStore } from "../users";

export default class LicenseStore {
  licenses: ILicense[] = [];
  appLicense = false;
  state: LoadStatesType = LoadStates.INITIAL;
  private readonly http = resolve<HttpClient>(diConstants.HTTP);
  public readonly alert!: AlertStore;
  public readonly lang!: LangStore;
  public readonly users!: UsersStore;

  constructor() {
    makeAutoObservable(this, {
      alert: false,
      lang: false,
      users: false,
    });
    wire(this, "alert", diConstants.ALERT);
    wire(this, "lang", diConstants.LANG.LANG_STORE);
    wire(this, "users", diConstants.USERS.USERS_STORE);
  }

  get licensesArray() {
    const users = this.users.users;
    return this.licenses.map((license) => {
      return {
        id: license.id,
        user:
          license?.id_user != null
            ? users.get(license.id_user)?.login ?? "Пользователь удален"
            : "Пользователь не указан",
        key: license.key,
        beginDate: moment(license.begin_date).format("DD.MM.YYYY"),
        endDate: moment(license.end_date).format("DD.MM.YYYY"),
        active: moment().isBetween(license.begin_date, license.end_date)
          ? "action"
          : ("error" as "action" | "error"),
      };
    });
  }

  getAllLicenses = flow(function* (this: LicenseStore) {
    try {
      const response: ILicense[] = yield this.http().get<ILicense[]>(
        "/api/license/all"
      );

      this.licenses = [];
      response.forEach((license) => {
        this.licenses.push(new License().fromJSON(license));
      });
      this.state = "COMPLETED";
    } catch (err) {
      console.error(err);
      this.state = "FAILED";
    }
  });

  updateLicense = flow(function* (
    this: LicenseStore,
    id_user: number | string,
    begin_date: string,
    end_date: string
  ) {
    const { alert, lang } = this;
    const forAlert = lang.getWord("forAlert");
    try {
      const data = {
        id_user,
        begin_date,
        end_date,
      };
      yield this.http().post("/api/license/update", data);

      alert.showAlert(AlertTypes.Success, forAlert.licenseRenewedSuccessfully);
      yield this.getAllLicenses();
    } catch (e) {
      const error = e as AxiosError;
      alert.showAlert(AlertTypes.Error, `${forAlert.error}: ${error.message}`);
    }
  });

  createDemoLicense = flow(function* (
    this: LicenseStore,
    begin_date: string,
    end_date: string
  ) {
    const { alert, lang } = this;
    const forAlert = lang.getWord("forAlert");
    try {
      const data = {
        begin_date,
        end_date,
      };
      yield this.http().post("/api/license/createDemo", data);
      alert.showAlert(
        AlertTypes.Success,
        forAlert.licenseHasBeenSuccessfullyCreated
      );
      yield this.getAllLicenses();
    } catch (e) {
      alert.showAlert(
        AlertTypes.Error,
        `${forAlert.error}: ${(e as Error).message}`
      );
    }
  });
}
