import { makeAutoObservable, flow } from "mobx";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { rootStore } from "../../providers/StoreContext";
import { Optional, UUID } from "../../types";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { ToastStore } from "../errors";
import { RequestRobot, Robot } from "../robots";
import GroupUser, { IGroupUser } from "./group-user";
import { GroupRoleType } from "./type";

interface AddGroupUserRequestBody {
  user_id: string;
  role: GroupRoleType;
}

export interface IGroup {
  id: UUID;
  name: string;
  name_en: string;
  parent: Optional<string>;
  root_parent: Optional<string>;
  createdAt: string;
  updatedAt: string;
  role: string;
  users: IGroupUser[];
  robots: RequestRobot[];
  path: string;
  level: number;

  fromJSON(json: IGroupJSON): IGroup;
  loadGroupUsers(): void;
  loadGroupRobots(): void;
  addGroupUser(body: AddGroupUserRequestBody): void;
  change(body: UpdateGroupParameter): void;
}


export type IGroupJSON = Omit<
  IGroup,
  "fromJSON" | "loadGroupUsers" |
  "loadGroupRobots" | "addGroupUser" | "change"
>;

export type CreateGroupParameter = Omit<
  IGroupJSON,
  "id" | "createdAt" | "updatedAt" | "users" |
  "robots" | "role" | "path" | "level"
>;
export type UpdateGroupParameter = Omit<
  CreateGroupParameter,
  "parent" | "root_parent"
>;

class Group implements IGroup {
  id = "";
  name = "";
  name_en = "";
  parent: Optional<string> = "";
  root_parent: Optional<string> = "";
  createdAt = "";
  updatedAt = "";
  role = "";
  path = "";
  level = 0;
  users: GroupUser[] = [];
  robots: Robot[] = [];

  public readonly toasts!: ToastStore;
  state: LoadStatesType = LoadStates.INITIAL;

  get isLoading() {
    return this.state == LoadStates.LOADING;
  }

  constructor() {
    makeAutoObservable(this, {
      toasts: false
    });
    wire(this, "toasts", diConstants.TOASTS);
  }

  getUserById(id: string) {
    return this.users.find((user) =>
      user.user_id == id
    )
  }

  fromJSON(json: IGroupJSON): Group {
    this.id = json.id;
    this.name = json.name;
    this.name_en = json.name_en;
    this.parent = json.parent;
    this.root_parent = json.root_parent;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;
    this.role = json.role;
    this.users = (json.users ?? []).map((user) =>
      new GroupUser().fromJSON(user)
    );
    this.robots = (json.robots ?? []).map((robot) =>
      new Robot().fromJSON(robot)
    );
    this.path = json.path;
    this.level = json.level;

    return this;
  }

  loadGroupRobots = flow(function* (this: Group) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const data: RequestRobot[] = yield http.get(
        `/api/groups/${this.id}/robots`
      );
      this.robots = [];
      data.forEach((robot) => {
        const isExists = rootStore.robots.robots.find((r) =>
          r.id == robot.id
        )
        if (isExists) {
          this.robots.push(isExists)
        } else {
          throw new Error('Прилетел робот, которого тут быть не должно')
        }
      });
      this.state = LoadStates.COMPLETED;
    } catch (err) {
      this.onError(err);
    }
  })

  loadGroupUsers = flow(function* (this: Group) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const data: IGroupUser[] = yield http.get(
        `/api/groups/${this.id}/users`
      );
      this.users = [];
      data.forEach((user) =>
        this.users.push(
          new GroupUser().fromJSON(user)
        )
      );
      this.state = LoadStates.COMPLETED;
    } catch (err) {
      this.onError(err);
    }
  });

  addGroupUser = flow(function* (
    this: Group,
    body: AddGroupUserRequestBody
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient()
      const resp = yield http.post(
        `/api/groups/${this.id}/users`,
        body
      );
      if (resp?.added) {
        this.users.push(
          new GroupUser().fromJSON(resp.user)
        )
        this.onSuccess('Пользователь успешно добавлен');
      }
    } catch (err) {
      this.onError(err);
    }
  });

  change = flow(function* (
    this: Group,
    body: UpdateGroupParameter
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient()
      yield http.patch(
        '/api/groups/' + this.id,
        body
      );
      this.name = body.name;
      this.name_en = body.name_en;
      this.onSuccess('Группа ' + this.name + " успешно изменена");
    } catch (err) {
      this.onError(err);
    }
  });

  changeUserRole(
    userId: string,
    role: GroupRoleType
  ) {
    const groupUser = this.getUserById(userId);
    if (groupUser) {
      groupUser.changeRole({ role })
    }
  }

  destroy = flow(function* (
    this: Group
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const resp = yield http.delete(
        'api/groups/' + this.id
      )
      if (resp.deleted) {
        this.onSuccess('Группа ' + this.name + " удалена");
      }
    } catch (err) {
      this.onError(err)
    }
  })

  deleteUser(
    userId: string
  ) {
    const groupUser = this.getUserById(userId)
    if (groupUser) {
      groupUser.destroy();
      this.users = [
        ...this.users.filter((user) =>
          user.user_id != userId
        )
      ]
      this.toasts.show('Пользователь удален')
    }
  }

  onBegin() {
    this.state = LoadStates.LOADING;
  }

  onSuccess(text: string) {
    this.state = LoadStates.COMPLETED;
    this.toasts.show(text);
  }

  onError(err: unknown) {
    console.error(err);
    this.state = LoadStates.FAILED;
    this.toasts.addError(err);
  }
}

export default Group;
