import { Container, createResolve, createWire } from "@owja/ioc";
import setupRequestsDI from "../features/requests/setup-requests-di";
import setupStoresDI from "../store/setupStoresDI";
import { pipe } from "../utils";

export const container = new Container();

pipe(setupRequestsDI, setupStoresDI)(container);

export const wire = createWire(container);
export const resolve = createResolve(container);
