import React, { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Form, Modal, Row, Col, Button, Alert } from 'react-bootstrap';
import { useStore } from '../../../../../providers/StoreContext';
import useStrings from '../../../../../hooks/useStrings';
import { yupResolver } from '@hookform/resolvers/yup';
import { userValidationSchema } from './CreateUserSchema';

interface ICreateUserModalProps {
  show: boolean;
  onHide: () => void;
}

type Inputs = {
  isAdmin: boolean,
  login: string,
  email: string,
  fam: string,
  name: string,
  otch: string,
  birthday: string,
  position: string,
  user_lexema8: string,
  guid_license: string,
};

const CreateUserModal = (props: ICreateUserModalProps) => {
  const [error, setError] = useState<string | null>(null);

  const store = useStore();
  const { general } = useStrings(store);
  const { control, reset, handleSubmit, formState: { errors } } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(userValidationSchema),
    defaultValues: {
      isAdmin: false,
      login: '',
      email: '',
      fam: '',
      name: '',
      otch: '',
      birthday: '',
      position: '',
      user_lexema8: '',
      guid_license: '',
    }
  });

  const onSubmit: SubmitHandler<Inputs> = data => createUser(data);

  const createUser = (user: Inputs) => {
    store.users.createUser(user)
      .then(() => close())
      .catch((err) => {
        const text = err.response?.data.message ?? err.message;
        setError(text)
      });
  }

  // close the window
  const close = () => {
    setError(null);
    reset();
    props.onHide();
  }

  return (
    <Modal
      show={props.show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Создать пользователя
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error &&
          <Alert dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        }
        <Form>

          {/* IsAdmin Checkbox */}
          <Row className="mb-3 justify-content-md-end">
            <Col sm="3">
              <Controller
                control={control}
                name='isAdmin'
                render={({ field }) =>
                  <Form.Check
                    type="checkbox"
                    id="editForm"
                    label='Создать Админа Оркестратора'
                    defaultChecked={field.value}
                    onChange={field.onChange}
                  />
                }

              />
            </Col>
          </Row>

          {/* Login input */}
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextIogin"
          >
            <Form.Label column sm="2">
              {general.login}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='login'
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field }) =>
                  <>
                    <Form.Control
                      placeholder="Введите логин"
                      {...field}
                    />
                    {errors.login?.message &&
                      <Alert>{errors.login?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* Email Input */}
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextEmail"
          >
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='email'
                rules={{
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered value does not match email format"
                  }
                }}
                render={({ field }) =>
                  <>
                    <Form.Control
                      type="email"
                      placeholder="Введите Email"
                      {...field}
                    />
                    {errors.email?.message &&
                      <Alert>{errors.email?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* Surname input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextSurname">
            <Form.Label column sm="2">
              {general.surname}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='fam'
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field }) =>
                  <>
                    <Form.Control
                      placeholder="Введите фамилию"
                      {...field}
                    />
                    {errors.fam?.message &&
                      <Alert>{errors.fam?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* Name input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextName">
            <Form.Label column sm="2">
              {general.name}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='name'
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field }) =>
                  <>
                    <Form.Control
                      placeholder="Введите имя"
                      {...field}
                    />
                    {errors.name?.message &&
                      <Alert>{errors.name?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* Otch input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextOtch">
            <Form.Label column sm="2">
              {general.name}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='otch'
                rules={{
                  required: true,
                  minLength: 4,
                  maxLength: 20
                }}
                render={({ field }) =>
                  <>
                    <Form.Control
                      placeholder="Введите отчество"
                      {...field}
                    />
                    {errors.otch?.message &&
                      <Alert>{errors.otch?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* Birthday input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextDate">
            <Form.Label column sm="2">
              {general.birthday}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="birthday"
                render={({ field }) => (
                  <>
                    <Form.Control
                      type="date"
                      {...field}
                    />
                    {errors.birthday?.message &&
                      <Alert>{errors.birthday?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>

          {/* Position input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPosition">
            <Form.Label column sm="2">
              {general.position}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="position"
                render={({ field }) => (
                  <>
                    <Form.Control
                      placeholder="Введите должность"
                      {...field}
                    />
                    {errors.position?.message &&
                      <Alert>{errors.position?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>

          {/* user_lexema8 input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextUser_lexema8">
            <Form.Label column sm="2">
              User Lexema8
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="user_lexema8"
                render={({ field }) => (
                  <>
                    <Form.Control
                      placeholder="Введите ваш id"
                      {...field}
                    />
                    {errors.user_lexema8?.message &&
                      <Alert>{errors.user_lexema8?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>


          {/* guid_license input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextПuid_license">
            <Form.Label column sm="2">
              guid_license
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="guid_license"
                render={({ field }) => (
                  <>
                    <Form.Control
                      placeholder="Введите лицензионный ключ"
                      {...field}
                    />
                    {errors.guid_license?.message &&
                      <Alert>{errors.guid_license?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>

        {/* Submit button */}
        <Button
          variant="success"
          onClick={handleSubmit(onSubmit)}
        >
          Создать пользователя
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateUserModal;