import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LogsStore } from '../../../store';
import HttpLogsStore from '../../../store/logs/http-logs-store';

interface IProps {
  store: HttpLogsStore | LogsStore
}
function LogsTablePagination({ store }: IProps) {

  const currentPage = store.currentPage;
  const steps = store.steps;

  if (store.count === 0) {
    return null;
  }

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    store.setPage(Number(e.currentTarget.innerHTML))
  }

  return (
    <Pagination>
      <Pagination.First
        id="first"
        href="/logs/1"
        disabled={currentPage === 1}
      />
      <Pagination.Prev
        href={`/logs/${currentPage - 1}`}
        id="prev"
        disabled={currentPage === 1}
      />
      {store.paginationSteps.map((step) => {
        return (
          <Pagination.Item
            onClick={(e) => handleClick(e)}
            key={`step-${step}`}
            href={`/logs/${step}`}
            active={currentPage === step}
          >
            {step}
          </Pagination.Item>
        );
      })}
      <Pagination.Next
        href={`/logs/${currentPage + 1}`}
        id="next"
        disabled={currentPage === steps}
      />
      <Pagination.Last
        id="last"
        href={`/logs/${steps}`}
        disabled={currentPage === steps}
      />
    </Pagination>
  );
}

export default LogsTablePagination;