import { AxiosError } from "axios";
import { makeAutoObservable, flow } from "mobx";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { AlertStore } from "../alert";

class AppLicense {
  isLicenseActive: 'NO_CHEKED' | 'ACTIVE' | 'FAILED' = 'NO_CHEKED';
  public readonly alert!: AlertStore;

  constructor() {
    makeAutoObservable(this, { alert: false });
    wire(this, "alert", diConstants.ALERT);
    this.loadLicense();
  }

  loadLicense = flow(function* (this: AppLicense) {
    try {
      const http = injectHttpClient();

      const data: { checkLicense: boolean } = yield http.get<{
        checkLicense: boolean;
      }>("api/unprotected_api/licenseStatus");

      console.log(data);
      if (data.checkLicense) {
        this.isLicenseActive = 'ACTIVE'
      } else {
        this.isLicenseActive = 'FAILED'
      }
    } catch (err) {
      console.error(err);
      this.isLicenseActive = 'FAILED'
    }
  });

  updateLicense = flow(function* (this: AppLicense, license: string) {
    try {
      const http = injectHttpClient();
      yield http.post("/api/unprotected_api/updateServerLicense", {
        license,
      });

      yield this.loadLicense();
    } catch (err) {
      const error = err as Error | AxiosError;

      if ("response" in error) {
        if (error.response?.status === 400) {
          this.alert.error("Некорректное значение лицензии");
        }
        if (error.response?.status === 500) {
          this.alert.error("Ошибка сервера");
        }
      }
      console.error(err);
    }
  });
}

export default AppLicense;
