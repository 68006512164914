import React, { useState } from "react";
import { CloseButton, ListGroup } from "react-bootstrap";
import { IRoleModel } from "../../../../../store/roles";
import PlusLogo from '../../../../../icons/Plus.png';
import AddRoleModal from "../AddRole/AddRole";

interface RoleListProps {
  userId: string,
  roles: IRoleModel[]
  isHover: boolean
  addRole: (
    role: IRoleModel
  ) => void
  deleteRole: (
    role: IRoleModel
  ) => void
}
export const RoleList = ({ userId, roles, deleteRole, isHover }: RoleListProps) => {
  const [showAddRole, setShowAddRole] = useState(false);
  const handleAdd = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation();
    setShowAddRole(true);
  }
  return (
    <div onClick={e => e.stopPropagation()}>
      <AddRoleModal
        userId={userId}
        show={showAddRole}
        onHide={() => setShowAddRole(false)}
      />
      <ListGroup horizontal>
        {roles.map((r) =>
          <Role
            r={r}
            key={r.id}
            deleteRole={deleteRole}
          />
        )}
        {isHover &&
          <img src={PlusLogo} className='add__role_btn' onClick={handleAdd} />
        }
      </ListGroup>
    </div>
  )
}

interface RoleProps {
  r: IRoleModel
  deleteRole: (
    role: IRoleModel
  ) => void
}
const Role = ({ r, deleteRole }: RoleProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const onHover = () => setIsHover(true);
  const onHoverOut = () => setIsHover(false);
  const onDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    deleteRole(r);
  };
  return (
    <ListGroup.Item
      className="p-1"
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}
      variant={r.value == 'ADMIN'
        ? 'warning'
        : 'light'
      }
    >
      <span>{r.value}</span>
      {isHover &&
        <CloseButton
          style={{ height: '10px' }}
          onClick={onDelete}
        />
      }
    </ListGroup.Item>
  )
}