import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useStore } from '../../../providers/StoreContext';
import RefreshIcon from '../../../icons/Refresh.png';
import CreateRoleModal from './modals/CreateRole/CreateRole';
import WatchEditRoleModal from './modals/WatchEditRole/WatchEditRole';
import CenterSpinner from '../../../components/Spinner/Spinner';


function truncate(str: string) {
  return str.length > 30 ? str.substring(0, 27) + "..." : str;
}

const ManageRoles = () => {
  const rolesStore = useStore().roles;
  const {
    roles,
    isLoading,
    refresh,
    watchRole,
    selectedRole,
    createRoleModal,
    watchAndEditRoleModal
  } = rolesStore;


  useEffect(() => {
    rolesStore.getAllRoles();
  }, [])

  return (
    <>
      <CreateRoleModal
        show={createRoleModal.show}
        onHide={() => createRoleModal.close()}
      />
      {selectedRole &&
        <WatchEditRoleModal
          show={watchAndEditRoleModal.show}
          onHide={() => watchAndEditRoleModal.close()}
          role={selectedRole}
        />
      }
      <CenterSpinner
        isLoad={isLoading}
      />
      <Row className="mx-3 justify-content-md-between">
        <Col md={5}>
          <Form.Group as={Row}>
            <Form.Label column>
              {'Всего ролей' + ': ' + roles.length}
            </Form.Label>
            {/* <Col sm={8}>
              <Button
                className='mb-3'
                variant='outline-secondary'
                onClick={() => createRoleModal.open()}
              >
                Создать новую роль
              </Button>
            </Col> */}
          </Form.Group>
        </Col>

        <Col sm={1}>
          <img
            src={RefreshIcon}
            className='refresh_btn'
            onClick={() => refresh()}
          />
        </Col>
      </Row>


      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Значение</th>
            <th>Описание</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) =>
            <tr
              key={role.id}
              onClick={() => watchRole(role)}
            >
              <td>{role.id}</td>
              <td>{role.value}</td>
              <td>{role.description}</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}

export default observer(ManageRoles);