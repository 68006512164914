import React from "react";
import { Tab, Col, Row, ListGroup, Container } from "react-bootstrap";

function DocsPage() {
  return (
    <Container className="mt-5">
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#rpa">
        <Row>
          <Col sm={4}>
            <ListGroup>
              <ListGroup.Item variant="success" action href="#rpa">
                Lexema-RPA Studio
              </ListGroup.Item>
              <ListGroup.Item variant="success" action href="#rpa-sample">
                Lexema-RPA Studio Sample
              </ListGroup.Item>
              <ListGroup.Item variant="success" action href="#rpa-support">
                Техническая поддержка Lexema-RPA Studio
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#rpa">
                <p
                  style={{
                    backgroundColor: "#fffcf4",
                    border: "#c58122 1px solid",
                    padding: "25px",
                    margin: "10px auto",
                    color: "black",
                  }}
                >
                  Lexema-RPA – это платформа для разработки программных роботов,
                  выполняющих рутинные операции на компьютере вместо человека.
                </p>
                <img
                  style={{ marginLeft: "90px" }}
                  src="http://www.lexema.ru/files/nodus_items/0000/0358/attaches/manyrobots.png"
                  alt="manyrobots.png"
                />
                <a
                  href="http://rpa.lexema.ru/download/setup.exe"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: "flex",
                    background:
                      "linear-gradient(0deg, rgba(255,111,0,1) 0%, rgba(255,141,0,1) 35%, rgba(255,179,0,1) 100%)",
                    padding: "20px 40px",
                    margin: "20px auto 40px auto",
                    borderRadius: "60px",
                    maxWidth: "450px",
                    border: "none",
                    color: "white",
                    fontSize: "1.2em",
                    fontWeight: 500,
                    textAlign: "center",
                  }}
                >
                  Скачать Lexema-RPA Studio
                </a>
                <div style={{ display: "flex" }}>
                  <div style={{ minWidth: "60px" }}>
                    <a
                      style={{ borderBottom: "none" }}
                      href="http://rpa.lexema.ru/download/Lexema_RPA_Studio.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "20px", width: "60px" }}
                        src="http://lexema.ru/files/nodus_items/0000/0454/attaches/abilities.png"
                      />
                    </a>
                  </div>
                  <p style={{ marginTop: "0.5em" }}>
                    <a
                      href="https://lexema-rpa-studio-docs.readthedocs.io/ru/latest/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      База знаний
                    </a>
                    &nbsp;|&nbsp;
                    <a
                      href="http://rpa.lexema.ru/download/Lexema_RPA_Studio.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lexema-RPA Studio Help (Инструкция пользователя)
                    </a>
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#rpa-sample">
                <div style={{ display: "flex" }}>
                  <div style={{ minWidth: "60px" }}>
                    <a
                      style={{ borderBottom: "none" }}
                      href="http://lexema.ru/solutions/konstruktor-robotov/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ paddingRight: "20px", width: "60px" }}
                        src="http://lexema.ru/files/nodus_items/0000/0454/attaches/example.png"
                      />
                    </a>
                  </div>
                  <p style={{ marginTop: "0.5em" }}>
                    <a
                      href="http://lexema.ru/solutions/lexema-rpa-programmnye-roboty/sample"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Lexema-RPA Studio Sample — примеры разработки роботов
                    </a>
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="#rpa-support">
                <p>
                  Мы осуществляем техническую поддержку несколькими способами.
                  Вы можете выбрать любой удобный вам вид связи.
                </p>
                <p>
                  Техническая поддержка для всех тарифов и пробных бесплатных
                  версий Lexema-RPA подразумевает техническую поддержку с
                  помощью ресурсов:
                </p>
                <ul>
                  <li>
                    Электронная почта:
                    <a
                      href="mailto:rpa_support@lexema.ru"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      rpa_support@lexema.ru
                    </a>
                  </li>
                  <li>
                    Telegram:
                    <a
                      href="https://t.me/rpa_support"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @rpa_support
                    </a>
                  </li>
                  <li>
                    Telegram:
                    <a
                      href="https://t.me/CourseLexemaRPA"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      @CourseLexemaRPA
                    </a>
                  </li>
                  <li>
                    Заявка на доработку платформы:
                    <a
                      href="https://lexema-rpa.tilda.ws/rework-request/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Подать заявку.
                    </a>
                    Работаете в Lexema-RPA Studio и есть предложения по
                    улучшению платформы? А, может быть, не хватает каких-то
                    возможностей? Рассмотрим все заявки!
                  </li>
                </ul>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}

export default DocsPage;
