import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from 'react';
import { useStore } from "../../../providers/StoreContext";
import { MY_GROUP_ROBOT, MY_ROBOT, Robot } from "../../../store";
import RobotLogo from '../../../icons/Robot.png';
import BaseModuleLogo from '../../../icons/Module.png';
import DowloadIcon from '../../../icons/Download.png';
import DisabledDowloadIcon from '../../../icons/DisabledDownload.png';
import ShareIcon from '../../../icons/Share.png';
import DisabledShareIcon from '../../../icons/DisabledShare.png';
import CrossIcon from '../../../icons/Close.png';
import DisabledCrossIcon from '../../../icons/DisabledClose.png';
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";

type PublishRobotProps = {
  robot: Robot
};

const PublishRobot = observer(function PublishRobot({
  robot
}: PublishRobotProps) {
  const store = useStore();
  const { robots } = store;
  // const params = useParams<{ robot_id: string }>();
  const history = useHistory();

  const users = store.users.users;
  const groups = store.groups;


  const [isHover, setIsHover] = useState<boolean>(false);
  const onHover = () => setIsHover(true);
  const onHoverOut = () => setIsHover(false);


  const getRobotowner = () => {
    const user = users.get(robot?.robot_owner);
    if (!user) return { login: groups.getNameById(robot?.robot_owner) };
    return user;
  }

  const robotOwner = useMemo(
    getRobotowner,
    [robot]
  )

  const dowloadClickHandler = (
    e: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    e.stopPropagation();
    store.robots.dowloadRobotFile(robot.id);
  }

  const isOwner = robot.ownership_type === MY_GROUP_ROBOT
    || robot.ownership_type === MY_ROBOT;

  const ModuleLogo = robot.iconBuffer?.length
    ? 'data:image/jpeg;base64,' + robot.iconBuffer
    : BaseModuleLogo

  const DarkModuleLogo = robot.darkIconBuffer?.length
    ? 'data:image/jpeg;base64,' + robot.darkIconBuffer
    : BaseModuleLogo

  return (
    <div
      className='robot'
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}
      onClick={() => {
        history.push('/robots/' + robot.id)
        robots.watchRobot(robot.id)
      }}
    >
      <img
        src={robot.isModule
          ? ModuleLogo
          : RobotLogo
        }
        className='robot__img'
      />
      <div className='robot__name'>
        <span>
          {robot.robotname}
        </span>
        {robot.isActive
          ? (
            <Spinner
              className="ms-2"
              variant="success"
              animation="grow"
              size="sm"
            />
          ) : null
        }
      </div>
      <div className="robot__share">
        {isOwner
          ? <img
            className={isHover && 'img_enabled' || ''}
            onClick={
              (e) => {
                e.stopPropagation();
                robots.shareRobot(robot);
              }
            }
            src={ShareIcon}
            alt="Поделиться роботом"
          />
          :
          <img
            src={DisabledShareIcon}
            alt="Поделиться роботом"
          />
        }
      </div>
      <div className="robot__dowload">
        {!robot.isModule && (robot.read
          ?
          <img
            src={DowloadIcon}
            className={isHover && 'img_enabled' || ''}
            alt="Скачать робота в студию"
            onClick={dowloadClickHandler}
          />
          :
          <img
            src={DisabledDowloadIcon}
            alt="Скачать робота в студию"
          />)
        }
      </div>
      <div className="robot__delete">
        {robot.delete
          ?
          <img
            className={isHover && 'img_enabled' || ''}
            onClick={
              (e) => {
                e.stopPropagation();
                robots.confirmDeleteRobot(robot)
              }
            }
            src={CrossIcon}
            alt="Удалить робота" />
          :
          <img
            src={DisabledCrossIcon}
            alt="Удалить робота"
          />
        }
      </div>
    </div>

  );
});

export default PublishRobot;