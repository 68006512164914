import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import NotificationStore from "./notifications-store";

export function setupNotificationsStoreDI(container: Container): Container {
  container
    .bind<Notification>(diConstants.NOTIFICATION.NOTIFICATION)
    .to(Notification);

  container
    .bind<NotificationStore>(diConstants.NOTIFICATION.NOTIFICATION_STORE)
    .to(NotificationStore)
    .inSingletonScope();
  return container;
}
