import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import useStrings from '../../../../../hooks/useStrings';
import { useStore } from '../../../../../providers/StoreContext';
import { IUser, IUserInfo } from '../../../../../store';
import { generatePassword } from '../../../helpers';
import { Inputs, userEditSchema } from './userEditSchema';

interface IWatchUserProps {
  show: boolean;
  onHide: () => void;
  currentUser: IUser;
}



const WatchAndEditUserModal = ({
  show,
  onHide,
  currentUser
}: IWatchUserProps) => {
  const store = useStore();
  const { users } = store;
  const {
    user,
    license
  } = users.selectedUserInfo as IUserInfo;

  const { general, personalAccount } = useStrings(store);
  const [editForm, setEditForm] = useState<boolean>(true);

  const {
    control,
    formState: { errors },
    reset,
    setValue,
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(userEditSchema),
  });

  useEffect(() => {
    setValue("password", '')
    setValue("login", user.login)
    setValue("email", user.email)
    setValue("fam", user.fam)
    setValue("name", user.name)
    setValue("otch", user.otch)
    setValue("position", user.position)
    setValue("license_key", license.license_key)
    setValue("birthday", moment(user.birthday).format("YYYY-MM-DD"))
    setValue("license_begin_date", moment(license.license_begin_date).format("YYYY-MM-DD"))
    setValue("license_end_date", moment(license.license_end_date).format("YYYY-MM-DD"))
  }, [users.selectedUserInfo])

  const onSubmit: SubmitHandler<Inputs> = data =>
    store.users.setUserInfoAfterUpdate(data, user.id)


  const close = () => {
    reset();
    onHide();
    setEditForm(true);
  }

  return (
    <Modal
      show={show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {'Пользователь ' + currentUser.login}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Row className="mb-3 justify-content-md-end">
            <Col sm="4">
              <Form.Check
                type="checkbox"
                id="editForm"
                label={personalAccount.allowEditing}
                defaultChecked={!editForm}
                onChange={() => setEditForm(!editForm)}
              />
            </Col>
          </Row>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextEmail"
          >
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">

              {/* email input */}
              <Controller
                control={control}
                name='email'
                render={({ field }) =>
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="email"
                      placeholder="Введите Email"
                      {...field}
                    />
                    {errors.email?.message &&
                      <Alert>{errors.email?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* login input */}
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextI"
          >
            <Form.Label column sm="2">
              Логин
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='login'
                render={({ field }) =>
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите логин"
                      {...field}
                    />
                    {errors.login?.message &&
                      <Alert>{errors.login?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formPlaintextF">
            <Form.Label column sm="2">
              Новый пароль
            </Form.Label>
            <Col sm="7">
              <Controller
                control={control}
                name='password'
                render={({ field }) =>
                  <>
                    <InputGroup>
                      <Form.Control
                        disabled={editForm}
                        type="text"
                        placeholder="Введите новый пароль"
                        {...field}
                      />
                      <Button
                        variant="outline-success"
                        disabled={editForm}
                        onClick={() => setValue("password", generatePassword())}
                      >
                        Сгенерировать пароль
                      </Button>
                    </InputGroup>
                    {errors.password?.message &&
                      <Alert>{errors.password?.message}</Alert>
                    }
                  </>
                }
              />

            </Col>
          </Form.Group>

          {/* surname input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextF">
            <Form.Label column sm="2">
              {general.surname}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='fam'
                render={({ field }) =>
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите фамилию"
                      {...field}
                    />
                    {errors.fam?.message &&
                      <Alert>{errors.fam?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* name input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextI">
            <Form.Label column sm="2">
              {general.name}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='name'
                render={({ field }) =>
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите имя"
                      {...field}
                    />
                    {errors.name?.message &&
                      <Alert>{errors.name?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* otchestvo input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextI">
            <Form.Label column sm="2">
              Отчество
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='otch'
                render={({ field }) =>
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите отчество"
                      {...field}
                    />
                    {errors.otch?.message &&
                      <Alert>{errors.otch?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* birthday input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextDate">
            <Form.Label column sm="2">
              {general.birthday}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="birthday"
                render={({ field }) => (
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="date"
                      {...field}
                    />
                    {errors.birthday?.message &&
                      <Alert>{errors.birthday?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>

          {/* Position input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextPosition">
            <Form.Label column sm="2">
              {general.position}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="position"
                render={({ field }) => (
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите должность"
                      {...field}
                    />
                    {errors.position?.message &&
                      <Alert>{errors.position?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>
          <hr />

          {/* license_key input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextLicKey">
            <Form.Label column sm="2">
              {personalAccount.licenseKey}
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name="license_key"
                render={({ field }) => (
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="text"
                      placeholder="Введите лицензионный ключ"
                      {...field}
                    />
                    {errors.license_key?.message &&
                      <Alert>{errors.license_key?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>

          {/* license_begin_date input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextLicDate">
            <Form.Label column sm="2">
              {general.actsWith}
            </Form.Label>
            <Col sm="4">
              <Controller
                control={control}
                name="license_begin_date"
                render={({ field }) => (
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="date"
                      {...field}
                    />
                    {errors.license_begin_date?.message &&
                      <Alert>{errors.license_begin_date?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>

            {/* license_end_date input */}
            <Form.Label column sm="2">
              {general.by}
            </Form.Label>
            <Col sm="4">
              <Controller
                control={control}
                name="license_end_date"
                render={({ field }) => (
                  <>
                    <Form.Control
                      disabled={editForm}
                      type="date"
                      {...field}
                    />
                    {errors.license_end_date?.message &&
                      <Alert>{errors.license_end_date?.message}</Alert>
                    }
                  </>
                )}
              />
            </Col>
          </Form.Group>

          {/* customerKey input */}
          <Form.Group as={Row} className="mb-3" controlId="formPlaintextLicKey">
            <Form.Label column sm="2">
              {personalAccount.customerKey}
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" disabled={true} value={user.id} />
            </Col>
          </Form.Group>
          <hr />

          <Button
            variant="danger"
            disabled={editForm}
            onClick={() => users.deleteUser(user.id)}
          >
            Удалить учетную запись пользователя
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={close}
        >
          Закрыть
        </Button>
        <Button
          variant="primary"
          disabled={editForm}
          onClick={handleSubmit(onSubmit)}
        >
          {general.save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(WatchAndEditUserModal)