import React from "react";
import "./LanguageSwitcherSelector.css";
import { Dropdown } from "react-bootstrap";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import { ILang } from "../../store";

function LanguageSwitcherSelector() {
  const store = useStore();
  const lang = store.lang;

  function onSelectLanguage(selectedLang: ILang) {
    lang.setCurrentLang(selectedLang);
  }

  const currentLang = lang.currentLang;

  return (
    <Dropdown style={{ marginLeft: "auto", marginRight: 20 }}>
      <Dropdown.Toggle
        id="dropdown-button-dark-example2"
        variant="light"
        bsPrefix="p-0"
      >
        <div className={currentLang.code} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {lang.inactiveLanguages.map((language, index) => {
          return (
            <Dropdown.Item
              key={index}
              className="d-flex"
              onClick={() => onSelectLanguage(language)}
            >
              <div className={language.code} />
              <p style={{ marginLeft: "20px" }}>{language.name}</p>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default observer(LanguageSwitcherSelector);
