import React from "react";
import {
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { Spinner } from "react-bootstrap";
//import DashboardPage from "../../pages/DashboardPage/DashboardPage";
import DocsPage from "../../pages/DocsPage/DocsPage";
import AppNavBar from "../AppNavBar/AppNavBar";
import PublishRobotsPage from "../../pages/PublishRobotsPage/PublishRobotsPage";
import SettingPage from "../../pages/SettingPage/SettingPage";
import PersonalAccountPage from "../../pages/PersonalAccountPage/PersonalAccountPage";
import ClientsPage from "../../pages/ClientsPage/ClientsPage";
import RegistrationForm from "../RegistrationForm/RegistrationForm";
import LoginForm from "../LoginForm/LoginForm";
import RecoveryPassword from "../RecoveryPassword/RecoveryPassword";
import CheckCode from "../RecoveryPassword/CheckCode/CheckCode";
import ChangePassword from "../RecoveryPassword/CheckCode/ChangePassword/ChangePassword";
import AppAlert from "../AppAlert/AppAlert";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import GroupsPage from "../../pages/GroupsPage/GroupsPage";
import { AdminsPage } from "../../pages/AdminsPage/AdminsPage";
import AppToast from "../AppToast/AppToast";
import TasksPage from "../../pages/TasksPage";

function CheckingAuth() {
  return (
    <div
      style={{
        position: "absolute",
        inset: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Spinner animation="border" style={{ margin: "auto" }} />
      </div>
    </div>
  );
}

const PrivateRoute = observer(function PrivateRoute({
  children,
  ...rest
}: RouteProps) {
  const store = useStore();
  const isAuthorized = store.auth.isAuth;
  const isCheckingAuth = store.auth.isCheckingAuth;
  const appLicense = store.appLicense.isLicenseActive;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (appLicense === 'NO_CHEKED') {
          return <CheckingAuth />
        }
        if (appLicense === 'FAILED') {
          store.auth.exitAuth();
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
        if (isAuthorized) {
          return children;
        }
        if (isCheckingAuth) {
          return <CheckingAuth />;
        }


        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
});

const AdminRoute = observer(function AdminRoute({
  children,
  ...rest
}: RouteProps) {
  const { auth } = useStore();
  const roles = auth.user?.user.roles;
  const isAdmin = roles.find((role) =>
    role.value === 'ADMIN'
  );
  const isAuthorized = auth.isAuth;
  const isCheckingAuth = auth.isCheckingAuth;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (isAuthorized && isAdmin) {
          return children;
        }
        if (isCheckingAuth) {
          return <CheckingAuth />;
        }

        return (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
})

function Router() {
  const store = useStore();
  const isAuthorized = store.auth.isAuth;

  return (
    <>
      {isAuthorized ? <AppNavBar /> : null}
      <AppAlert />
      <AppToast />
      <Switch>
        <Route path="/login" component={LoginForm} />
        <Route
          path="/registration/:guid_license"
          component={RegistrationForm}
        />
        <Route path="/registration" component={RegistrationForm} />
        <Route path="/recovery_password/check_code" component={CheckCode} />
        <Route
          path="/recovery_password/change_password"
          component={ChangePassword}
        />
        <Route path="/recovery_password" component={RecoveryPassword} />
        <PrivateRoute path="/" exact>
          <Redirect to={'/robots'} />
        </PrivateRoute>
        <PrivateRoute path="/docs">
          <DocsPage />
        </PrivateRoute>
        {/* <PrivateRoute exact={true} path="/logs">
          <LogsPage />
        </PrivateRoute> */}

        <PrivateRoute path="/robots/:robot_id">
          <PublishRobotsPage />
        </PrivateRoute>
        <PrivateRoute path="/robots">
          <PublishRobotsPage />
        </PrivateRoute>
        <PrivateRoute path="/tasks/:taskId">
          <TasksPage />
        </PrivateRoute>
        <PrivateRoute path="/tasks">
          <TasksPage />
        </PrivateRoute>
        <PrivateRoute path="/settings">
          <SettingPage />
        </PrivateRoute>
        <PrivateRoute path="/lk">
          <PersonalAccountPage />
        </PrivateRoute>
        <AdminRoute path="/admin">
          <AdminsPage />
        </AdminRoute>
        <PrivateRoute path="/clients">
          <ClientsPage />
        </PrivateRoute>
        <PrivateRoute path="/groups/:group_id">
          <GroupsPage />
        </PrivateRoute>
        <PrivateRoute path="/groups">
          <GroupsPage />
        </PrivateRoute>
      </Switch>
    </>
  );
}

export default observer(Router);
