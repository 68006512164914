import { makeAutoObservable } from "mobx";
import configApp from "../../config/config";
import { resolve } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { ToastStore } from "../../store/errors";
import { Optional, UUID } from "../../types";

export const WsEvents = {
  SET_CLIENT_STATE: 'SET_CLIENT_STATE',
  SET_STATE_ROBOT: 'SET_STATE_ROBOT',
  CONNECT_FOR_WEB: 'CONNECT_FOR_WEB',
  SET_STATE_INFO_ROBOT: 'SET_STATE_INFO_ROBOT',
  SET_TASK_STARTED: 'SET_TASK_STARTED',
  SET_TASK_STOPED: 'SET_TASK_STOPED',
  SET_INFO_ABOUT_TASK: 'SET_INFO_ABOUT_TASK'
} as const;

export type WsEvent = typeof WsEvents[keyof typeof WsEvents];

export interface BaseMessage {
  type: WsEvent,
  event?: WsEvent, // поправить это двоевластие
  token?: string,
}

export interface SetClientState extends BaseMessage {
  data: {
    id: string,
    state: boolean
  }
}

export interface SetStateInfoRobot extends BaseMessage {
  data: {
    id_robot: UUID,
    id_info_robot: UUID,
    active: boolean
  }
}

type infoRobot = {
  id: UUID,
  id_robot: UUID,
  id_task: UUID,
  id_client: UUID,
  begin_date: string,
  end_date: string,
  cdate: string,
  status: boolean,
  value1: any,
  value2: any,
  value3: any,
  value4: any,
  value5: any,
  error: string
}

export interface SetTaskStarted extends BaseMessage {
  data: infoRobot
}
export interface SetTaskStoped extends BaseMessage {
  data: infoRobot
}

export interface SetInfoAbouTask extends BaseMessage {
  data: {
    id: string;
    id_info_robots: string;
    status: boolean;
    percent: number;
    current_date: string;
    cdate: string;
    state: string;
    error: string;
    module: string;
    comment: string;
    module_name: string;
  }
}

export type availableMessageType = SetClientState
  | BaseMessage | SetStateInfoRobot
  | SetTaskStoped | SetTaskStarted
  | SetInfoAbouTask

export default class WsClient {
  public client: Optional<WebSocket> = null;
  public isConnected = false;
  alert = resolve<ToastStore>(diConstants.TOASTS)();

  public gateway = configApp.wsUrl;

  subscriptions: Array<(ev: MessageEvent<any>) => void> = []

  logout() {
    this.isConnected = false
    this.client?.close()
  }

  login() {
    this.client = new WebSocket(this.gateway);

    this.client.onopen = () => {
      this.isConnected = true;
      this.sendMessage({
        type: WsEvents.CONNECT_FOR_WEB
      })
      console.log('[Socket] подключено')
    };

    this.client.onclose = () => {
      this.isConnected = false;
      console.log('[Socket] закрыт')
    }

    this.client.onerror = () => {
      this.isConnected = false;
      console.log('[Socket] ошибка')
    };
    this.client.onmessage = (ev: MessageEvent<any>) => {
      for (const listener of this.subscriptions) {
        listener(ev)
      }
      console.log('[Socket] сообщение ', JSON.parse(ev.data))
    }
  }

  setWSAuthInterceptor(token: string) {
    this.addTokenInterceptor = (msg: availableMessageType) => {
      msg.token = token;
      return msg;
    }
  }

  constructor() {
    makeAutoObservable(this, {
      alert: false
    })
  }

  sendMessage(msg: availableMessageType) {
    if (this.isConnected && this.client) {
      this.client.send(
        JSON.stringify(
          this.addTokenInterceptor(msg)
        )
      );
    }
  }

  subscribeOnMessage(callback: (ev: MessageEvent<any>) => void) {
    this.subscriptions.push(callback);
  }

  addTokenInterceptor(msg: availableMessageType) {
    return msg;
  }
}
