import React, { useEffect } from "react";
import { Alert, Fade } from "react-bootstrap";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";

function AppAlert() {
  const store = useStore();
  const alert = store.alert;
  const show = store.alert.show;

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    if (show) {
      timeout = setTimeout(() => {
        alert.closeAlert();
      }, 3000);
    }

    return () => {
      if (timeout != null) {
        clearTimeout(timeout);
      }
    };
  }, [show]);

  if (!alert.show) {
    return null;
  }

  return (
    <Alert
      variant={alert.type}
      style={{ margin: 10 }}
      dismissible
      transition={Fade}
    >
      {alert.msg}
    </Alert>
  );
}

export default observer(AppAlert);
