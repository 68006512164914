import { yupResolver } from '@hookform/resolvers/yup';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Container, InputGroup, Form, Row, Col, Modal, Table, Alert } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Control, Controller, FieldErrorsImpl, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import CenterSpinner from '../components/Spinner/Spinner';
import useStrings from '../hooks/useStrings';
import RefreshIcon from '../icons/Refresh.png';
import { useStore } from '../providers/StoreContext';
import { CreateTaskType, Task, taskValidationSchema, UpdateTaskType } from '../store/tasks/tasks-store';
import { strUndef, toUndefStr } from '../utils/helpers';

const TasksPage = observer(() => {
  const store = useStore();
  const {
    general,
    tasks: text
  } = useStrings(store);
  const history = useHistory();
  const params = useParams<{
    taskId: string
  }>();
  const { tasks } = store;
  const { watchTask, refresh, taskSearcher } = tasks;

  React.useEffect(() => {
    tasks.refresh();
    if (params.taskId)
      watchTask(params.taskId);
  }, [])
  return (
    <Container fluid>
      <CenterSpinner
        isLoad={tasks.isLoading}
      />
      <CreateTaskModal />
      <WatchEditTaskModal />
      <Col>
        <Row className="my-3 justify-content-md-between">
          <Col>
            <Form.Group as={Row}>
              <Col>
                <InputGroup>
                  <InputGroup.Text>
                    {general.search}
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder={general.search}
                    value={taskSearcher.searchTerm}
                    onChange={(e) =>
                      taskSearcher.search(e.currentTarget.value)
                    }
                  />
                </InputGroup>
              </Col>
              <Col>
                <Button
                  variant='outline-secondary'
                  onClick={() => tasks.beginCreateTask()}
                >
                  {text.addTask}
                </Button>
              </Col>
            </Form.Group>
          </Col>
          <Col sm={1} className="col-form-label">
            <img
              src={RefreshIcon}
              className='refresh_btn'
              onClick={() => refresh()}
            />
          </Col>
        </Row>
        <Row>
          <Table
            striped
            bordered
            hover
            size='sm'
            style={{
              fontSize: '14px'
            }}
          >
            <thead>
              <tr>
                <th>{text.taskName}</th>
                <th>{text.robot}</th>
                <th>{text.firstStart}</th>
                <th>{text.repeats}</th>
                <th>{text.starts}</th>
                <th>{text.startInterval}</th>
                <th>{text.status}</th>
                <th>{text.rpaClient}</th>
              </tr>
            </thead>
            <tbody>
              {
                (taskSearcher.isSearching
                  ? taskSearcher.result
                  : tasks.tasks
                ).map((task: Task) =>
                  <tr
                    key={task.id}
                    onClick={() => {
                      watchTask(task.id);
                      history.push('/tasks/' + task.id)
                    }}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    <td>{task.taskName}</td>
                    <td>{task.robotname}</td>
                    <td>
                      {moment(task.first_start_task)
                        .format("YYYY-MM-DD HH:mm:ss")
                      }
                    </td>
                    <td>{task.count_povtor}</td>
                    <td>{task.count_works}</td>
                    <td>
                      {
                        task.inv_year + ':' +
                        task.inv_month + ':' +
                        task.inv_day + ':' +
                        task.inv_hour + ':' +
                        task.inv_minute + ':' +
                        task.inv_sec
                      }
                    </td>
                    <td>{task.last_work_status}</td>
                    <td>{task.machine_name}</td>
                  </tr>
                )
              }
            </tbody>
          </Table>
        </Row>
      </Col>
    </Container>
  )
})

export default TasksPage;

export const CreateTaskModal = observer(() => {
  const store = useStore();
  const {
    general,
    tasks: text,
  } = useStrings(store);
  const {
    tasks,
    robots: { robots },
    clients: { clients }
  } = store;
  const { createTaskModal } = tasks;
  const history = useHistory();

  const createTask: SubmitHandler<CreateTaskType> =
    data => tasks.createTask(data);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: {
      errors,
      isDirty
    } } = useForm<CreateTaskType>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(taskValidationSchema),
      defaultValues: {
        _count_povtor: '0',
        _interval: '0:0:0:0:0:0',
        _start_date: '',
        _task_name: '',
        _robot_guid: '',
        _machine_name: '',
        inv_year: '0',
        inv_month: '0',
        inv_day: '0',
        inv_hour: '0',
        inv_minute: '0',
        inv_sec: '0'
      }
    });

  const setStartInterval = () => {
    const values = getValues();
    setValue(
      '_interval',
      values.inv_year + ':' +
      values.inv_month + ':' +
      values.inv_day + ':' +
      values.inv_hour + ':' +
      values.inv_minute + ':' +
      values.inv_sec
    )
  }
  const close = () => {
    reset();
    createTaskModal.close();
    history.push('/tasks');
    setIntervalInputModal(false);
  }

  const [intervalInputModal, setIntervalInputModal] = useState(false);
  return (
    <Modal
      show={createTaskModal.show}
      onHide={close}
      size='lg'
    >
      <Modal.Body>
        <IntervalInputModal
          show={intervalInputModal}
          onHide={() => setIntervalInputModal(false)}
          setValues={() => setStartInterval()}
          control={control as Control<any, any>}
          errors={errors}
        />
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            {text.taskName}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name='_task_name'
              render={({ field }) =>
                <>
                  <Form.Control
                    placeholder={text.taskName}
                    {...field}
                  />
                  {errors._task_name?.message &&
                    <Alert>{errors._task_name?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.robot}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_robot_guid"
              render={({ field }) => (
                <>
                  <Form.Control
                    as={"select"}
                    value={strUndef(field.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(e.target.value)
                    }
                  >
                    <option value={""}>{text.selectRobot}</option>
                    {robots.map((robot) =>
                      <option value={robot.id} key={robot.id}>
                        {robot.robotname}
                      </option>
                    )}
                  </Form.Control>
                  {errors._robot_guid?.message &&
                    <Alert>{errors._robot_guid?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.firstStart}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_start_date"
              render={({ field }) => (
                <>
                  <Form.Control
                    type="datetime-local"
                    {...field}
                  />
                  {errors._start_date?.message &&
                    <Alert>{errors._start_date?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.repeats}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_count_povtor"
              render={({ field }) => (
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {errors._count_povtor?.message &&
                    <Alert>{errors._count_povtor?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.startInterval}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_interval"
              render={({ field }) => (
                <>
                  <Form.Control
                    onClick={() => setIntervalInputModal(true)}
                    value={field.value}
                    readOnly={true}
                  />
                  {errors._interval?.message &&
                    <Alert>{errors._interval?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.rpaClient}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_machine_name"
              render={({ field }) => (
                <>
                  <Form.Control
                    as={"select"}
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(e.target.value)
                    }
                  >
                    <option value={""}>{text.selectClient}</option>
                    {clients.map((client) =>
                      <option value={client.name} key={client.id}>
                        {client.name}
                      </option>
                    )}
                  </Form.Control>
                  {errors._machine_name?.message &&
                    <Alert>{errors._machine_name?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-danger'
          onClick={close}
        >
          {general.close}
        </Button>
        <Button
          variant='outline-success'
          disabled={!isDirty}
          onClick={handleSubmit(createTask)}
        >
          {general.save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})

export const WatchEditTaskModal = observer(() => {
  const store = useStore();
  const {
    general,
    tasks: text,
  } = useStrings(store);
  const { tasks,
    robots: { robots },
    clients: { clients }
  } = store;
  const { watchModal } = tasks;
  const currentTask = tasks.currentTask as Task;
  const history = useHistory();

  const updateTask: SubmitHandler<UpdateTaskType> =
    data => tasks.updateTask(data);

  const {
    control,
    reset,
    setValue,
    getValues,
    handleSubmit,
    formState: {
      errors,
      isDirty
    } } = useForm<UpdateTaskType>({
      mode: 'onChange',
      reValidateMode: 'onChange',
      resolver: yupResolver(taskValidationSchema),
      defaultValues: {
        _count_povtor: '0',
        _interval: '0:0:0:0:0:0',
        _start_date: '',
        _task_name: '',
        _robot_guid: '',
        _machine_name: '',
        inv_year: '0',
        inv_month: '0',
        inv_day: '0',
        inv_hour: '0',
        inv_minute: '0',
        inv_sec: '0'
      }
    });
  useEffect(() => {
    setValue('_task_id', toUndefStr(currentTask?.id));
    setValue('_count_povtor', toUndefStr(currentTask?.count_povtor || '0'));
    setValue('_start_date', moment(toUndefStr(currentTask?.first_start_task)).format("YYYY-MM-DD HH:mm:ss"));
    setValue('_task_name', toUndefStr(currentTask?.taskName));
    setValue('_robot_guid', toUndefStr(currentTask?.robotvcode));
    setValue('_machine_name', toUndefStr(currentTask?.machine_name));
    const inv_year = toUndefStr(currentTask?.inv_year || '0');
    const inv_month = toUndefStr(currentTask?.inv_month || '0');
    const inv_day = toUndefStr(currentTask?.inv_day || '0');
    const inv_hour = toUndefStr(currentTask?.inv_hour || '0');
    const inv_minute = toUndefStr(currentTask?.inv_minute || '0');
    const inv_sec = toUndefStr(currentTask?.inv_sec || '0');
    setValue('inv_year', inv_year);
    setValue('inv_month', inv_month);
    setValue('inv_day', inv_day);
    setValue('inv_hour', inv_hour);
    setValue('inv_minute', inv_minute);
    setValue('inv_sec', inv_sec);
    setValue(
      '_interval',
      inv_year + ':' +
      inv_month + ':' +
      inv_day + ':' +
      inv_hour + ':' +
      inv_minute + ':' +
      inv_sec
    );
  }, [currentTask])

  const setStartInterval = () => {
    const values = getValues();
    setValue(
      '_interval',
      values.inv_year + ':' +
      values.inv_month + ':' +
      values.inv_day + ':' +
      values.inv_hour + ':' +
      values.inv_minute + ':' +
      values.inv_sec
    )
  }
  const close = () => {
    reset();
    watchModal.close();
    history.push('/tasks');
    setIntervalInputModal(false);
  }

  const [intervalInputModal, setIntervalInputModal] = useState(false);
  return (
    <Modal
      show={watchModal.show}
      onHide={close}
      size='lg'
    >
      <Modal.Body>
        <IntervalInputModal
          show={intervalInputModal}
          onHide={() => setIntervalInputModal(false)}
          setValues={() => setStartInterval()}
          control={control}
          errors={errors}
        />
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            {text.taskName}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name='_task_name'
              render={({ field }) =>
                <>
                  <Form.Control
                    placeholder={text.taskName}
                    {...field}
                  />
                  {errors._task_name?.message &&
                    <Alert>{errors._task_name?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.robot}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_robot_guid"
              render={({ field }) => (
                <>
                  <Form.Control
                    as={"select"}
                    value={strUndef(field.value)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(e.target.value)
                    }
                  >
                    <option value={""}>
                      {text.selectRobot}
                    </option>
                    {robots.map((robot) =>
                      <option value={robot.id} key={robot.id}>
                        {robot.robotname}
                      </option>
                    )}
                  </Form.Control>
                  {errors._robot_guid?.message &&
                    <Alert>{errors._robot_guid?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.firstStart}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_start_date"
              render={({ field }) => (
                <>
                  <Form.Control
                    type="datetime-local"
                    {...field}
                  />
                  {errors._start_date?.message &&
                    <Alert>{errors._start_date?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.repeats}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_count_povtor"
              render={({ field }) => (
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {errors._count_povtor?.message &&
                    <Alert>{errors._count_povtor?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.startInterval}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_interval"
              render={({ field }) => (
                <>
                  <Form.Control
                    onClick={() => setIntervalInputModal(true)}
                    value={field.value}
                    readOnly={true}
                  />
                  {errors._interval?.message &&
                    <Alert>{errors._interval?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {text.rpaClient}
          </Form.Label>
          <Col>
            <Controller
              control={control}
              name="_machine_name"
              render={({ field }) => (
                <>
                  <Form.Control
                    as={"select"}
                    value={field.value}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      field.onChange(e.target.value)
                    }
                  >
                    <option value={""}>
                      {text.selectClient}
                    </option>
                    {clients.map((client) =>
                      <option value={client.name} key={client.id}>
                        {client.name}
                      </option>
                    )}
                  </Form.Control>
                  {errors._machine_name?.message &&
                    <Alert>{errors._machine_name?.message}</Alert>
                  }
                </>
              )}
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='outline-danger'
          onClick={close}
        >
          {general.close}
        </Button>
        <Button
          variant='danger' // todo disable if active
          onClick={() => tasks.deleteTask(currentTask.id)}
        >
          {general.delete}
        </Button>
        <Button
          variant='success'
          disabled={!isDirty}
          onClick={handleSubmit(updateTask)}
        >
          {general.save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})


const IntervalInputModal = observer((props: {
  show: boolean,
  onHide: () => void,
  control: Control<any, any>
  errors: FieldErrorsImpl,
  setValues: () => void
}) => {
  const store = useStore();
  const { tasks, general } = useStrings(store);
  const errored = Boolean(
    props.errors?.inv_sec
    || props.errors?.inv_minute
    || props.errors?.inv_hour
    || props.errors?.inv_day
    || props.errors?.inv_month
    || props.errors?.inv_year
  )

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size='lg'
    >
      <Modal.Body>
        <Form.Group as={Row}>
          <Form.Label column sm={4}>
            {tasks.year}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_year'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_year?.message &&
                    <Alert>{props.errors.inv_year?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {tasks.month}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_month'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_month?.message &&
                    <Alert>{props.errors.inv_month?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {tasks.day}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_day'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_day?.message &&
                    <Alert>{props.errors.inv_day?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {tasks.hour}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_hour'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_hour?.message &&
                    <Alert>{props.errors.inv_hour?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {tasks.minute}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_minute'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_minute?.message &&
                    <Alert>{props.errors.inv_minute?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mt-3'>
          <Form.Label column sm={4}>
            {tasks.second}
          </Form.Label>
          <Col>
            <Controller
              control={props.control}
              name='inv_sec'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="number"
                    {...field}
                  />
                  {props.errors.inv_sec?.message &&
                    <Alert>{props.errors.inv_sec?.message}</Alert>
                  }
                </>
              }
            />
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={errored}
          onClick={() => {
            props.setValues();
            props.onHide();
          }}
          variant='outline-success'
        >
          {general.save}
        </Button>
      </Modal.Footer>
    </Modal>
  )
})