const diConstants = {
  HTTP: Symbol.for("HTTP"),
  WS: Symbol.for("WS"),

  TOASTS: Symbol.for("TOASTS"),

  ALERT: Symbol.for("ALERT"),
  APP_LICENSE: Symbol.for("APP_LICENSE"),

  AUTH: {
    AUTH_USER: Symbol.for("AUTH_USER"),
    AUTH_STORE: Symbol.for("AUTH_STORE"),
  },

  CLIENTS: {
    CLIENT: Symbol.for("CLIENT"),
    CLIENT_STORE: Symbol.for("CLIENT_STORE"),
  },

  INFO_ROBOTS: {
    INFO_ROBOT_DETAIL: Symbol.for("INFO_ROBOT_DETAIL"),
    INFO_ROBOT: Symbol.for("INFO_ROBOT"),
    INFO_ROBOT_STORE: Symbol.for("INFO_ROBOT_STORE"),
  },

  LANG: {
    LANG: Symbol.for("LANG"),
    LANG_STORE: Symbol.for("LANG_STORE"),
  },

  LICENSE: {
    LICENSE: Symbol.for("LICENSE"),
    LICENSE_STORE: Symbol.for("LICENSE_STORE"),
  },

  LOG: {
    LOG: Symbol.for("LOG"),
    HTTP_LOG: Symbol.for("HTTP_LOG"),
    LOGS_STORE: Symbol.for("LOG_STORE"),
    HTTP_LOGS_STORE: Symbol.for("HTTP_LOGS_STORE"),
  },

  NOTIFICATION: {
    NOTIFICATION: Symbol.for("NOTIFICATION"),
    NOTIFICATION_STORE: Symbol.for("NOTIFICATION_STORE"),
  },

  ROBOTS: {
    ROBOT: Symbol.for("ROBOT"),
    ROBOT_STORE: Symbol.for("ROBOT_STORE"),
  },

  TASKS: {
    TASKS: Symbol.for("TASKS"),
    TASKS_STORE: Symbol.for("TASKS_STORE"),
  },

  ROLES: {
    ROLE_MODEL: Symbol.for("ROLE_MODEL"),
    ROLES_STORE: Symbol.for("ROLES_STORE"),
  },

  USERS: {
    USER: Symbol.for("USER"),
    USERS_STORE: Symbol.for("USERS_STORE"),
  },

  VARS: {
    GLOBAL_VARIABLE: Symbol.for("GLOBAL_VARIABLE"),
    VARS_STORE: Symbol.for("VARS_STORE"),
  },

  GROUPS: {
    GROUP_USER: Symbol.for("GROUP_USER"),
    GROUP: Symbol.for("GROUP"),
    GROUP_STORE: Symbol.for("GROUP_STORE"),
  },

  ROOT_STORE: Symbol.for("ROOT_STORE"),
} as const;

export { diConstants };
