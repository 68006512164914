import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import HttpClient from "./http-client";

function setupRequestsDI(container: Container) {
  container
    .bind<HttpClient>(diConstants.HTTP)
    .to(HttpClient)
    .inSingletonScope();

  return container;
}

export default setupRequestsDI;
