import React, { useState } from 'react'
import { Dropdown, Form, Button, Accordion, InputGroup } from 'react-bootstrap';
import PassOwnerModal from '../../passOwnerModal/passOwnerModal';
import GroupLogo from '../../../../../icons/Group.png';
import UserLogo from '../../../../../icons/User.png';
import { useStore } from '../../../../../providers/StoreContext';
import { RobotOwner } from '../../../../../store';
import { observer } from 'mobx-react-lite';
import VirtualScroll from '../../../../../components/VirtualScroll/VirtualScroll';
import { Optional } from '../../../../../types';
import useStrings from '../../../../../hooks/useStrings';

function ChangeOwner() {
  const store = useStore();
  const {
    robots: text,
    general
  } = useStrings(store);
  const { robots } = store;
  const {
    currentRobot,
    potentialOwners,
    potentialOwnersSearchResult
  } = robots;

  const [
    selectedNewOwner,
    setSelectedNewOwner
  ] = useState<Optional<RobotOwner>>(null);
  const [shareConfirn, setShareConfirn] = useState(false);

  function closeModal() {
    setSelectedNewOwner(null);
    robots.changeOwnerModal.close();
  }

  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>{text.pass_owner + '?'}</Accordion.Header>
        <Accordion.Body>
          <PassOwnerModal
            show={shareConfirn}
            onHide={() => {
              closeModal();
              setShareConfirn(false);
            }}
            robot={currentRobot}
            newOwnerId={selectedNewOwner?.id || null}
          />
          <InputGroup>
            <Form.Label column>{text.newOwner}</Form.Label>
            <Dropdown >
              <Dropdown.Toggle variant='outline-secondary'>
                {selectedNewOwner?.name || text.choseUserOrGroup}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: '100%' }}>
                <Form.Control
                  size="sm"
                  className='mb-1'
                  placeholder={text.seacrByLoginOrGroupName}
                  onChange={
                    (e) =>
                      robots.searchTerm = e.currentTarget.value
                  }
                  value={robots.searchTerm}
                />
                {!potentialOwners?.length ?
                  <div>{general.notFound}</div>
                  :
                  <VirtualScroll
                    data={potentialOwnersSearchResult}
                    visibleRows={10}
                    rowHeight={33}
                    render={(owner: RobotOwner) =>
                      <Dropdown.Item
                        onClick={() => setSelectedNewOwner(owner)}
                        key={owner.id}
                      >
                        <img
                          src={owner.type === 'GROUP'
                            ? GroupLogo
                            : UserLogo
                          }
                          height={20}
                          style={{ marginRight: '5px' }}
                        />
                        {owner.name}
                      </Dropdown.Item>
                    }
                  />
                }
              </Dropdown.Menu>
            </Dropdown>



            <Button
              variant='outline-secondary'
              disabled={!selectedNewOwner?.name}
              onClick={() => setShareConfirn(true)}
            >
              {text.pass_owner}
            </Button>
          </InputGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )

  // return (
  //   <Col style={{ marginLeft: '40px' }}>
  //     <Row className="mb-3">
  //       <NavLink onClick={() => setChangeOwner(true)} to={'#'}>
  //         Сменить владельца?
  //       </NavLink>
  //     </Row>
  //     {changeOwner
  //       ? <>
  //         <PassOwnerModal
  //           show={shareConfirn}
  //           onHide={() => {
  //             closeModal();
  //             setShareConfirn(false);
  //           }}
  //           robot={currentRobot}
  //           newOwnerId={selectedNewOwner?.id || null}
  //         />
  //         <Form.Group as={Row} className="mb-3" controlId="formPlaintextname">
  //           <Form.Label column sm="3">
  //             Новый владелец
  //           </Form.Label>
  //           <Col className='d-flex mb-3'>
  //             <Dropdown >
  //               <Dropdown.Toggle variant='outline-primary'>
  //                 {selectedNewOwner?.name || 'Выберите пользователя или группу'}
  //               </Dropdown.Toggle>
  //               <Dropdown.Menu style={{ width: '100%' }}>
  //                 <Form.Control
  //                   size="sm"
  //                   placeholder="Искать по логину или имени группы"
  //                   onChange={
  //                     (e) =>
  //                       robots.searchTerm = e.currentTarget.value
  //                   }
  //                   value={robots.searchTerm}
  //                 />
  //                 {!potentialOwners?.length ?
  //                   <div>Ничего не найдено</div>
  //                   :
  //                   <VirtualScroll
  //                     data={potentialOwnersSearchResult}
  //                     rowHeight={33}
  //                     visibleRows={10}
  //                     render={(o: RobotOwner) =>
  //                       <Dropdown.Item
  //                         onClick={() => setSelectedNewOwner(o)}
  //                         key={o.id}
  //                       >
  //                         <img
  //                           src={o.type === 'GROUP'
  //                             ? GroupLogo
  //                             : UserLogo
  //                           }
  //                           height={20}
  //                           style={{ marginRight: '5px' }}
  //                         />
  //                         {o.name}
  //                       </Dropdown.Item>
  //                     }
  //                   />
  //                 }
  //               </Dropdown.Menu>
  //             </Dropdown>
  //             <Button
  //               style={{ marginLeft: 'auto' }}
  //               disabled={!selectedNewOwner?.name}
  //               variant='danger'
  //               onClick={() => setShareConfirn(true)}
  //             >
  //               Отдать робота
  //             </Button>
  //           </Col>
  //           <hr />
  //         </Form.Group>
  //       </>
  //       : null
  //     }
  //   </Col>
  // )
}
export default observer(ChangeOwner);