import { makeAutoObservable, flow } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import Notification, { INotification } from "./notification";
import { Notice } from "./type";

export default class NotificationStore {
  notifications: INotification[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAllNotifications = flow(function* (this: NotificationStore) {
    try {
      const httpCient = injectHttpClient();
      const notifications: INotification[] = yield httpCient.get<Notice[]>(
        "api/notifications/"
      );

      this.notifications = [];
      notifications.forEach((notification) => {
        this.notifications.push(new Notification().fromJSON(notification));
      });
    } catch (err) {
      console.error(err);
    }
  });

  addNotification = flow(function* (
    this: NotificationStore,
    title: string,
    msg: string,
    receiver: string
  ) {
    try {
      const httpCient = injectHttpClient();
      const data = {
        title,
        msg,
        receiver,
      };
      const response: Notice = yield httpCient.post<typeof data, Notice>(
        "/api/notifications/",
        data
      );

      console.log(response);

      this.notifications.push(new Notification().fromJSON(response));
    } catch (err) {
      console.error(err);
    }
  });

  deleteNotification = flow(function* (
    this: NotificationStore,
    id: number | string | undefined
  ) {
    try {
      const httpCient = injectHttpClient();
      yield httpCient.delete(`/api/notifications/${id}`);
      const index = this.notifications.findIndex((n) => n.id === id);
      this.notifications.splice(index, 1);
    } catch (err) {
      console.error(err);
    }
  });
}
