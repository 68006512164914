import React, { useEffect, useState } from "react";
import { Navbar, Nav, Dropdown } from "react-bootstrap";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppModal from "../AppModal/AppModal";
import Notifications from "../Notifications/Notifications";
import LanguageSwitcherSelector from "../LanguageSwitcherSelector/LanguageSwitcherSelector";
import useStrings from "../../hooks/useStrings";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import useInterval from "../../hooks/useInterval";

function AppNavBar() {
  const history = useHistory();
  const location = useLocation();
  const store = useStore();
  const { login, roles } = store.auth.user.user;
  const strings = useStrings(store);
  const { appNavBar, notice } = strings;
  const [showModalNotification, setShowModalNotification] = useState(false);
  function onHideModalNotification() {
    setShowModalNotification(!showModalNotification);
  }
  async function onLogOut() {
    store.auth.exitAuth();
    window.history.pushState("", "", "/login");
  }

  useEffect(() => {
    store.notifications.getAllNotifications()
  }, [])

  useInterval(() => {
    store.notifications.getAllNotifications()
  }, 3000)

  const pathname = location.pathname;

  const isAdmin = roles.find((role) =>
    role.value == 'ADMIN'
  );

  return (
    <>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand style={{ marginLeft: 20 }} href="/">
          Lexema Orchestrator
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
            activeKey={pathname}
          >
            {/* <NavLink
              activeClassName="active"
              className="nav-link"
              to="/dashboard"
            >
              {appNavBar.dashboard}
            </NavLink> */}
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/robots"
            >
              {appNavBar.robots}
            </NavLink>
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/clients"
            >
              {appNavBar.clients}
            </NavLink>
            <NavLink
              activeClassName="active"
              className="nav-link"
              to="/tasks"
            >
              {appNavBar.tasks}
            </NavLink>
            <NavLink activeClassName="active" className="nav-link" to="/docs">
              {appNavBar.docs}
            </NavLink>
            <NavLink activeClassName="active" className="nav-link" to="/groups">
              {appNavBar.groups}
            </NavLink>
          </Nav>
          <LanguageSwitcherSelector />
          <Badge
            style={{ marginRight: 20 }}
            badgeContent={store.notifications.notifications.length}
            color="primary"
            onClick={onHideModalNotification}
          >
            <MailIcon fontSize="large" />
          </Badge>
          <Dropdown style={{ marginRight: 20 }}>
            <Dropdown.Toggle id="dropdown-button-dark-example1" variant="light">
              <AccountCircleIcon style={{ marginRight: 20 }} fontSize="large" />
              {login}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => history.push("/lk")}>
                {appNavBar.lk}
              </Dropdown.Item>
              {isAdmin &&
                <Dropdown.Item onClick={() => history.push("/admin")}>
                  {appNavBar.admin}
                </Dropdown.Item>
              }
              <Dropdown.Divider />
              <Dropdown.Item onClick={onLogOut}>{appNavBar.exit}</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
      </Navbar>
      <AppModal
        show={showModalNotification}
        onHide={onHideModalNotification}
        title={notice.notifications}
        BodyComponent={Notifications}
      />
    </>
  );
}

export default observer(AppNavBar);
