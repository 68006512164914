import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface ConfirmDialogProps {
  show: boolean,
  onClose: () => void,
  question: string,
  onConfirm: () => void,
}

export default function ConfirmDialog({
  show,
  question,
  onClose,
  onConfirm
}: ConfirmDialogProps) {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {/* todo lang */}
          Подтвердите действие
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* todo lang */}
        <p>{question}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-success"
          onClick={onClose}
        >
          {/* todo lang */}
          Закрыть
        </Button>
        <Button
          variant="outline-danger"
          onClick={() => onConfirm()}
        >
          {/* todo lang */}
          Подтвердить
        </Button>
      </Modal.Footer>
    </Modal>
  )
}