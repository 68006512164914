import { makeAutoObservable, observable } from "mobx";

class ToastStore {
  errors: any[] = []
  messages: string[] = []
  constructor() {
    this.show = this.show.bind(this);
    this.addError = this.addError.bind(this);
    this.closeError = this.closeError.bind(this);
    this.closeMessage = this.closeMessage.bind(this);
    makeAutoObservable(this)
  }

  get isEmpty() {
    return !this.errors.length &&
      !this.messages.length
  }

  addError(error: any) {
    this.errors.push(error);
  }

  show(text: string) {
    this.messages.push(text)

    const index = this.messages.indexOf(text);

    let timeout: ReturnType<typeof setTimeout> | null = null;
    timeout = setTimeout(() => {
      if (index > -1) {
        this.closeMessage(index);
      }
    }, 5000);
  }

  closeMessage(index: number) {
    this.messages.splice(index, 1);
  }
  closeError(index: number) {
    this.errors.splice(index, 1);
  }
}

export default ToastStore;
