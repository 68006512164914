/* eslint-disable @typescript-eslint/ban-ts-comment */
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { splitIntoChunks } from "../../utils";
import { makeAutoObservable, flow, when } from "mobx";
import Robot, { MY_GROUP_ROBOT, MY_ROBOT, RequestRobot } from "./robot";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { getOwners, withNames } from "../../pages/PublishRobotsPage/helpers";
import { Modal, Searcher } from "../../utils/helpers";
import { InfoRobotsStore } from "../info_robots";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { ToastStore } from "../errors";
import { rootStore } from "../../providers/StoreContext";
import { injectWebSocketClient } from "../../features/websocket/inject-ws-client";
import WsClient, { availableMessageType, SetStateInfoRobot } from "../../features/websocket/ws-client";

export interface IShareRobot {
  accessor_id: string,
  read: boolean,
  write: boolean,
  create: boolean,
  delete: boolean
}

export default class RobotStore {
  public readonly toasts!: ToastStore;
  infoRobots: InfoRobotsStore;
  ws: WsClient = injectWebSocketClient();
  state: LoadStatesType = LoadStates.INITIAL;
  robots: Robot[] = [];

  errors: any[] = [];

  subscribeWsMessages() {
    this.ws.subscribeOnMessage((ev: MessageEvent<any>) => {
      const message = JSON.parse(ev.data) as availableMessageType;
      switch (message.event) {
        case 'SET_STATE_INFO_ROBOT': {
          const msg = message as SetStateInfoRobot;
          const {
            active,
            id_robot,
            id_info_robot,
          } = msg.data;
          const robot = this.getRobotById(id_robot);

          const info_robot = this.infoRobots.info_robots.find((ir) =>
            ir.id == id_info_robot
          )
          if (info_robot)
            info_robot.isActive = true;

          if (robot) {
            robot.isActive = active;
            rootStore.toasts.show(
              "Robot " + robot.robotname +
              (active
                ? " started"
                : " stoped"
              )
            );
          }

          this.updateStateRobot(id_robot, active);
          rootStore.infoRobots._updateStateInfoRobot(
            id_info_robot,
            active
          )
          break;
        }
      }
    })
  }


  updateStateRobot(
    id_robot: string,
    state: boolean
  ) {
    const targetRobot = this.getRobotById(id_robot);
    if (!targetRobot) return;
    targetRobot.isActive = state;
    this.robots = [
      ...this.robots.filter((robot) => robot.id != id_robot),
      targetRobot
    ]
  }

  constructor() {
    makeAutoObservable(this, {
      toasts: false
    });
    wire(this, "toasts", diConstants.TOASTS);

    this.getCurrentRobotAccesses = this.getCurrentRobotAccesses.bind(this);
    this.currentUsersRobots = this.currentUsersRobots.bind(this);
    this.dowloadRobotFile = this.dowloadRobotFile.bind(this);
    this.deleteAccessHttp = this.deleteAccessHttp.bind(this);
    this.passRobotOwner = this.passRobotOwner.bind(this);
    this.shareRobotHttp = this.shareRobotHttp.bind(this);
    this.deleteRobot = this.deleteRobot.bind(this);
    this.deleteAccess = this.deleteAccess.bind(this);
    this.getMyRobots = this.getMyRobots.bind(this);
    this.shareRobot = this.shareRobot.bind(this);
    this.watchRobot = this.watchRobot.bind(this);
    this.getRobots = this.getRobots.bind(this);
    this.refresh = this.refresh.bind(this);
    this.subscribeWsMessages();

    this.infoRobots = rootStore.infoRobots
  }
  /**
   * selected robot
   */
  _currentRobot: Robot | null = null;

  // возможные владельцы
  get potentialOwners() {
    return getOwners(this.currentRobot?.robot_owner)
  }

  _searchTerm = '';
  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(term: string) {
    this._searchTerm = term;
  }

  get onlyModules() {
    return this.robots.filter(
      (robot) =>
        robot.isModule == true
    )
  }

  get onlyRobots() {
    return this.robots.filter(
      (robot) =>
        robot.isModule == false
    )
  }

  // get onlyActive() {
  //   this.robots.map((robot) => {

  //   })
  // }

  getRobotStartHistory(robotId: string) {
    return this.infoRobots.info_robots.filter((ir) =>
      ir.id_robot == robotId
    )
  }

  // isActive(robotId: string) {
  //   const startHistory = this.getRobotStartHistory(robotId);
  //   if (startHistory.length) {
  //     startHistory.forEach((infoRobot) => {
  //        infoRobot.detail.filter((detail) => {

  //       })
  //     })
  //   }
  //   return false
  // }

  /**
   * Поиск по списку
   */
  get potentialOwnersSearchResult() {
    if (!this.searchTerm.length)
      return this.potentialOwners;

    return this.potentialOwners.filter((a) =>
      a.name
        .toLowerCase()
        .includes(this.searchTerm)
    )
  }

  get currentRobot() {
    return this._currentRobot;
  }

  set currentRobot(robot: Robot | null) {
    this._currentRobot = robot;
  }

  _currentRobotAccesses: RobotAccess[] = []

  currentRobotSelectedAccess: RobotAccess | null = null

  get currentRobotAccesses() {
    return this._currentRobotAccesses
  }

  set currentRobotAccesses(accesses: RobotAccess[]) {
    this._currentRobotAccesses = accesses;
  }




  get canManageCurrentRobot() {
    return this.currentRobot?.ownership_type === MY_GROUP_ROBOT
      || this.currentRobot?.ownership_type === MY_ROBOT
  }

  robotSearcher = new Searcher(() => this.onlyRobots)
  moduleSearcher = new Searcher(() => this.onlyModules)

  // модалки
  watchModal = new Modal()
  deleteModal = new Modal()
  shareModal = new Modal()
  deleteAccessModal = new Modal()
  changeOwnerModal = new Modal()

  // показать модалки
  async watchRobot(robotId: string) {
    when(
      () => this.state === LoadStates.COMPLETED,
      async () => {
        const robot = this.robots.find((robot) =>
          robot.id === robotId
        )
        if (!robot) {
          this.watchModal.close();
          this.toasts.addError(
            new Error('Этого робота не существует или он вам не доступен')
          );
          return;
        }

        this.currentRobot = robot;
        await this.getCurrentRobotAccesses();
        this.infoRobots.loadInfoRobots(robot.id)
        this.watchModal.open();
      }
    )
  }
  async closeWatchRobot() {
    this.watchModal.close();
    this.currentRobot = null;
    this.currentRobotSelectedAccess = null
    this.infoRobots.info_robots = [];
    this.currentRobotAccesses = [];
  }
  shareRobot(robot: Robot) {
    this.currentRobot = robot;
    this.shareModal.open();
  }
  confirmDeleteRobot(robot: Robot) {
    this.currentRobot = robot;
    this.deleteModal.open();
  }
  deleteAccess(access: RobotAccess) {
    this.currentRobotSelectedAccess = access;
    this.deleteAccessModal.open();
  }

  get isLoading() {
    return this.state === "LOADING";
  }

  get isError() {
    return this.state === "FAILED";
  }

  get chunks() {
    return splitIntoChunks<RequestRobot>(this.robots, 6);
  }


  currentUsersRobots(user_id: string) {
    return this.robots.filter(
      (robot) => robot.robot_owner === user_id
    );
  }

  refresh() {
    this.getRobots()
  }

  getRobots = flow(function* (this: RobotStore) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const robots: RequestRobot[] = yield http.get<RequestRobot[]>("/api/robots?type=all");
      this.robots = [];
      robots.forEach((robot) => {
        this.robots.push(
          new Robot().fromJSON({ ...robot, noclicker: robot.noclicker === 1 })
        );
      });

      this.onSuccess();
    } catch (err) {
      this.onError(err);
    }
  });


  deleteRobot = flow(function* (this: RobotStore, robot_id: string) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      yield http.delete(`/api/robots/${robot_id}`)

      this.robots = this.robots.filter((robot) =>
        robot_id !== robot.id
      )

      this.onSuccess("Робот удален");
    } catch (err) {
      this.onError(err);
    }
  });


  passRobotOwner = flow(function* (
    this: RobotStore,
    robot_id: string,
    newOwnerId: string
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      yield http.patch(`/api/robots/${robot_id}/robot_owner`, { newOwnerId })
      this.getRobots();
      this.onSuccess('Робот передан новому владельцу');
    } catch (err) {
      this.onError(err);
    }
  });


  async dowloadRobotFile(robotId: string) {
    try {
      const http = injectHttpClient();
      await http.get(
        `/api/robots/file/${robotId}`,
        [],
        { responseType: 'blob' }
      )
        .then(async (response: any) => {
          const dowloadUrl = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = dowloadUrl;
          link.download = `${robotId}.rpa`;
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
    } catch (err) {
      console.error(err);
      this.errors.push(err);
    }
  }

  getCurrentRobotAccesses = flow(function* (
    this: RobotStore,
  ) {
    this.onBegin();
    const http = injectHttpClient();
    yield http.get<RobotAccess[]>(`api/robots/${this.currentRobot?.id}/accesses`)
      .then((accesses) =>
        this.currentRobotAccesses = withNames(
          accesses,
          this.potentialOwners
        )
      )
      .then(() =>
        this.onSuccess()
      )
      .catch((err) =>
        this.onError(err)
      )
  })

  shareRobotHttp = flow(function* (
    this: RobotStore,
    access: Omit<RobotAccess, 'id' | 'robot_id' | 'createdAt' | 'updatedAt' | 'name'>,
    robotId: string
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      yield http.post(`/api/robots/${robotId}/access`, access)
        .then((res) => {
          const result = res as { message: string, access: RobotAccess }

          // сразу добавляем в accesses
          this.currentRobotAccesses = withNames(
            [...this.currentRobotAccesses, result.access],
            this.potentialOwners
          )
          const accessor_id = result.access.accessor_id;
          // если робота дали группе сразу добавляем его туда
          if (this.defineAccessorType(accessor_id) == 'GROUP') {
            const group = rootStore.groups.groups.find((group) =>
              group.id == accessor_id
            )
            const robot = this.getRobotById(robotId);
            if (group && robot) {
              group.robots = [...group.robots, robot]
            }
          }
        })
      this.onSuccess('Доступ выдан');
    } catch (err) {
      this.onError(err);
    }
  })

  deleteAccessHttp = flow(function* (
    this: RobotStore
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const robotId = this.currentRobot?.id;
      const currentAccess = this.currentRobotSelectedAccess as RobotAccess;
      const accessId = currentAccess.id;
      yield http.delete(`/api/robots/${robotId}/access/${accessId}`)
        .then(() =>
          this.currentRobotAccesses = this.currentRobotAccesses.filter(
            (r) =>
              r.id !== accessId
          )
        )
        .then(() => {
          // если удалили доступ для группы, то сразу удаляем робота у этой группы
          if (this.defineAccessorType(currentAccess.accessor_id) == 'GROUP') {
            const group = rootStore.groups.groups.find((group) =>
              group.id == currentAccess.accessor_id
            )
            if (group) {
              group.robots = [...group.robots].filter((robot) =>
                robot.id != robotId
              )
            }
          }
        })
      this.deleteAccessModal.close();
      this.onSuccess('Доступ удален');
    } catch (err) {
      this.onError(err);
    }
  })

  getRobotById(robotId: string) {
    return this.robots.find((robot) =>
      robot.id == robotId
    )
  }

  getMyRobots() {
    return this.robots.filter((robot) =>
      robot.ownership_type == MY_ROBOT ||
      robot.ownership_type == MY_GROUP_ROBOT
    )
  }

  defineAccessorType(accessor_id: string) {
    const acessor = this.potentialOwners.find((accessor) =>
      accessor.id == accessor_id
    )
    if (acessor?.type) return acessor.type;
    return null;
  }

  onBegin() {
    this.state = LoadStates.LOADING;
  }

  onSuccess(text?: string) {
    this.state = LoadStates.COMPLETED;
    if (text?.trim().length) this.toasts.show(text);
  }

  onError(err: unknown) {
    console.error(err);
    this.errors.push(err);
    this.state = LoadStates.FAILED;
  }
}

export type RobotAccess = {
  id: number,
  robot_id: string,
  accessor_id: string,
  name: string,
  read: boolean,
  write: boolean,
  create: boolean,
  delete: boolean,
  createdAt: string,
  updatedAt: string
}

export type RobotOwner = {
  type: 'GROUP' | 'USER',
  name: string,
  id: string
}