import React, { useEffect, useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Form,
  Row,
  Col,
  Button,
  Table,
} from "react-bootstrap";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useStrings from "../../hooks/useStrings";
import { observer } from "mobx-react-lite";
import { useStore } from "../../providers/StoreContext";

const GlobalVariable = observer(function GlobalVariable() {
  const store = useStore();
  const { general, globalVariable } = useStrings(store);
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  // const [globalVariables, setGlobalVariables] = useState<IGlobalVariable[]>([])
  const globalVariables = store.vars.vars;

  useEffect(() => {
    store.vars.getAllVars();
  }, []);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    store.vars.addVar(name, value).then(() => {
      setValue("");
      setName("");
    });
  };

  const onDeleteVar = (id: string | number) => {
    store.vars.deleteVar(id);
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Form.Control
              placeholder={globalVariable.nameVar}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder={globalVariable.valueVar}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Col>
          <Col>
            <Button
              variant="success"
              type="submit"
              disabled={!name.trim() || !value.trim()}
            >
              {general.add}
            </Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>{globalVariable.nameVar}</th>
            <th>{globalVariable.valueVar}</th>
            <th>{general.actions}</th>
          </tr>
        </thead>
        <tbody>
          {globalVariables.map((gv) => {
            return (
              <tr key={gv.id}>
                <td>{gv.name}</td>
                <td>{gv.value}</td>
                <td>
                  <DeleteOutlineIcon
                    fontSize={"large"}
                    onClick={() => onDeleteVar(gv.id!)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
});

function SettingPage() {
  const store = useStore();
  const { settingPage } = useStrings(store);
  return (
    <Container className="mainContainer">
      <Tabs
        defaultActiveKey="general_settings"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="general_settings" title={settingPage.generalSettings}>
          {settingPage.info}
        </Tab>
        <Tab eventKey="global_variable" title={settingPage.globalVariable}>
          <GlobalVariable />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default observer(SettingPage);
