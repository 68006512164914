import { AxiosError } from "axios";
import { makeAutoObservable, flow } from "mobx";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { rootStore } from "../../providers/StoreContext";
import { LoadStatesType } from "../../types/load-states";
import { ToastStore } from "../errors";
import { LangStore } from "../lang";
import RoleModel, { IRoleModel } from "./role-model";

type RoleCreationAttrs = {
  value: string,
  description: string,
  resources: {
    name: string,
    read: boolean,
    write: boolean,
    create: boolean,
    delete: boolean
  }[]
}

const LoadTypes = {
  INITIAL: "INITIAL",
  COMPLETED: "COMPLETED",
  LOADING: "LOADING",
  FAILED: "FAILED",
} as const;

class Modal {
  errors = []
  show = false

  constructor() {
    makeAutoObservable(this);
  }

  open() {
    this.show = true
  }
  close() {
    this.show = false
  }
}

class RolesStore {
  state: LoadStatesType = "INITIAL";
  errors: Array<any> = [];

  roles: IRoleModel[] = [];
  selectedRole: IRoleModel | null = null;
  public readonly toasts!: ToastStore;
  public readonly lang!: LangStore;

  constructor() {
    makeAutoObservable(this, {
      toasts: false,
      lang: false
    });
    wire(this, "toasts", diConstants.TOASTS);
    wire(this, "lang", diConstants.LANG.LANG_STORE);
    this.refresh = this.refresh.bind(this);
    this.watchRole = this.watchRole.bind(this);
    this.createRole = this.createRole.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.deleteRole = this.deleteRole.bind(this);
    this.getAllRoles = this.getAllRoles.bind(this);
  }

  get isLoading() {
    return this.state === LoadTypes.LOADING
  }


  private _deleteRoleById(id: number) {
    this.roles = this.roles.filter((v) => v.id !== id);
  }

  watchAndEditRoleModal = new Modal()
  createRoleModal = new Modal()

  watchRole(role: IRoleModel) {
    this.selectedRole = role;
    this.watchAndEditRoleModal.open();
  }

  refresh() {
    this.getAllRoles();
  }

  getAllRoles = flow(function* (this: RolesStore) {
    try {
      this.state = LoadTypes.LOADING;
      const http = injectHttpClient();
      const roles: IRoleModel[] = yield http.get("/api/roles");
      this.roles = [];

      roles.forEach((role) =>
        this.roles.push(
          new RoleModel().fromJSON(role)
        )
      );
      this.state = LoadTypes.COMPLETED;
    } catch (err) {
      this.onError(err);
      throw err;
    }
  });

  createRole = flow(function* (
    this: RolesStore,
    role: RoleCreationAttrs
  ) {
    try {
      this.state = LoadTypes.LOADING;
      const http = injectHttpClient();
      const returnedRole: IRoleModel = yield http.post(
        "/api/admin/roles",
        role
      );
      const newRole = new RoleModel().fromJSON(returnedRole);
      this.roles = [...this.roles, newRole];
      this.onSuccess();
    } catch (err) {
      this.onError(err);
      throw err;
    }
  })

  updateRole = flow(function* (
    this: RolesStore,
    roleId: number,
    role: RoleCreationAttrs
  ) {
    try {
      this.state = LoadTypes.LOADING;
      const http = injectHttpClient();
      const returnedRole: any = yield http.put(
        `/api/admin/roles/${roleId}`,
        role
      );
      this._deleteRoleById(returnedRole.id);
      this.roles.push(new RoleModel().fromJSON(returnedRole));
      this.onSuccess();
    } catch (err) {
      this.onError(err);
      throw err;
    }
  })

  deleteRole = flow(function* (
    this: RolesStore,
    roleId: number
  ) {
    try {
      this.state = LoadTypes.LOADING;
      const http = injectHttpClient();
      const result: { delete: boolean } = yield http.delete(
        `/api/admin/roles/${roleId}`
      );
      if (result?.delete)
        this._deleteRoleById(roleId);

      this.onSuccess();
    } catch (err) {
      this.onError(err);
      throw err;
    }
  })


  onSuccess() {
    this.toasts.show(
      this.lang.getWord('messages').success
    );
  }


  onError(err: unknown) {
    console.error(err);
    this.errors.push(err);
    this.state = LoadTypes.FAILED;
    if (err instanceof AxiosError)
      this.toasts.addError(err)

  }
}

export default RolesStore;
