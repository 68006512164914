import { makeAutoObservable } from "mobx";
import { IUser } from "./type";

export default class User implements IUser {
  id: IUser["id"] = "";
  login: IUser["login"] = "";
  email: IUser["email"] = "";
  roles: IUser["roles"] = [];
  name: IUser["name"] = "";
  fam: IUser["fam"] = "";
  otch: IUser["otch"] = "";
  birthday: IUser["birthday"] = '';
  position: IUser["position"] = "";
  banned: IUser["banned"] = false;

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(user: IUser) {
    this.id = user.id;
    this.login = user.login;
    this.name = user.name;
    this.fam = user.fam;
    this.otch = user.otch;
    this.position = user.position;
    this.banned = user.banned;
    this.roles = user.roles;
  }
}
