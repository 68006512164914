import React, { useEffect } from "react";
import { Alert, Button, Card, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import './PublishRobotsPage.css';
import DeleteRobotModal from "./modals/deleteRobotModal/deleteRobotModal";
import ShareRobotModal from "./modals/shareRobotModal/shareRobotModal";
import CenterSpinner from "../../components/Spinner/Spinner";
import VirtualScroll from "../../components/VirtualScroll/VirtualScroll";
import { Robot } from "../../store";
import PublishRobot from "./Robot/Robot";
import PublishModule from "./Robot/Module";
import WatchRobot from "./modals/WatchRobot";
import { useParams } from "react-router-dom";
import RefreshIcon from "../../icons/Refresh.png";
import useStrings from "../../hooks/useStrings";

function PublishRobotsPage() {
  const params = useParams<{
    robot_id: string
  }>();

  const store = useStore();
  const { robots: text } = useStrings(store);
  const { robots, infoRobots } = store;
  const {
    robotSearcher,
    moduleSearcher,
    currentRobot,
  } = robots;
  const { onlyModules, onlyRobots } = robots;
  const activeCount = robots.robots.filter((robot) =>
    robot.isActive
  ).length

  useEffect(() => {
    robots.refresh();
    if (params.robot_id)
      robots.watchRobot(params.robot_id)
  }, [])

  if (robots.isError) (
    <Button
      className="m-3"
      onClick={() => robots.refresh()}
    >
      {text.tryToLoad}
    </Button>
  )

  return (
    <Container fluid>
      {currentRobot &&
        <WatchRobot />
      }
      <DeleteRobotModal />
      <ShareRobotModal />

      <CenterSpinner
        isLoad={robots.isLoading}
      />
      <Row className="mt-3">
        <Col>
          <Form.Group as={Row}>
            <Form.Label sm={4} column>{text.activeNow}</Form.Label>
            <Col sm={4}>
              <Form.Control
                disabled
                value={activeCount + ' / ' + robots.robots.length}
              />
            </Col>

          </Form.Group>
        </Col>
        <Col className="col-form-label">
          {/* <NavLink to='/dashboard'>{text.watchStatistics}</NavLink> */}
        </Col>
        <Col sm={1} className="col-form-label">
          <img
            src={RefreshIcon}
            className='refresh_btn'
            onClick={() => robots.refresh()}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Body>
              <InputGroup>
                <InputGroup.Text>
                  {text.robots}
                </InputGroup.Text>
                <Form.Control
                  placeholder={text.seacrh}
                  type='text'
                  onChange={(e) =>
                    robotSearcher.search(e.currentTarget.value)
                  }
                />
              </InputGroup>
              {onlyRobots.length
                ?
                <div className='robotlist'>
                  <VirtualScroll
                    data={robotSearcher.isSearching
                      ? robotSearcher.result
                      : onlyRobots
                    }
                    rowHeight={57.97}
                    visibleRows={6}
                    render={(robot: Robot) =>
                      <PublishRobot
                        key={robot.id}
                        robot={robot}
                      />
                    }
                  />
                </div>
                : (
                  <Alert
                    variant="success"
                    className="mt-3 mb-0"
                  >
                    {text.notAvailableRobots}
                  </Alert>
                )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col sm={5}>
          <Card>
            <Card.Body>
              <InputGroup>
                <InputGroup.Text>
                  {text.modules}
                </InputGroup.Text>
                <Form.Control
                  placeholder="type search"
                  type='text'
                  onChange={(e) =>
                    moduleSearcher.search(e.currentTarget.value)
                  }
                />
              </InputGroup>
              {onlyModules.length
                ?
                <div className='robotlist'>
                  <VirtualScroll
                    data={moduleSearcher.isSearching
                      ? moduleSearcher.result
                      : onlyModules
                    }
                    rowHeight={57.97}
                    visibleRows={6}
                    render={(robot: Robot) =>
                      <PublishModule
                        key={robot.id}
                        robot={robot}
                      />
                    }
                  />
                </div>
                : (
                  <Alert
                    variant="success"
                    className="mt-3 mb-0"
                  >
                    {text.notAvailableModules}
                  </Alert>
                )
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(PublishRobotsPage);
