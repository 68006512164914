import { Container } from "@owja/ioc";
import { diConstants } from "../../../di/di.constants";
import HttpLogsStore from "../http-logs-store";
import { HttpLog } from "../log";

export function setupHttpLogsStoreDI(container: Container): Container {
    container.bind<HttpLog>(diConstants.LOG.HTTP_LOG).to(HttpLog);
    container
        .bind<HttpLogsStore>(diConstants.LOG.HTTP_LOGS_STORE)
        .to(HttpLogsStore)
        .inSingletonScope();

    return container;
}
