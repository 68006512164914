import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import {
  CellContext,
  flexRender,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  HeaderContext,
  useReactTable,
} from "@tanstack/react-table";
import { useHistory, useParams } from "react-router-dom";
import { ILog, LogsStore } from "../../../store";
import { autorun } from "mobx";
import moment from "moment";
import useStrings from "../../../hooks/useStrings";
import { useStore } from "../../../providers/StoreContext";
import LogsTablePagination from "../components/LogsPagination";

const INTL_MAP = {
  id: "id",
  pid: "pid",
  timestamp: "timestamp",
  level: "level",
  class: "class",
  handler: "handler",
  stack: "stack",
  message: "message",
  username: "user",
  requestUuid: "requestUuid",
} as const;

function TableHeaderCell(props: HeaderContext<ILog, any>) {
  const store = useStore();
  const { general } = useStrings(store);
  const id = props.column.id as keyof typeof INTL_MAP;
  const INTL_KEY = INTL_MAP[id];
  return <span>{general[INTL_KEY]}</span>;
}

function TableCell(props: CellContext<ILog, any>) {
  return props.getValue()
    ? props.getValue()
    : 'null';
}

// function truncate(str: string) {
//   return str.length > 30 ? str.substring(0, 27) + "..." : str;
// }

const prettyDate = (props: any) => {
  return moment(new Date(parseInt(props.getValue(), 10)).toISOString()).format("YYYY-MM-DD HH:mm:ss")
}

const columnHelper = createColumnHelper<ILog>();
const defaultColumns: ColumnDef<ILog>[] = [
  columnHelper.accessor("id", {
    id: "id",
    header: "#",
    cell: TableCell,
  }),
  columnHelper.accessor("pid", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("timestamp", {
    header: TableHeaderCell,
    cell: prettyDate,
  }),
  columnHelper.accessor("level", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("message", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("username", {
    header: TableHeaderCell,
    cell: TableCell,
  })
];


const SystemLogs = () => {
  const store = useStore();
  const history = useHistory();
  const logsStore = store.logs;

  const params = useParams<{ page: string }>();

  // Синхронизируем URL-параметр страницы с mobx-хранилищем
  useEffect(() => {
    const page = parseInt(params.page, 10);
    if (Number.isNaN(page)) {
      return;
    }
    if (page !== logsStore.currentPage) {
      logsStore.setPage(page);
    }
    if (page > logsStore.steps && logsStore.steps > 0) {
      history.replace(`/logs/${logsStore.steps}`);
    }
    if (page < 1) {
      history.replace(`/logs/1`);
    }
  }, [params.page, logsStore.currentPage, logsStore.steps]);

  // Загружаем логи за нужный шаг
  useEffect(() => {
    //const abortController = new AbortController();
    let promise: ReturnType<LogsStore["getAllLogs"]>;
    const dispose = autorun(() => {
      promise = logsStore.getAllLogs(
        logsStore.currentPage,
        //abortController.signal
      );
    });

    return () => {
      dispose();
      //abortController.abort();
      promise?.cancel();
    };
  }, [logsStore.currentPage]);

  const table = useReactTable<ILog>({
    data: logsStore.pageLogs,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <span>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <LogsTablePagination
        store={logsStore}
      />
    </>
  )
}
export default observer(SystemLogs);