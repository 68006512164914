import { makeAutoObservable } from "mobx";

export const SupportedLanguages = {
  ru: "ru",
  en: "en",
} as const;
export type SupportedLanguagesType =
  typeof SupportedLanguages[keyof typeof SupportedLanguages];

export const SupportedLanguagesNames = {
  en: "English",
  ru: "Русский",
} as const;
export type SupportedLanguagesNameType =
  typeof SupportedLanguagesNames[keyof typeof SupportedLanguagesNames];

export interface ILang {
  code: SupportedLanguagesType;
  name: SupportedLanguagesNameType;
}

export default class Lang implements ILang {
  name: SupportedLanguagesNameType;
  code: SupportedLanguagesType;

  constructor(name: SupportedLanguagesNameType, code: SupportedLanguagesType) {
    this.name = name;
    this.code = code;
    makeAutoObservable(this);
  }
}

export const SUPPORTED_LANGUAGES = [
  new Lang("English", "en"),
  new Lang("Русский", "ru"),
];
