import { Localization } from "./en";

const ru: Localization = {
  appNavBar: {
    dashboard: "Дашборд",
    robots: "Роботы",
    clients: "Клиенты",
    docs: "Документация",
    publicRobots: "Опубликованные роботы",
    activeRobots: "Активные роботы",
    logs: "Журнал логирования",
    lk: "Личный кабинет",
    admin: "Админ панель",
    groups: "Группы",
    exit: "Выйти",
    notifications: "Уведомления",
    tasks: 'Задачи'
  },
  notice: {
    notifications: "Уведомления",
    noNotifications: "У вас пока нет уведомлений...",
  },
  globalVariable: {
    nameVar: "Название переменной",
    valueVar: "Значение переменной",
  },
  settingPage: {
    info: "Здесь будут какие-то обшие настройки",
    generalSettings: "Общие настройки",
    globalVariable: "Глобальные переменные",
  },
  personalAccount: {
    generalInfo: "Общая информация",
    rights: "Права",
    editingRights: "Редактирование прав",
    sendingNotifications: "Отправка уведомлений",
    licenseManagement: "Управление лицензиями",
    demoLicenseManagement: "Управление демо-лицензиями",
    licenseKey: "Лицензионный ключ",
    allowEditing: "Разрешить редактирование",
    uploadRights: "Загрузить права",
    beginningOfTheLicense: "Начало действия лицензии",
    licenseExpiration: "Окончание действия лицензии",
    upgradeLicense: "Обновить лицензию",
    customerKey: "Ключ для клиентов",
    numberOfPublicationsAvailable: "Количество доступных публикаций",
    updateStudio: "Обновление Lexema RPA Studio",
  },
  clients: {
    noRegisteredCustomersYet: "Пока нет зарегистрированных клиентов",
    client: "Клиент",
    activeNow: "Активны сейчас",
    startCount: "Кол-во запусков",
    date: "Дата"
  },
  robots: {
    robot: "Робот",
    descriptionOfTheRobot: "Описание робота",
    info: "Краткое описание робота или какая-либо информация",
    info2: "Более подробная информация о роботе",
    currentProgress: "Текущий прогресс",
    clicker: "Есть кликер",
    machineName: "Имя машины",
    completedSuccessfully: "Завершен успешно",
    additionalValuesAndErrors: "Дополнительные значения и ошибки",
    robotExecutionProcess: "Процесс выполнения робота",
    completedWithAnError: "Завершен с ошибкой",
    activeNow: "Активны сейчас",
    successfullyEnded: "Завершено успешно",
    watchStatistics: "Смотреть статистику",
    robots: "Роботы",
    modules: "Модули",
    seacrh: "Искать",
    notAvailableRobots: "Доступных роботв нет",
    notAvailableModules: "Доступных модулей нет",
    tryToLoad: "Попробовать загрузить",
    working: "Работает...",
    resting: "Отдыхает",
    common: "Общие",
    deleteRobot: "Удалить робота",
    activity: 'Активность',
    runningNow: "Выполняется сейчас",
    completed: "Завершено",
    downloading: "Скачивание",
    editing: "Изменение",
    publication: "Публикация",
    deleting: "Удаление",
    dateOfIssue: "Дата выдачи",
    accessHasNotBeenGrantedToAnyoneYet: "Доступ еще никому не выдан",
    doYouWantDeleteAccess: "Вы действительно хотите удалить доступ?",
    grantAccess: "Дать доступ",
    loadError: "Произошло что-то плохое и робота не удалось загрузить((",
    access: "Доступ",
    pass_owner: 'Сменить владельца',
    newOwner: 'Новый хозяин',
    choseUserOrGroup: 'Выберите пользователя или группу',
    seacrByLoginOrGroupName: "Искать по логину или имени группы",
    its_your_robot: 'Это ваш робот. Вы имеете право на все действия с роботом',
    its_your_group_robot: 'Вы админ группы, которой принадлежит этот робот. Вы имеете право на все действия с роботом',
    you_have_access: 'Вам выдали разрешения на определенные действия с этим роботом',
    your_group_have_access: 'Группе, в которой вы состоите, выдали разрешения на определенные действия с этим роботом',
    deletedTask: 'Задача удалена',
    deletedClient: 'Клиент удален'
  },
  forAlert: {
    informationSaved: "Информация сохранена!",
    failedToSaveInformation: "Не удалось сохранить информацию!",
    welcome: "Добро пожаловать",
    licenseHasExpired: "Истек срок действия лицензии!",
    licenseHasExpiredServer:
      "Истек срок действия серверной лицензии оркестратора!",
    contactYourSystemAdministrator: "обратитесь к системному администратору!",
    invalidUsernamePassword: "Неверный логин/пароль",
    linkWasSuccessfullyCopiedToTheClipboard:
      "Ссылка успешно скопирована в буфер обмена!",
    failedToCopyLinkToClipboard:
      "Не удалось скопировать ссылку в буфер обмена!",
    licenseRenewedSuccessfully: "Лицензия успешно обновлена!",
    error: "Ошибка",
    licenseHasBeenSuccessfullyCreated: "Лицензия успешно создана!",
  },
  general: {
    search: "Поиск",
    version: "Версия",
    upload: "Загрузить",
    delete: "Удалить",
    notLimited: "Не ограничено",
    status: "Статус",
    currentDate: "Текущая дата",
    comment: "Комментарий",
    percent: "Процент",
    error: "Ошибка",
    value: "Значение",
    notFound: "Не найдено",
    download: "Скачать",
    owner: "Собственник",
    id: "Идентификатор",
    create: "Создать",
    save: "Сохранить",
    close: "Закрыть",
    add: "Добавить",
    actions: "Действия",
    surname: "Фамилия",
    name: "Имя",
    patronymic: "Отчество",
    birthday: "Дата рождения",
    position: "Должность",
    actsWith: "Действует с",
    by: "по",
    allow: "Разрешить",
    selectUser: "Выберите пользователя",
    whom: "Кому",
    caption: "Заголовок",
    message: "Сообщение",
    send: "Отправить",
    user: "Пользователь",
    startDate: "Дата начала",
    expirationDate: "Дата окончания",
    key: "Ключ",
    state: "Состояние",
    place: "Место",
    type: "Тип",
    level: "Уровень",
    date: "Дата",
    host: "Хост",
    entity: "Сущность",
    searching: "Выполняется поиск...",
    active: "Активные",
    inactive: "Неактивные",
    detailedInformation: "Подробная информация",
    link: "Ссылка",
    pid: "PID",
    timestamp: "Timestamp",
    class: "Класс",
    handler: "Обработчик",
    stack: "Стек",
    username: "Пользователь",
    requestUuid: "uuid запроса",
    uuid: "uuid",
    method: "Метод",
    url: "url",
    userAgent: "userAgent",
    mscs: "мсек",
    login: "логин",
    content_length: "Размер контента",
    invalidFormat: 'Недопустимый формат',
    description: 'Описание'
  },
  graph: {
    notEnoughDataToBuildAGraph: "Недостаточно данных для построения графика",
  },
  messages: {
    success: "Успешно",
    error: "Ошибка",
    informationSaved: 'Информация сохранена',
    informationHasNotSaved: 'Не удалось сохранить информацию'
  },
  tasks: {
    addTask: "Добавить задачу",
    taskName: "Наименование задачи",
    robot: "Робот",
    selectRobot: "Выберите робота",
    selectClient: "Выберите клиента",
    firstStart: "Первый запуск",
    repeats: "Повторы",
    starts: "Выполнения",
    startInterval: "Интервал запуска",
    status: "Статус",
    rpaClient: "RPA Клиент",
    year: "Год",
    month: "Месяц",
    day: "День",
    hour: "Час",
    minute: "Минута",
    second: "Секунда"
  },
  validationAlerts: {}
};

export default ru;
