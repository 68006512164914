import { makeAutoObservable, flow } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { injectWebSocketClient } from "../../features/websocket/inject-ws-client";
import WsClient, { availableMessageType, SetClientState, WsEvents } from "../../features/websocket/ws-client";
import { rootStore } from "../../providers/StoreContext";
import Client, { IClient } from "./client";


export default class ClientsStore {
  clients: IClient[] = [];
  ws: WsClient = injectWebSocketClient()

  constructor() {
    makeAutoObservable(this);
    this.subscribeWsMessages()
  }

  getClientById(id: string) {
    return this.clients.find((client) =>
      client.id == id
    )
  }

  getClientName(id: string) {
    return this.clients.find((client) =>
      client.id == id
    )?.name
  }

  updateStateClient(
    id: string,
    state: boolean
  ) {
    const targetClient = this.clients
      .find((client) => client.id == id);

    if (!targetClient) return;
    targetClient.state = state;
    this.clients = [
      ...this.clients.filter((client) => client.id !== id),
      targetClient
    ]
  }

  setClientState(id: string, state: boolean) {
    const message: availableMessageType = {
      event: WsEvents.SET_CLIENT_STATE,
      data: {
        id,
        // eslint-disable-next-line
        // @ts-ignore
        state
      }
    }
    this.ws.sendMessage(message)
  }

  subscribeWsMessages() {
    this.ws.subscribeOnMessage((ev: MessageEvent<any>) => {
      const message = JSON.parse(ev.data) as availableMessageType;
      switch (message.event) {
        case 'SET_CLIENT_STATE': {
          const msg = message as SetClientState;
          const { id, state } = msg.data;
          const client = this.getClientById(id)
          if (client) {
            rootStore.toasts.show(
              "Client " +
              client.name +
              (state
                ? " connected"
                : " disconnected"
              )
            );
            this.updateStateClient(id, state);
          }
          break;
        }
      }
    })
  }

  getAllClients = flow(function* (this: ClientsStore) {
    try {
      const http = injectHttpClient();
      const clients: IClient[] = yield http.get("/api/clients/all");
      this.clients = [];

      if (clients != null && Array.isArray(clients)) {
        clients.forEach((client) => {
          this.clients.push(new Client().fromJSON(client));
        });
      }
    } catch (err) {
      console.log("Error catch: ", err);
    }
  });
}
