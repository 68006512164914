import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import Client from "./client";
import ClientsStore from "./clients-store";

export function setupClientsStoreDI(container: Container): Container {
  container.bind<Client>(diConstants.CLIENTS.CLIENT).to(Client);
  container
    .bind<ClientsStore>(diConstants.CLIENTS.CLIENT_STORE)
    .to(ClientsStore)
    .inSingletonScope();

  return container;
}
