import { makeAutoObservable } from "mobx";
import { UUID } from "../../types";
import { IRoleModel } from "../roles";

type License = {
  license: boolean;
  license_key: string;
  license_begin_date: string;
  license_end_date: string;
}

type User = {
  id: UUID;
  email: string;
  fam: string;
  login: string;
  name: string;
  otch: string;
  birthday: string;
  position: string;
  password_expires_in: string | null;
  banned: boolean;
  roles: IRoleModel[];
  user_lexema8: string | null;
}

export interface IAuthUser {
  isAuth: boolean,
  user: User;
  license: License;
  cookie: string;
  expireDate: string;
  numberOfPublications?: string;
}

class AuthUser implements IAuthUser {
  isAuth = false;
  user: User = {
    id: "",
    login: "",
    email: "",
    roles: [],
    fam: "",
    name: "",
    otch: '',
    birthday: '',
    position: '',
    password_expires_in: '',
    banned: false,
    user_lexema8: null,
  };
  license: License = {
    license: false,
    license_begin_date: "",
    license_end_date: "",
    license_key: "",
  }
  cookie = "";
  expireDate = "";
  numberOfPublications?: string = undefined;


  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IAuthUser): AuthUser {
    this.isAuth = json.isAuth;
    this.license = json.license;
    this.cookie = json.cookie;
    this.expireDate = json.expireDate;
    this.numberOfPublications = json.numberOfPublications;
    this.user = json.user;
    return this;
  }

  setValue<K extends keyof IAuthUser>(key: K, value: IAuthUser[K]) {
    (this as IAuthUser)[key] = value;
  }
}

export default AuthUser;
