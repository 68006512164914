import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import Lang from "./lang";
import LangStore from "./lang-store";

export function setupLangStoreDI(container: Container): Container {
  container.bind<Lang>(diConstants.LANG.LANG).to(Lang);

  container
    .bind<LangStore>(diConstants.LANG.LANG_STORE)
    .to(LangStore)
    .inSingletonScope();

  return container;
}
