import React from "react";
import { resolve } from "../di/di";
import { diConstants } from "../di/di.constants";
import RootStore from "../store/RootStore";

export const rootStore = resolve<RootStore>(diConstants.ROOT_STORE)();

export const StoreContext = React.createContext(rootStore);
StoreContext.displayName = "StoreContext";

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  return (
    <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
  );
};

export interface withRootStoreProps {
  rootStore: RootStore;
}

export const withRootStore = <P extends object>(
  Wrapped: React.ComponentType<P>
): React.FC<Omit<P, keyof withRootStoreProps>> => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <StoreContext.Consumer>
      {(store) => <Wrapped {...(props as P)} rootStore={store} />}
    </StoreContext.Consumer>
  );
};

export const useStore = () => React.useContext(StoreContext);
