import React from "react";
import useInput from "../../../../hooks/useInput";
import { useHistory } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import { CheckCodeProps } from "../CheckCode";
import { injectHttpClient } from "../../../../features/requests/inject-http-client";

const ChangePassword = (props: CheckCodeProps) => {
  const {
    location: {
      state: { login, email, code },
    },
  } = props;
  const pass1 = useInput();
  const pass2 = useInput();
  const history = useHistory();

  const validateForm = () =>
    pass1.value.length > 0 &&
    pass2.value.length > 0 &&
    pass1.value === pass2.value;

  const queryChangePassword = () => {
    const http = injectHttpClient();
    const data = {
      login,
      email,
      code,
      password: pass1.value,
    };

    return http.post("/api/auth/change_password", data);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const resChangePass = await queryChangePassword();
    alert("Пароль успешно восстановлен!");
    history.push("/login");
  };

  return (
    <div className="Login">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="pass1">
          <Form.Label>Новый пароль</Form.Label>
          <Form.Control type="password" {...pass1} />
        </Form.Group>
        <Form.Group controlId="pass2">
          <Form.Label>Новый пароль еще раз</Form.Label>
          <Form.Control type="password" {...pass2} />
        </Form.Group>
        <Row className="pt-1 pb-1 mt-2">
          <Col>
            <Button
              style={{ width: "inherit" }}
              size={"lg"}
              variant={"success"}
              type="submit"
              disabled={!validateForm()}
            >
              Сменить пароль
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ChangePassword;
