import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Button, Form, Dropdown } from "react-bootstrap";
import { observer } from "mobx-react-lite"
import { RobotOwner } from '../../../../store';
import { useStore } from '../../../../providers/StoreContext';
import GroupLogo from '../../../../icons/Group.png';
import UserLogo from '../../../../icons/User.png';
import VirtualScroll from '../../../../components/VirtualScroll/VirtualScroll';


const ShareRobotModal = observer(() => {
  const { robots } = useStore();
  const {
    shareModal,
    currentRobot,
    potentialOwners,
    potentialOwnersSearchResult
  } = robots;
  const [selectedAccessor, setselectedAccessor] = useState<RobotOwner | null>(null);
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const [create, setCreate] = useState(false);
  const [del, setDelete] = useState(false);



  const close = () => {
    setselectedAccessor(null);
    setRead(false);
    setWrite(false);
    setCreate(false);
    setDelete(false);
    shareModal.close();
  }

  const handleShare = () => {
    if (currentRobot?.id && selectedAccessor?.id) {
      robots.shareRobotHttp({
        accessor_id: selectedAccessor?.id,
        read,
        write,
        create,
        delete: del
      }, currentRobot?.id);
      close();
    }
  }
  return (
    <Modal
      show={shareModal.show}
      onHide={close}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Предоставить доступ
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <p>Пользователь или Группа</p>
          <Dropdown className="mt-2 mb-2">
            <Dropdown.Toggle size='sm' variant='outline-primary'>
              {selectedAccessor?.name || 'Выберите пользователя или группу'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              <Form.Control
                size="sm"
                placeholder="Искать по логину или имени группы"
                onChange={
                  (e) => robots.searchTerm = e.currentTarget.value
                }
                value={robots.searchTerm}
              />
              {!potentialOwners.length ?
                <div>Ничего не найдено</div>
                :
                <VirtualScroll
                  data={potentialOwnersSearchResult}
                  rowHeight={33}
                  visibleRows={10}
                  render={(a: RobotOwner) =>
                    <Dropdown.Item
                      onClick={() => setselectedAccessor(a)}
                      key={a.id}
                    >
                      <img
                        src={a.type === 'GROUP'
                          ? GroupLogo
                          : UserLogo
                        }
                        height={20}
                        style={{ marginRight: '5px' }}
                      />
                      {a.name}
                    </Dropdown.Item>
                  }
                />
              }
            </Dropdown.Menu>
          </Dropdown>

          <hr />
          <Form.Check
            inline
            type='checkbox'
            id='read'
            label='Чтение'
            checked={read}
            onChange={() => setRead(!read)}
          />
          <Form.Check
            inline
            type='checkbox'
            id='write'
            label='Запись'
            checked={write}
            onChange={() => setWrite(!write)}
          />
          <Form.Check
            inline
            type='checkbox'
            id='create'
            label='Публикация'
            checked={create}
            onChange={() => setCreate(!create)}
          />
          <Form.Check
            inline
            type='checkbox'
            id='delete'
            label='Удаление'
            checked={del}
            onChange={() => setDelete(!del)}
          />
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={() => close()}>Закрыть</Button>
        <Button
          disabled={
            !selectedAccessor
            || !(read || write || create || del)
          }
          variant="success"
          onClick={handleShare}
        >
          Отправить
        </Button>
      </Modal.Footer>
    </Modal>
  );
})

export default ShareRobotModal;
