import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import License from "./license";
import LicenseStore from "./license-store";

export function setupLicenseStoreDI(container: Container): Container {
  container.bind<License>(diConstants.LICENSE.LICENSE).to(License);
  container
    .bind<LicenseStore>(diConstants.LICENSE.LICENSE_STORE)
    .to(LicenseStore)
    .inSingletonScope();

  return container;
}
