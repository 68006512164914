import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import AppLicense from "./app-license";

export function setupAppLicense(container: Container): Container {
  container
    .bind<AppLicense>(diConstants.APP_LICENSE)
    .to(AppLicense)
    .inSingletonScope();

  return container;
}
