import { makeAutoObservable } from "mobx";

export interface IGlobalVariable {
  id: string | number;
  name: string;
  value: string;
}

export default class GlobalVariable implements IGlobalVariable {
  id: string | number = "";
  name = "";
  value = "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IGlobalVariable) {
    this.id = json.id;
    this.name = json.name;
    this.value = json.value;
    return this;
  }
}
