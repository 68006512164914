import React from 'react'
import { Badge, ListGroup } from 'react-bootstrap'
import { useStore } from '../../../../../providers/StoreContext';
import { GroupRoles, IGroupUser } from '../../../../../store';
import CrossIcon from '../../../../../icons/Cross.png';

interface ListGroupUserProps {
  user: IGroupUser
  canManage: boolean
  deleteUser: () => void
}

export default function ListGroupUser({
  user,
  canManage,
  deleteUser
}: ListGroupUserProps) {
  const { users, auth } = useStore();
  const _user = users.getById(user.user_id);
  const isCurrentUser = auth.user.user.id == user.user_id;
  const userName = isCurrentUser
    ? "Вы"
    : _user?.login ?? "Удаленный пользователь";

  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-center">
      <div className="ms-2 me-auto">
        <span>{userName}</span>
      </div>
      <>
        {user.role == GroupRoles.ADMIN
          ? (
            <Badge bg="primary" pill>
              Администратор
            </Badge>
          )
          : null}
        {canManage && !isCurrentUser
          ?
          <img
            src={CrossIcon}
            className='crooss_icon'
            onClick={deleteUser}
            alt='Удалить'
          />
          : null
        }
      </>
    </ListGroup.Item>
  )
}
