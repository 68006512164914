import React from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { useStore } from '../../../../providers/StoreContext';
import { observer } from 'mobx-react-lite';


const DeleteRobotModal = () => {
  const { robots } = useStore();
  const { currentRobot, deleteModal } = robots;

  const deleteRobot = () => {
    if (currentRobot?.id) {
      robots.deleteRobot(currentRobot.id)
        .then(() =>
          deleteModal.close()
        )
    }
  }
  return (
    <Modal
      show={deleteModal.show}
      onHide={() => deleteModal.close()}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Подтвердите удаление
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Вы действительно хотите удалить этого робота из оркестратора?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-success" onClick={deleteModal.close}>Закрыть</Button>
        <Button variant="outline-danger" onClick={deleteRobot}>Удалить</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(DeleteRobotModal);
