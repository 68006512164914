/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AxiosError } from "axios";
import { makeAutoObservable, flow } from "mobx";
import qs from "qs";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { injectWebSocketClient } from "../../features/websocket/inject-ws-client";
import WsClient from "../../features/websocket/ws-client";
import { UUID } from "../../types";
import { ToastStore } from "../errors";
import { LangStore } from "../lang";
import { IRoleModel } from "../roles";
import AuthUser, { IAuthUser } from "./auth-user";

type RefreshTokenDto = { refresh_token: string };
type RefreshTokenResponse = {
  access_token: string;
  refresh_token: string;
  expireDate: string;
  warnings?: Warning[];
};

type Warning = {
  type: WarningTypes;
  daysLeft?: number;
  message?: string;
}

type WarningTypes = 'WAIT_PASSWORD_REFRESH'

interface SetAuthInfoAfterUpdateParam {
  login: string,
  email: string,
  password: string | null,
  fam: string,
  name: string,
  otch: string,
  birthday: string,
  position: string,
  license_key: string,
  license_begin_date: string,
  license_end_date: string,
}

interface UserInfoServerResponse {
  user: {
    id: UUID;
    email: string;
    fam: string;
    login: string;
    name: string;
    otch: string;
    birthday: string;
    position: string;
    password_expires_in: string;
    banned: boolean;
    roles: IRoleModel[];
    user_lexema8: string | null;
  };
  license: {
    license: boolean;
    license_key: string;
    license_begin_date: string;
    license_end_date: string;
  };
}

export const AuthStates = {
  CHECKING_AUTH: "CHECKING_AUTH",
  AUTHORIZED: "AUTHORIZED",
  AUTHORIZING: "AUTHORIZING",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
} as const;
export type AuthStateType = typeof AuthStates[keyof typeof AuthStates];

class AuthStore {
  state: AuthStateType = "CHECKING_AUTH";
  user: IAuthUser = new AuthUser();
  public readonly toasts!: ToastStore;
  public readonly lang!: LangStore;
  readonly ws!: WsClient;

  constructor() {
    makeAutoObservable(this, {
      toasts: false,
      lang: false,
      ws: false
    });

    wire(this, "ws", diConstants.WS);
    wire(this, "toasts", diConstants.TOASTS);
    wire(this, "lang", diConstants.LANG.LANG_STORE);

    this.afterCreate();
  }

  get isCheckingAuth() {
    return this.state === AuthStates.CHECKING_AUTH;
  }

  get isAuth() {
    return this.state === AuthStates.AUTHORIZED;
  }

  get isAuthorized() {
    return this.state === AuthStates.AUTHORIZED;
  }

  get isAuthorizing() {
    return this.state === AuthStates.AUTHORIZING;
  }

  setState(state: AuthStateType) {
    this.state = state;
  }

  private setRefreshToken(refreshToken: string): void {
    localStorage.setItem("refresh_token", refreshToken);
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem("refresh_token");
  }

  setAuthInfoAfterUpdate = flow(function* (
    this: AuthStore,
    userId: string,
    param: SetAuthInfoAfterUpdateParam
  ) {
    const http = injectHttpClient();
    const { toasts, lang } = this;
    try {
      const data = qs.stringify(param);
      yield http.put<typeof data, SetAuthInfoAfterUpdateParam>(
        "/api/users/" + userId,
        data
      )
        .then((res) => {
          if (res) {
            const { user, license } = this.user
            Object.assign(user, res)
            Object.assign(license, res)
            this.updateStateAuthUser();
            toasts.show(
              lang.getWord("forAlert").informationSaved
            )
          }
        });
    } catch (err) {
      toasts.addError(err);
    }
  });

  private updateStateAuthUser() {
    const updated = new AuthUser().fromJSON(this.user)
    this.user = new AuthUser();
    this.user = updated;
  }

  setAuthInfo = flow(function* setAuthInfo(
    this: AuthStore,
    login: string,
    password: string
  ) {
    const { toasts, lang } = this;
    const forAlert = lang.getWord("forAlert");
    const http = injectHttpClient();
    try {
      this.setState(AuthStates.AUTHORIZING);

      const body = qs.stringify({
        login,
        password,
      });

      interface ExternalLoginResponse {
        isAuth: boolean;
        user: {
          id: UUID;
          email: string;
          fam: string;
          login: string;
          name: string;
          otch: string;
          birthday: string;
          position: string;
          password_expires_in: string;
          banned: boolean;
          roles: IRoleModel[];
          user_lexema8: string | null;
        };
        license: {
          license: boolean;
          license_key: string;
          license_begin_date: string;
          license_end_date: string;
        };
        refresh_token: string;
        access_token: string;
        expireDate: string;
      }

      const data: ExternalLoginResponse = yield http.post<
        string,
        ExternalLoginResponse
      >(`/api/v2/auth/external_login`, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      if (!data.isAuth) {
        this.setState(AuthStates.NOT_AUTHORIZED);
        return;
      }
      const { user, license } = data;

      if (!license?.license) {
        toasts.addError(
          new Error(
            forAlert.licenseHasExpired + ' ' +
            user.name + ' ' +
            user.otch + ' ' +
            forAlert.contactYourSystemAdministrator
          )
        )
        return;
      }

      toasts.show(`${forAlert.welcome}, ${user.name} ${user.otch}!`)
      this.setState(AuthStates.AUTHORIZED);
      http.setAuthInterceptors({
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
      });
      this.ws.setWSAuthInterceptor(data.access_token);
      this.ws.login();

      this.setRefreshToken(data.refresh_token);

      this.user = new AuthUser().fromJSON({
        isAuth: data.isAuth,
        user: user,
        license: license,
        cookie: "",
        expireDate: ""
      });
    } catch (e) {
      const error = e as AxiosError<any>;
      if ("response" in error) {
        const status = error.response?.status;
        if (status === 403) {
          toasts.addError(
            new Error(
              `${forAlert.licenseHasExpiredServer} ${forAlert.contactYourSystemAdministrator}`
            )
          )
        } else {
          toasts.addError(
            new Error(
              `${forAlert.invalidUsernamePassword} (${error.response?.data.statusCode} ${error.response?.data.message})`
            )
          )
        }
      }
      this.state = AuthStates.NOT_AUTHORIZED;
      throw error;
    }
  });

  exitAuth(this: AuthStore) {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("info_about_user");
    this.state = AuthStates.NOT_AUTHORIZED;
    this.user = new AuthUser();
    injectWebSocketClient().logout()
  }

  loadUserInfo = flow(function* loadUserInfo(this: AuthStore) {
    try {
      const http = injectHttpClient();
      const { user, license }: UserInfoServerResponse =
        yield http.get<UserInfoServerResponse>("/api/v2/auth/");

      this.user = new AuthUser().fromJSON({
        isAuth: true,
        user: user,
        license: license,
        cookie: "",
        expireDate: ""
      });
    } catch (err) {
      console.error(err);
    }
  });

  afterCreate = flow(function* (
    this: AuthStore
  ) {
    const http = injectHttpClient();
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.setState(AuthStates.NOT_AUTHORIZED);
      return;
    }
    this.setState(AuthStates.CHECKING_AUTH);
    try {
      const response = yield http.post<RefreshTokenDto, RefreshTokenResponse>(
        "/api/auth/refresh",
        { refresh_token: refreshToken }
      );
      if (response?.access_token) {
        http.setAuthInterceptors({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        });
        this.ws.setWSAuthInterceptor(response.access_token);
        this.ws.login();
        this.setRefreshToken(response.refresh_token);
      }
      yield this.loadUserInfo();
      this.state = AuthStates.AUTHORIZED;
    } catch (err) {
      this.setState(AuthStates.NOT_AUTHORIZED);
    }
  })
}

export default AuthStore;
