import { makeAutoObservable } from "mobx";
import localization from "../../localization";
import { Localization } from "../../localization/en";
import { ILang, SupportedLanguagesType, SUPPORTED_LANGUAGES } from "./lang";

export default class LangStore {
  langs: ILang[] = SUPPORTED_LANGUAGES;
  currentLangCode: SupportedLanguagesType = "ru";

  constructor() {
    makeAutoObservable(this);
  }

  get currentLang(): ILang {
    const lang = this.langs.find((l) => l.code === this.currentLangCode)!;
    return lang;
  }

  get inactiveLanguages() {
    const currentLang = this.currentLangCode ?? "ru";
    return this.langs.filter((lang) => lang.code !== currentLang);
  }

  setCurrentLang(lang: ILang) {
    localStorage.setItem("language", lang.code);
    this.currentLangCode = lang.code;
  }

  getWord<K extends keyof Localization>(key: K): Localization[K] {
    const currentLang = (this.currentLangCode ??
      "ru") as SupportedLanguagesType;
    return localization[currentLang][key];
  }
}
