import { flow, makeAutoObservable, when } from "mobx";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { Optional } from "../../types";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { Searcher } from "../../utils/helpers";
import { ToastStore } from "../errors";
import { LangStore } from "../lang";
import Group, { CreateGroupParameter, IGroupJSON } from "./group";
import GroupBreadcrumbs from "./group-breadcrumbs";

class GroupsStore {
  groups: Group[] = [];
  pathNames: Array<string> = [];
  breadcrumbs: GroupBreadcrumbs;
  _currentGroup: Optional<Group> = null;
  state: LoadStatesType = LoadStates.INITIAL;

  watchGroup(groupId: Optional<Group['id']>) {
    when(
      () => this.state == LoadStates.COMPLETED,
      async () => {
        if (!groupId) {
          this.toTopLevel();
        }
        const group = this.groups.find((group) =>
          group.id == groupId
        )
        if (!group) {
          this.toasts.addError(
            new Error('Такой группы не существует или она вам не доступна')
          );
          return;
        }
        this.onBegin();
        await group.loadGroupUsers();
        await group.loadGroupRobots();
        this.pushToPaths(group);
        this.onSuccess();
      }
    )
  }

  pushToPaths(group: Group) {
    // this.breadcrumbs.setPath([
    //   ...this.breadcrumbs.paths,
    //   `${group.id}`,
    // ]);
    // this.setPathNames([
    //   ...this.pathNames,
    //   this.getName(group)]
    // );
    // this._currentGroup = group;


    const groupTree = [group];
    const getParent = (group: Group) => {
      const isExists = this.groups.find((g) =>
        g.id == group.parent
      )
      if (isExists) {
        groupTree.unshift(isExists);
        getParent(isExists);
      }
    }
    getParent(group);
    this.breadcrumbs.setPath(
      groupTree.map(
        (group) => group.id
      )
    );
    this.setPathNames(
      groupTree.map((group) =>
        this.getName(group)
      )
    );
  }
  toTopLevel() {
    this.breadcrumbs.setPath([]);
    this.setPathNames([]);
    this._currentGroup = null;
  }

  groupSearcher = new Searcher(() =>
    this.groups
  );

  public readonly toasts!: ToastStore;
  public readonly lang!: LangStore;

  setPathNames(pathNames: Array<string>) {
    this.pathNames = pathNames;
  }

  get currentGroup(): Optional<Group> {
    const path = this.breadcrumbs.paths
    return this.groups.find((group) =>
      group.id == (
        path.length > 0
          ? path[path.length - 1]
          : null
      )
    ) || null
  }

  get isLoading() {
    return this.state === LoadStates.LOADING;
  }

  get isError() {
    return this.state === LoadStates.FAILED;
  }

  refresh() {
    this.loadAll();
  }

  constructor() {
    this.breadcrumbs = new GroupBreadcrumbs(this);
    makeAutoObservable(this, {
      toasts: false,
      lang: false
    });
    wire(this, "toasts", diConstants.TOASTS);
    wire(this, "lang", diConstants.LANG.LANG_STORE);
    this.loadAll = this.loadAll.bind(this);
    this.getName = this.getName.bind(this);
    this.watchGroup = this.watchGroup.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.createGroup = this.createGroup.bind(this);
    this.getNameById = this.getNameById.bind(this);
    this.setPathNames = this.setPathNames.bind(this);
  }

  getNameById(id: string) {
    return this.groups.find((group) =>
      id == group.id
    )?.name
  }

  getName(group: Group) {
    const lang = this.lang.currentLangCode;
    return lang === "ru"
      ? group.name
      : group.name_en;
  }

  loadAll = flow(function* (this: GroupsStore) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const data: IGroupJSON[] = yield http.get<IGroupJSON[]>(
        "/api/groups/"
      );
      this.groups = [];

      data.forEach((group) => {
        this.groups.push(new Group().fromJSON(group));
      });
      this.state = LoadStates.COMPLETED;
    } catch (err) {
      this.onError(err);
    }
  });


  createGroup = flow(function* (
    this: GroupsStore,
    arg: CreateGroupParameter
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const response: IGroupJSON = yield http.post(
        "/api/groups/",
        arg
      );
      if (response) {
        this.groups.push(
          new Group().fromJSON(response)
        );
        this.onSuccess('Группа успешно создана') // todo lang
      }
    } catch (err) {
      this.onError(err);
    }
  });

  onBegin() {
    this.state = LoadStates.LOADING;
  }

  deleteGroup = flow(function* (
    this: GroupsStore,
    groupId: string
  ) {
    try {
      this.onBegin();
      const group = this.groups.find((group) =>
        group.id = groupId
      )
      if (!group)
        throw new Error('Группа не найдена!') // todo lang

      yield group.destroy();
      this.groups = [...this.groups.filter((group) =>
        group.id != groupId
      )]

      this.loadAll();
      this.toTopLevel();
      this.onSuccess('Группа успешно удалена'); // todo lang
    } catch (err) {
      this.onError(err)
    }
  })

  onSuccess(text?: string) {
    this.state = LoadStates.COMPLETED;
    if (text?.length) {
      this.toasts.show(text);
    }
  }

  onError(err: unknown) {
    console.error(err);
    this.state = LoadStates.FAILED;
    this.toasts.addError(err);
  }
}

export default GroupsStore;
