import React, { useCallback, useMemo, useState } from "react";
import { Accordion, Alert, Button, Card, CloseButton, Col, Form, Modal, ProgressBar, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap";
import './watchRobotModal/watchRobotModal.css';
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Error from '../../../icons/Error.png'
import Done from '../../../icons/Done.png'
import Pause from '../../../icons/Pause.png'
// import Start from '../../../icons/Start.png' // todo
import Stop from '../../../icons/Stop.png'
import CrossIcon from '../../../icons/Close.png'
import './EbalModal.css'
import { useStore } from "../../../providers/StoreContext";
import { getOwnerById } from "../helpers";
import ChangeOwner from "./watchRobotModal/components/changeOwner";
import { IInfoRobot, IInfoRobotDetail, MY_GROUP_ROBOT, MY_ROBOT, Robot, ROBOT_ACCESSED_TO_GROUP_WITH_ME, ROBOT_ACCESSED_TO_ME } from "../../../store";
import { strUndef } from "../../../utils/helpers";
import CenterSpinner from "../../../components/Spinner/Spinner";
import ConfirmDialog from "../../../components/Confirm/Confirm";
import ShareRobotModal from "./shareRobotModal/shareRobotModal";
import moment from "moment";
import { injectHttpClient } from "../../../features/requests/inject-http-client";
import useStrings from "../../../hooks/useStrings";
import VirtualScroll from "../../../components/VirtualScroll/VirtualScroll";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


function WatchRobotModal() {
  const store = useStore();
  const {
    general,
    robots: text
  } = useStrings(store);
  const history = useHistory();
  const {
    isLoading,
    watchModal,
    deleteAccessModal,
    currentRobotAccesses,
    canManageCurrentRobot,
    deleteAccessHttp,
    deleteAccess,
    shareRobot,
  } = store.robots;
  const { infoRobots } = store;
  const currentRobot = store.robots.currentRobot as Robot;

  const [reload, setReload] = React.useState(0)
  const robotOwner = useMemo(
    () => getOwnerById(
      store,
      currentRobot?.robot_owner as string
    ),
    [store.users.list, currentRobot]
  );


  const close = () => {
    if (history.location.pathname == ('/robots/' + currentRobot.id)) {
      history.push('/robots');
    }
    store.robots.closeWatchRobot()
  }
  const currentRobotInfo = useMemo(() => {
    switch (currentRobot?.ownership_type) {
      case MY_ROBOT:
        return text.its_your_robot;

      case MY_GROUP_ROBOT:
        return text.its_your_group_robot;

      case ROBOT_ACCESSED_TO_ME:
        return text.you_have_access;

      case ROBOT_ACCESSED_TO_GROUP_WITH_ME:
        return text.your_group_have_access;
      default: return null
    }
  }, [store.lang.currentLangCode, currentRobot])

  // watching info_robot item
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState("");

  const [
    currentInfoRobot,
    setCurrentInfoRobot
  ] = useState<IInfoRobot | null>(
    null
  );

  const onToggleModal = (title: string, infoRobots: IInfoRobot) => {
    setTitle(title);
    setCurrentInfoRobot(infoRobots);
    setShowModal(!showModal);
  };

  const sendCommand = useCallback(async (command: string) => {
    try {
      const http = injectHttpClient();
      const data = {
        id_client: currentInfoRobot?.id_client,
        id_task: currentInfoRobot?.id_task,
      };
      const response = await http.post(`/api/command/${command}`, data);
    } catch (e) {
      console.log("sendCommand", e);
    }
  }, [currentInfoRobot]);

  return (
    <Modal
      show={watchModal.show}
      onHide={close}
      size="lg"
    >
      <Modal
        size="lg"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <ActiveRobotsPageBodyComponent
            sendCommand={sendCommand}
            currentInfoRobot={currentInfoRobot}
          />
        </Modal.Body>
      </Modal>

      {currentRobot
        ?
        <Modal.Body>
          <Row className="mb-3 justify-content-space-between">
            <Col>
              <span>
                {currentRobot?.robotname + ' - '}
              </span>
              {currentRobot.isActive
                ? (
                  <span style={{ color: 'green', fontWeight: 'inherit' }}>
                    {text.working}
                  </span>
                ) : (
                  <span>
                    {text.resting}
                  </span>
                )}
            </Col>
            <CloseButton
              onClick={close}
              style={{ marginRight: '1rem' }}
            />
          </Row>

          <Tabs>
            <Tab
              eventKey="common_info"
              title={text.common}
              className="mt-3"
            >
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                >
                  <Form.Label column sm="4">
                    {general.id}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      disabled={true}
                      value={strUndef(currentRobot.id)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                >
                  <Form.Label column sm="4">
                    {general.name}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      disabled={true}
                      value={strUndef(currentRobot.robotname)}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                >
                  <Form.Label column sm="4">
                    {general.owner}
                  </Form.Label>
                  <Col sm="8">
                    <Form.Control
                      disabled={true}
                      value={robotOwner || currentRobot.robot_owner}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                >
                  <Form.Label column sm="4">
                    {general.description}
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as={'textarea'}
                      rows={2}
                      disabled={true}
                      value={strUndef(currentRobot.description)}
                    />
                  </Col>
                </Form.Group>
                {currentRobotInfo &&
                  <Alert>
                    {currentRobotInfo}
                  </Alert>
                }
              </Form>

              {canManageCurrentRobot
                ?
                <ChangeOwner />
                : null
              }
              {currentRobot.delete
                ?
                <Button
                  variant="danger"
                  className="mt-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    store.robots.confirmDeleteRobot(currentRobot)
                  }}
                >
                  {text.deleteRobot}
                </Button>
                : null
              }
            </Tab>
            <Tab
              eventKey="activity"
              title={text.activity}
              className="mt-3 mb-3"
            >
              {infoRobots.active.length
                ? (
                  <Card>
                    <Card.Header>
                      {text.runningNow + ' (' + infoRobots.active.length + ')'}
                    </Card.Header>
                    <div className="active_list">
                      <VirtualScroll
                        data={infoRobots.active}
                        visibleRows={3}
                        rowHeight={72.63}
                        render={(info) => (
                          <StartHistoryItem
                            onToggleModal={onToggleModal}
                            key={info.id}
                            infoRobots={info}
                          />
                        )}
                      />
                    </div>
                  </Card>
                ) : null
              }
              <Card className="mt-3">
                <Card.Header>
                  {text.completed + ' (' + infoRobots.completed.length + ')'}
                </Card.Header>
                <div className="active_list">
                  <VirtualScroll
                    data={infoRobots.completed}
                    visibleRows={3}
                    rowHeight={72.63}
                    render={(info) => (
                      <StartHistoryItem
                        onToggleModal={onToggleModal}
                        key={info.id}
                        infoRobots={info}
                      />
                    )}
                  />
                </div>
              </Card>
            </Tab>
            {canManageCurrentRobot
              ? (
                <Tab
                  eventKey="access"
                  title={text.access}
                  className="mt-3 mb-3"
                >
                  <div className='accessList'>
                    <div className="accessList__header">
                      <div className="accessList__read">{text.downloading}</div>
                      <div className="accessList__write">{text.editing}</div>
                      <div className="accessList__create">{text.publication}</div>
                      <div className="accessList__delete">{text.deleting}</div>
                      <div className="accessList__date">{text.dateOfIssue}</div>
                    </div>
                    {!currentRobotAccesses.length &&
                      <Alert className="mt-3">
                        {text.accessHasNotBeenGrantedToAnyoneYet}
                      </Alert>
                    }
                    {currentRobotAccesses.map((access) =>
                      <div className="access__item" key={access.id}>
                        <div className="access__user">
                          {access.name}
                        </div>
                        <div className="access__read">
                          <input
                            type="checkbox"
                            checked={access.read}
                            disabled={true}
                          />
                        </div>
                        <div className="access__write">
                          <input
                            type="checkbox"
                            checked={access.write}
                            disabled={true}
                          />
                        </div>
                        <div className="access__create">
                          <input
                            type="checkbox"
                            checked={access.create}
                            disabled={true}
                          />
                        </div>
                        <div className="access__delete">
                          <input
                            type="checkbox"
                            checked={access.delete}
                            disabled={true}
                          />
                        </div>
                        <div className="access__date">
                          {access.createdAt.substring(0, 10)}
                        </div>
                        <div className="access__remove">
                          <img
                            src={CrossIcon}
                            onClick={() => deleteAccess(access)}
                            alt={text.deleting}
                          />
                          <ConfirmDialog
                            show={deleteAccessModal.show}
                            onClose={() => deleteAccessModal.close()}
                            question={text.doYouWantDeleteAccess}
                            onConfirm={() =>
                              deleteAccessHttp()
                                .then(() => deleteAccessModal.close())
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <ShareRobotModal />
                  <Button
                    variant='success'
                    onClick={() => shareRobot(currentRobot)}
                  >
                    {text.grantAccess}
                  </Button>
                </Tab>
              )
              : null
            }
          </Tabs>
        </Modal.Body>
        : isLoading
          ? <CenterSpinner isLoad={isLoading} />
          : <Modal.Body>
            <Alert variant="danger">
              {text.loadError}
            </Alert>
          </Modal.Body>
      }
    </Modal>
  )
}

export default observer(WatchRobotModal);


const StartHistoryItem = observer(function ({
  infoRobots,
  onToggleModal
}: {
  infoRobots: IInfoRobot,
  onToggleModal: (title: string, infoRobots: IInfoRobot) => void;
}) {
  const store = useStore();
  const {
    robots: text
  } = useStrings(store);
  const {
    clients,
    tasks
  } = store;

  const current_percent = useMemo(() => {
    if (infoRobots.detail.length === 0) return 0;
    return infoRobots.detail.reduce((acc, curr) =>
      acc.percent > curr.percent ? acc : curr
    )?.percent;
  }, [infoRobots.detail]);

  const task = tasks._getTaskById(infoRobots.id_task);
  const client = clients.getClientById(infoRobots.id_client);

  const isNotFinished = !infoRobots.status;

  const isFailed = !infoRobots.isActive && isNotFinished;

  return (
    <div className="active_item"
      onClick={() => onToggleModal(
        moment(infoRobots.begin_date).format("YYYY-MM-DD HH:mm:ss"),
        infoRobots
      )}
    >
      <div className="work__status">
        {infoRobots.isActive && (
          <Spinner
            variant="success"
            animation="grow"
            size="sm"
          />
        )}
        {!isNotFinished && (
          <img
            src={Done}
          />
        )}
        {isFailed && (
          <img
            src={Error}
          />
        )}
      </div>
      <div className="work__name">
        {client?.name || text.deletedClient}
      </div>
      <div className="work__taskname">
        {task?.taskName || text.deletedTask}
      </div>
      <div className="work__begin_date">
        {moment(infoRobots.begin_date).format("YYYY-MM-DD HH:mm:ss")}
      </div>
      <div className="work__progress">
        <ProgressBar
          animated={isNotFinished}
          label={`${current_percent}%`}
          now={current_percent}
          variant={isNotFinished
            ? "info"
            : (isFailed
              ? "danger"
              : "success"
            )
          }
        />
      </div>
      <div className="work__action_1">
        {infoRobots.isActive && (
          <img
            src={Pause}
          />
        )}
      </div>
      <div className="work__action_2">
        {infoRobots.isActive ? (
          <img
            src={Stop}
          />
        ) : (
          <img
            src={CrossIcon}
          />
        )
        }
      </div>
    </div>
  )
});


interface ActiveRobotsPageBodyComponentProps {
  sendCommand(command: "unpause" | "pause" | "stop"): void;
  currentInfoRobot: IInfoRobot | null;
}

export const ActiveRobotsPageBodyComponent = observer(function ({
  sendCommand,
  currentInfoRobot,
}: ActiveRobotsPageBodyComponentProps) {
  const store = useStore();
  const clients = store.clients.clients;
  const { general, robots } = useStrings(store);
  return (
    <Form>
      <Row className="mb-3 justify-content-md-end">
        <Col sm="3">
          <Form.Check
            type="checkbox"
            id="noclicker"
            label={robots.completedSuccessfully}
            disabled={true}
            defaultChecked={currentInfoRobot?.status ?? false}
          />
        </Col>
      </Row>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextId">
        <Form.Label column sm="4">
          {general.id}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            disabled={true}
            value={currentInfoRobot?.id}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextname">
        <Form.Label column sm="4">
          {robots.machineName}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            disabled={true}
            value={
              clients.find((c) => c.id === currentInfoRobot?.id_client)?.name
            }
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="dateRobot">
        <Form.Label column sm="4">
          {general.startDate}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="datetime"
            disabled={true}
            value={moment(currentInfoRobot?.begin_date).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="dateRobot">
        <Form.Label column sm="4">
          {general.expirationDate}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="datetime"
            disabled={true}
            value={moment(currentInfoRobot?.end_date).format(
              "YYYY-MM-DD HH:mm:ss"
            )}
          />
        </Col>
      </Form.Group>
      <Accordion>
        <Accordion.Item eventKey="valueAndError">
          <Accordion.Header>
            {robots.additionalValuesAndErrors}
          </Accordion.Header>
          <Accordion.Body>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextValue1"
            >
              <Form.Label column sm="4">
                {`${general.value} 1`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.value1 ?? ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextValue2"
            >
              <Form.Label column sm="4">
                {`${general.value} 2`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.value2 ?? ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextValue3"
            >
              <Form.Label column sm="4">
                {`${general.value} 3`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.value3 ?? ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextValue4"
            >
              <Form.Label column sm="4">
                {`${general.value} 4`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.value4 ?? ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextValue5"
            >
              <Form.Label column sm="4">
                {`${general.value} 5`}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.value5 ?? ""}
                />
              </Col>
            </Form.Group>
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formPlaintextError"
            >
              <Form.Label column sm="4">
                {general.error}
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  type="text"
                  disabled={true}
                  value={currentInfoRobot?.error ?? ""}
                />
              </Col>
            </Form.Group>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="tableWithStates">
          <Accordion.Header>{robots.robotExecutionProcess}</Accordion.Header>
          <Accordion.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{general.state}</th>
                  <th>{general.percent}</th>
                  <th>{general.comment}</th>
                  <th>{general.currentDate}</th>
                  <th>{general.status}</th>
                </tr>
              </thead>
              <tbody>
                {currentInfoRobot?.detail.map(
                  (ird: IInfoRobotDetail, index: number) => {
                    return (
                      <tr key={ird.id}>
                        <td>{index + 1}</td>
                        <td>{ird.state}</td>
                        <td>{ird.percent}</td>
                        <td>{ird.comment}</td>
                        <td>
                          {moment(ird.current_date).format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td>
                          {ird.status ? (
                            <CheckIcon fontSize={"large"} />
                          ) : (
                            <ErrorOutlineIcon fontSize={"large"} />
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Form>
  );
})