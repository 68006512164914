import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import User from "./user";
import UsersStore from "./users-store";

export function setupUsersStoreDI(container: Container): Container {
  container.bind<User>(diConstants.USERS.USER).to(User);
  container
    .bind<UsersStore>(diConstants.USERS.USERS_STORE)
    .to(UsersStore)
    .inSingletonScope();

  return container;
}
