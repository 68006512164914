import React, { useEffect } from "react";
import { Table } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import {
  CellContext,
  flexRender,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  HeaderContext,
  useReactTable,
} from "@tanstack/react-table";
import { useHistory, useParams } from "react-router-dom";
import { IHttpLog } from "../../../store";
import { autorun } from "mobx";
import moment from "moment";
import useStrings from "../../../hooks/useStrings";
import { useStore } from "../../../providers/StoreContext";
import LogsTablePagination from "../components/LogsPagination";
import HttpLogsStore from "../../../store/logs/http-logs-store";

const INTL_MAP = {
  id: "id",
  uuid: "uuid",
  pid: "pid",
  method: "method",
  url: "url",
  status: "status",
  userAgent: "userAgent",
  host: "host",
  timestamp: "timestamp",
  mscs: "mscs",
  login: "login",
  content_length: "content_length"
} as const;

function TableHeaderCell(props: HeaderContext<IHttpLog, any>) {
  const store = useStore();
  const { general } = useStrings(store);
  const id = props.column.id as keyof typeof INTL_MAP;
  const INTL_KEY = INTL_MAP[id];
  return <span>{general[INTL_KEY]}</span>;
}

function TableCell(props: CellContext<IHttpLog, any>) {
  return props.getValue()
    ? truncate(props.getValue())
    : 'null';
}

function truncate(str: string) {
  return str.length > 30 ? str.substring(0, 27) + "..." : str;
}

const prettyDate = (props: any) => {
  return moment(new Date(parseInt(props.getValue(), 10)).toISOString()).format("YYYY-MM-DD HH:mm:ss")
}
const columnHelper = createColumnHelper<IHttpLog>();
const defaultColumns: ColumnDef<IHttpLog>[] = [
  columnHelper.accessor("id", {
    id: "id",
    header: "#",
    cell: TableCell,
  }),
  columnHelper.accessor("pid", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("method", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("timestamp", {
    header: TableHeaderCell,
    cell: prettyDate,
  }),
  columnHelper.accessor("url", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("status", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("userAgent", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("host", {
    header: TableHeaderCell,
    cell: TableCell,
  }),
  columnHelper.accessor("login", {
    header: TableHeaderCell,
    cell: TableCell,
  })
];


const HttpLogs = () => {
  const store = useStore();
  const history = useHistory();
  const httpLogsStore = store.httpLogs;

  const params = useParams<{ page: string }>();

  // Синхронизируем URL-параметр страницы с mobx-хранилищем
  useEffect(() => {
    const page = parseInt(params.page, 10);
    if (Number.isNaN(page)) {
      return;
    }
    if (page !== httpLogsStore.currentPage) {
      httpLogsStore.setPage(page);
    }
    if (page > httpLogsStore.steps && httpLogsStore.steps > 0) {
      history.replace(`/logs/${httpLogsStore.steps}`);
    }
    if (page < 1) {
      history.replace(`/logs/1`);
    }
  }, [params.page, httpLogsStore.currentPage, httpLogsStore.steps]);

  // Загружаем логи за нужный шаг
  useEffect(() => {
    //const abortController = new AbortController();
    let promise: ReturnType<HttpLogsStore["getAllHttpLogs"]>;
    const dispose = autorun(() => {
      promise = httpLogsStore.getAllHttpLogs(
        httpLogsStore.currentPage,
        //abortController.signal
      );
    });

    return () => {
      dispose();
      //abortController.abort();
      promise?.cancel();
    };
  }, [httpLogsStore.currentPage]);

  const table = useReactTable<IHttpLog>({
    data: httpLogsStore.pageLogs,
    columns: defaultColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <>
      <Table striped bordered hover size="sm">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <span>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <LogsTablePagination
        store={httpLogsStore}
      />
    </>
  )
}
export default observer(HttpLogs);