import { rootStore } from "../../providers/StoreContext";
import { RobotAccess, RobotOwner } from "../../store";
import RootStore from "../../store/RootStore";

// Просто возращает список групп и юзеров вместе как владельцев
export function getOwners(robot_owner: string | undefined) {
  if (!robot_owner) return []
  const ownersList: RobotOwner[] = [];
  rootStore.users.list.forEach((user) => {
    const isOwnerUser = robot_owner !== user.id
    if (isOwnerUser)
      ownersList.push({
        id: user.id,
        name: user.login,
        type: 'USER'
      })
  })
  rootStore.groups.groups.forEach((group) => {
    const isOwnerGroup = robot_owner !== group.id;
    if (isOwnerGroup)
      ownersList.push({
        id: group.id,
        name: group.name,
        type: 'GROUP'
      })
  })
  return ownersList;
}

// Просто возращает имя группы или логин по id
export function getOwnerById(store: RootStore, id: string) {
  let user;
  if (id) {
    user = store.users.users.get(id)?.login;
    if (!user) user = store.groups.getNameById(id);
  }
  return user;
}

// Просто добавить имена всем id-шникам, чтобы нормально выводить на экран
export function withNames(
  accesses: RobotAccess[],
  potentialOwners: RobotOwner[]
) {
  accesses.forEach(access => {
    access.name = potentialOwners.find(owner =>
      owner.id === access.accessor_id
    )?.name || 'Удаленный пользователь';
  })
  return accesses;
}