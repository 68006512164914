import React from "react";
import Router from "./components/Router/Router";
import { AuthProvider } from "./providers/AuthContext";
import { StoreProvider } from "./providers/StoreContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import ErrorBoundary from "./errors/ErrorBoundary";

const theme = createTheme();

const useStyles = makeStyles(() => {
  {
    // some CSS that accesses the theme
  }
});

function App() {
  return (
    <ErrorBoundary>
      <StoreProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <Router />
          </ThemeProvider>
        </AuthProvider>
      </StoreProvider>
    </ErrorBoundary>
  );
}

export default App;
