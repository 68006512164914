import React, { useState, useMemo } from 'react';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';
import { Form, Modal, Row, Col, Button, Alert, Dropdown, Card } from 'react-bootstrap';
import { useStore } from '../../../../../providers/StoreContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { roleValidationSchema } from './CreateRoleSchema';
import { BaseResources } from './constants';

const CreateRoleModal = (props: ICreateRoleModalProps) => {
  const [error, setError] = useState<string | null>(null);
  const store = useStore();
  const { control, reset, handleSubmit, formState: { errors, isDirty } } = useForm<RoleCreationInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(roleValidationSchema),
    defaultValues: {
      description: '',
      value: '',
      resources: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "resources",
  });

  const onSubmit: SubmitHandler<RoleCreationInputs> = data => createRole(data);

  const createRole = (role: RoleCreationInputs) => {
    store.roles.createRole(role)
      .then(() => close())
      .catch((err) => {
        const text = err.response?.data.message ?? err.message;
        setError(text)
      });
  }

  // close the window
  const close = () => {
    setError(null);
    reset();
    props.onHide();
  }


  const [name, setName] = useState<string | null>(null);
  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const [create, setCreate] = useState(false);
  const [del, setDelete] = useState(false);

  const handleAddResource = () => {
    if (name) {
      append({ name, read, write, create, delete: del });
      setName(null);
      setRead(false);
      setWrite(false);
      setCreate(false);
      setDelete(false);
    }
  }

  const resorcesDropdown = useMemo(
    () => Object.keys(BaseResources)
      .filter((r) => {
        return r !== fields.find((f) => f.name == r)?.name
      }),
    [fields.length]
  )

  return (
    <Modal
      show={props.show}
      onHide={close}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Создать роль
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error &&
          <Alert dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        }
        <Form>

          {/* Login input */}
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextIogin"
          >
            <Form.Label column sm="2">
              value
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='value'
                render={({ field }) =>
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Введите имя роли"
                      {...field}
                    />
                    {errors.value?.message &&
                      <Alert>{errors.value?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* description Input */}
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextEmail"
          >
            <Form.Label column sm="2">
              description
            </Form.Label>
            <Col sm="10">
              <Controller
                control={control}
                name='description'
                render={({ field }) =>
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Введите значение роли"
                      {...field}
                    />
                    {errors.description?.message &&
                      <Alert>{errors.description?.message}</Alert>
                    }
                  </>
                }
              />
            </Col>
          </Form.Group>

          {/* choose available resources */}
          <Row className="mb-3">
            <Card>
              {fields.map((item, index) =>
                <Col key={item.id} className='d-flex m-3 justify-content-between'>
                  <Controller
                    control={control}
                    name={`resources.${index}.name`}
                    render={({ field }) =>
                      <span
                        ref={field.ref}
                      >
                        {field.value}
                      </span>
                    }
                  />
                  <Controller
                    control={control}
                    name={`resources.${index}.read`}
                    render={({ field }) =>
                      <Form.Check
                        type="checkbox"
                        id="roleResource_read"
                        label='read'
                        defaultChecked={field.value}
                        onChange={field.onChange}
                      />
                    }
                  />
                  <Controller
                    control={control}
                    name={`resources.${index}.write`}
                    render={({ field }) =>
                      <Form.Check
                        type="checkbox"
                        id="roleResource_write"
                        label='write'
                        defaultChecked={field.value}
                        onChange={field.onChange}
                      />
                    }
                  />
                  <Controller
                    control={control}
                    name={`resources.${index}.create`}
                    render={({ field }) =>
                      <Form.Check
                        type="checkbox"
                        id="roleResource_create"
                        label='create'
                        defaultChecked={field.value}
                        onChange={field.onChange}
                      />
                    }
                  />
                  <Controller
                    control={control}
                    name={`resources.${index}.delete`}
                    render={({ field }) =>
                      <Form.Check
                        type="checkbox"
                        id="roleResource_delete"
                        label='delete'
                        defaultChecked={field.value}
                        onChange={field.onChange}
                      />
                    }
                  />
                  <Button
                    onClick={() => remove(index)}
                    variant='danger'
                    size='sm'
                  >
                    Delete
                  </Button>

                </Col>
              )}
            </Card>
          </Row>

          {/* Choose available resource form */}
          <Card className='p-3'>
            <Col className='d-flex justify-content-between'>
              <Dropdown>
                <Dropdown.Toggle variant='outline-success'>
                  {name || 'Select Resource'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {
                    resorcesDropdown.map(r =>
                      <Dropdown.Item
                        onClick={() => setName(r)}
                        key={r}
                      >
                        {r}
                      </Dropdown.Item>
                    )
                  }
                </Dropdown.Menu>
              </Dropdown>
              <Form.Check
                type="checkbox"
                id="addRoleResource_read"
                label='read'
                defaultChecked={read}
                onChange={() => setRead(!read)}
              />
              <Form.Check
                type="checkbox"
                id="addRoleResource_write"
                label='write'
                defaultChecked={write}
                onChange={() => setWrite(!write)}
              />
              <Form.Check
                type="checkbox"
                id="addRoleResource_create"
                label='create'
                defaultChecked={create}
                onChange={() => setCreate(!create)}
              />
              <Form.Check
                type="checkbox"
                id="addRoleResource_delete"
                label='delete'
                defaultChecked={del}
                onChange={() => setDelete(!del)}
              />
              <Button
                disabled={!name}
                variant='success'
                size='sm'
                onClick={handleAddResource}
              >
                Add resource
              </Button>
            </Col>
          </Card>


        </Form>
      </Modal.Body>
      <Modal.Footer>

        {/* Submit button */}
        <Button
          variant="success"
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || Boolean(Object.keys(errors).length)}
        >
          Создать роль
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CreateRoleModal;