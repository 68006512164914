import { makeAutoObservable } from "mobx";
import { Optional } from "../../types";

export interface ILicense {
  id: string;
  id_user: Optional<string>;
  key: string;
  begin_date: string;
  end_date: string;
}

export default class License implements ILicense {
  id: ILicense["id"] = "";
  id_user: ILicense["id_user"] = "";
  key: ILicense["key"] = "";
  begin_date: ILicense["begin_date"] = "";
  end_date: ILicense["end_date"] = "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(data: ILicense): License {
    this.id = data.id;
    this.id_user = data.id_user;
    this.key = data.key;
    this.begin_date = data.begin_date;
    this.end_date = data.end_date;

    return this;
  }
}
