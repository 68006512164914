import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import GlobalVariable, { IGlobalVariable } from "./global-variable";
import VarsStore from "./vars-store";

export function setupVarsStore(container: Container): Container {
  container
    .bind<IGlobalVariable>(diConstants.VARS.GLOBAL_VARIABLE)
    .to(GlobalVariable);

  container
    .bind<VarsStore>(diConstants.VARS.VARS_STORE)
    .to(VarsStore)
    .inSingletonScope();

  return container;
}
