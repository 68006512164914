import { makeAutoObservable } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { Optional } from "../../types";
import { IInfoRobotDetail } from "./info-robot-detail";

export interface IInfoRobot {
  id: string;
  id_robot: string;
  id_task: string;
  id_client: string;
  begin_date: string;
  end_date: Optional<string>;
  cdate: string;
  status: Optional<boolean>;
  value1: Optional<string>;
  value2: Optional<string>;
  value3: Optional<string>;
  value4: Optional<string>;
  value5: Optional<string>;
  error: Optional<string>;
  detail: IInfoRobotDetail[];
  isActive: boolean;
}

export default class InfoRobot implements IInfoRobot {
  id: IInfoRobot["id"] = "";
  id_robot: IInfoRobot["id_robot"] = "";
  id_task: IInfoRobot["id_task"] = "";
  id_client: IInfoRobot["id_client"] = "";
  begin_date: IInfoRobot["begin_date"] = "";
  end_date: IInfoRobot["end_date"] = "";
  cdate: IInfoRobot["cdate"] = "";
  status: IInfoRobot["status"] = false;
  value1: IInfoRobot["value1"] = "";
  value2: IInfoRobot["value2"] = "";
  value3: IInfoRobot["value3"] = "";
  value4: IInfoRobot["value4"] = "";
  value5: IInfoRobot["value5"] = "";
  error: IInfoRobot["error"] = "";
  detail: IInfoRobot["detail"] = [];
  isActive: IInfoRobot["isActive"] = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fromJSON(json: IInfoRobot): Promise<InfoRobot> {
    this.id = json.id;
    this.id_robot = json.id_robot;
    this.id_task = json.id_task;
    this.id_client = json.id_client;
    this.begin_date = json.begin_date;
    this.end_date = json.end_date;
    this.cdate = json.cdate;
    this.status = json.status;
    this.value1 = json.value1;
    this.value2 = json.value2;
    this.value3 = json.value3;
    this.value4 = json.value4;
    this.value5 = json.value5;
    this.error = json.error;
    this.isActive = json.isActive;
    this.detail = await this.loadDetails(json.id_robot, json.id)
    // this.detail = json.detail.map((detail) =>
    //   new InfoRobotDetail().fromJSON(detail)
    // );
    return this;
  }

  addDetail(detail: IInfoRobotDetail) {
    this.detail.push(detail);
    this.detail = [...this.detail];
  }

  async loadDetails(robotId: string, robotInfoId: string) {
    const http = injectHttpClient();
    const details: any[] = await http.get(`api/robots/${robotId}/info/${robotInfoId}/details`);
    if (details?.length) {
      return details;
    }
    return [];
  }
}
