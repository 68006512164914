import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import RoleModel from "./role-model";
import RolesStore from "./roles-store";

export function setupRolesStoreDI(container: Container): Container {
  container.bind<RoleModel>(diConstants.ROLES.ROLE_MODEL).to(RoleModel);
  container
    .bind<RolesStore>(diConstants.ROLES.ROLES_STORE)
    .to(RolesStore)
    .inSingletonScope();

  return container;
}
