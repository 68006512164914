import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import configApp from "../../config/config";
import { resolve } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { ToastStore } from "../../store/errors";

export type QueryStringParams = string | Record<string, any> | [string, any][];

export interface RequestError {
  message: string;
}

export const REFRESH_TOKEN = "REFRESH";

export default class HttpClient {
  alert = resolve<ToastStore>(diConstants.TOASTS)();
  private client: AxiosInstance;
  private interceptorsConfig = {
    requestInterceptorId: 0,
    refreshToken: "",
    responseInterceptorId: 0,
  };

  constructor() {
    this.client = axios.create({
      baseURL: configApp.apiUrl,
    });
  }

  async get<T>(
    url: string,
    parameters: QueryStringParams = "",
    config: Omit<AxiosRequestConfig, "method" | "url"> = {}
  ): Promise<T> {
    const searchParams = new URLSearchParams(parameters);
    const searchParamsString = searchParams.toString();
    return this.client
      .get<T | RequestError>(
        url + (searchParamsString.length > 0 ? `?${searchParamsString}` : ""),
        {
          ...config,
        }
      )
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          return res.data as T;
        }
        throw Error((res.data as RequestError)?.message);
      });
  }

  async post<B, R>(
    url: string,
    body: B,
    config?: Omit<AxiosRequestConfig, "method" | "url">
  ) {
    return this.client
      .post<B, AxiosResponse<R | RequestError>>(url, body, {
        ...config,
        headers: {
          "Content-Type": "application/json",
          ...(config?.headers ?? {}),
        },
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          return res.data as R;
        }
        throw Error((res.data as RequestError)?.message);
      });
  }

  async put<B, R>(url: string, body: B) {
    return this.client
      .put<B, AxiosResponse<R | RequestError>>(url, body)
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          return res.data as R;
        }
        throw Error((res.data as RequestError)?.message);
      });
  }

  async patch<B, R>(url: string, body: B) {
    return this.client
      .patch<B, AxiosResponse<R | RequestError>>(url, body)
      .then((res) => {
        if (res.status >= 200 && res.status < 400) {
          return res.data as R;
        }
        throw Error((res.data as RequestError)?.message);
      });
  }

  async delete<T>(url: string) {
    return this.client.delete<T | RequestError>(url).then((res) => {
      if (res.status >= 200 && res.status < 400) {
        return res.data as T;
      }
      throw Error((res.data as RequestError)?.message);
    });
  }

  cleanClient() {
    this.client = axios.create({});
  }

  private setRequestInterceptor(accessToken: string) {
    // Удаляем старый перехватчик
    this.client.interceptors.request.eject(
      this.interceptorsConfig.requestInterceptorId
    );

    // Добавляем новый
    this.interceptorsConfig.requestInterceptorId =
      this.client.interceptors.request.use((requestConfig) => {
        const token = `Bearer ${accessToken}`;
        if (requestConfig.headers == null) {
          requestConfig.headers = {};
        }
        requestConfig.headers.Authorization = token;
        return requestConfig;
      });
  }

  async setAuthInterceptors(params: {
    accessToken: string;
    refreshToken: string;
  }) {
    const interceptors = this.interceptorsConfig;

    /**
     * Делает запрос на получение новых токенов,
     * после чего перезадает перехватчик запросов
     */
    const getNewToken = async () => {
      type RefreshTokenDto = { refresh_token: string };
      type RefreshTokenResponse = {
        access_token: string;
        refresh_token: string;
        expireDate: string;
      };

      return this.client
        .post<RefreshTokenDto, RefreshTokenResponse>("/auth/refresh", {
          refresh_token: interceptors.refreshToken,
        })
        .then((res) => {
          this.setRequestInterceptor(res.access_token);

          // Обновляем refresh_token
          interceptors.refreshToken = res.refresh_token;
        });
    };

    this.setRequestInterceptor(params.accessToken);

    type AxiosConf = AxiosRequestConfig & { _retry?: boolean };

    const onUnsuccessfulResponse = async (response: AxiosResponse<any>) => {
      this.alert.addError(response)
      const originalConfig = response.config as AxiosConf;
      if (response.status !== 401 || "_retry" in response.config) {
        throw response;
      }

      originalConfig._retry = true;
      return getNewToken().then(() => this.client(originalConfig));
    };

    this.client.interceptors.response.eject(interceptors.responseInterceptorId);
    interceptors.responseInterceptorId = this.client.interceptors.response.use(
      undefined,
      onUnsuccessfulResponse
    );
  }
}
