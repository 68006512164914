import React, { useEffect, useMemo, useState } from "react";
import "./GroupsPage.css";
import { Accordion, Alert, Button, Card, Col, Container, Dropdown, Form, ListGroup, Modal, Row } from "react-bootstrap";
import GroupsPageBreadcrumbs from "./BreadCrumbs/GroupsPageBreadcrumb";
import GroupIcon from '@mui/icons-material/Group'
import RobotIcon from '../../icons/Robot.png'
import { useStore } from "../../providers/StoreContext";
import { Group, GroupRoles, GroupRoleType, IUser, Robot, UpdateGroupParameter } from "../../store";
import { splitIntoChunks } from "../../utils";
import { AddGroupFormData } from "./modals/AddGroupModal/AddGroupModal";
import { observer } from "mobx-react-lite";
import ConfirmDialog from "../../components/Confirm/Confirm";
import CenterSpinner from "../../components/Spinner/Spinner";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types";
import ListGroupUser from "./modals/WatchGroupModal/components/ListGroupUser";
import VirtualScroll from "../../components/VirtualScroll/VirtualScroll";
import { useHistory, useParams } from "react-router-dom";
import TestModal from '../PublishRobotsPage/modals/WatchRobot';
import { Optional, UUID } from "../../types";
import { searchIn } from "../AdminsPage/helpers";

interface ItemProps {
  name: string,
  onClick: () => void
}

const GroupItem = ({ onClick, name }: ItemProps) => (
  <Col>
    <Container
      className="m-3 d-flex flex-column align-items-center"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <Row>
        <Col>
          <GroupIcon
            fontSize={"large"}
            color={"action"}
          />
        </Col>
      </Row>
      <Row>
        <Col>{name}</Col>
      </Row>
    </Container>
  </Col>
)

const RobotItem = ({ onClick, name }: ItemProps) => {
  return (
    <Col>
      <Container
        className="m-3 d-flex flex-column align-items-center"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <Row>
          <Col>
            <img
              height={35}
              src={RobotIcon}
            />
          </Col>
        </Row>
        <Row>
          <Col>{name}</Col>
        </Row>
      </Container>
    </Col>
  )
}

const CHUNK_SIZE = 6;

function GroupsPage() {
  const history = useHistory();
  const params = useParams<{ group_id: string }>();
  const { groups, toasts, robots, users } = useStore();
  const {
    getName,
    watchGroup,
    setPathNames,
    isLoading,
    pathNames,
    currentGroup,
    breadcrumbs,
    createGroup,
    deleteGroup,
  } = groups;
  const {
    watchRobot,
  } = robots;
  const path = breadcrumbs.paths;

  const isInRoot = !pathNames.length;
  const isGroupAdmin = currentGroup?.role === GroupRoles.ADMIN;
  const canAddGroup = isInRoot || isGroupAdmin;

  const currentLevelGroups = breadcrumbs.currentLevelGroups;

  const items = [
    ...currentLevelGroups,
    ...(currentGroup?.robots ?? [])
  ]

  const chunks = useMemo(() => {
    return splitIntoChunks(items ?? [], CHUNK_SIZE);
  }, [currentLevelGroups, currentGroup?.robots]);

  const [showDelete, setShowDelete] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [addRobot, setAddRobot] = useState(false);

  const {
    control: AddGroupControl,
    handleSubmit: AddGroupSubmit,
    reset: AddGroupReset,
    formState: {
      isDirty: AddGroupIsDirty,
      errors: AddGroupErrors
    }
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
      name_en: "",
    },
  });

  const {
    setValue,
    control: EditGroupControl,
    handleSubmit: EditGroupSubmit,
    reset: editGroupReset,
    formState: {
      isDirty: EditGroupIsDirty,
      errors: EditGroupErrors
    }
  } = useForm<UpdateGroupParameter>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      name: "",
      name_en: "",
    },
  });


  useEffect(() => {
    if (currentGroup) {
      setValue('name', currentGroup.name)
      setValue('name_en', currentGroup.name_en)
    }
  }, [currentGroup])

  useEffect(() => {
    if (params.group_id) {
      watchGroup(params.group_id)
    }
  }, [])


  function onValidAdding(data: AddGroupFormData) {
    createGroup({
      parent: path.length > 0
        ? path[path.length - 1]
        : null,
      root_parent: path.length > 0
        ? path[0]
        : null,
      ...data,
    })
      .then(() => AddGroupReset())
  }
  function onInvalidAdding(errors?: FieldErrors<AddGroupFormData>) {
    if (errors?.name && errors?.name_en) {
      if (
        errors.name.type == "required" &&
        errors.name_en.type == "required"
      ) {
        toasts.show(
          "Укажите название на русском и на английском языках!"
        );
        errors.name.ref?.focus?.();
      }
      return;
    }
    if (errors?.name) {
      if (errors.name.type == "required") {
        toasts.show("Укажите название на русском языке!");
        errors.name.ref?.focus?.();
      }
      return;
    }
    if (errors?.name_en) {
      if (errors.name_en.type === "required") {
        toasts.show("Укажите название на английском языке!");
        errors.name_en.ref?.focus?.();
      }
      return;
    }
  }
  function onValidEdit(data: UpdateGroupParameter) {
    if (currentGroup) currentGroup.change(data)
      .then(() => editGroupReset);
  }


  return (
    <Container className="mt-3" fluid>
      <TestModal />
      <CenterSpinner
        isLoad={currentGroup?.isLoading
          ? currentGroup.isLoading
          : isLoading
        }
      />
      <Row>
        <Col sm={4}>
          <Card>
            <Card.Header>
              Параметры:
            </Card.Header>
            <Card.Body>
              {currentGroup
                ? (
                  <Form>
                    <Controller
                      name="name"
                      control={EditGroupControl}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Group as={Row}>
                          <Form.Label column sm={4}>Имя RU</Form.Label>
                          <Col>
                            <Form.Control
                              disabled={!isGroupAdmin}
                              {...field}
                            />
                          </Col>
                        </Form.Group>
                      )}
                    />
                    <Controller
                      name="name_en"
                      control={EditGroupControl}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Form.Group as={Row} className='mt-3'>
                          <Form.Label column sm={4}>Имя EN</Form.Label>
                          <Col>
                            <Form.Control
                              disabled={!isGroupAdmin}
                              {...field}
                            />
                          </Col>
                        </Form.Group>
                      )}
                    />

                    {isGroupAdmin
                      ? <>
                        <ConfirmDialog
                          show={showDelete}
                          onClose={() => setShowDelete(false)}
                          question='Удалить группу?'
                          onConfirm={() =>
                            deleteGroup(currentGroup.id)
                              .then(() => setShowDelete(false))
                          }
                        />

                        <Button
                          className="mt-3 me-3"
                          variant="danger"
                          onClick={() => setShowDelete(true)}
                        >
                          Удалить группу?
                        </Button>
                        <Button
                          disabled={!EditGroupIsDirty
                            && !EditGroupErrors?.name
                            && !EditGroupErrors?.name_en
                          }
                          onClick={EditGroupSubmit(
                            onValidEdit,
                            onInvalidAdding
                          )}
                          className="mt-3 me-3"
                          variant="success"
                        >
                          Сохранить
                        </Button>
                      </>
                      : null
                    }
                  </Form>
                )
                : null
              }
              {currentGroup && <>
                <AddUserModal
                  show={addUser}
                  onHide={() =>
                    setAddUser(false)
                  }
                  onAddUser={(user_id: UUID, role: GroupRoleType) =>
                    currentGroup.addGroupUser({ user_id, role })
                  }
                  roleList={Object.keys(GroupRoles) as GroupRoleType[]}
                  userList={users.list}
                />
                <Accordion className="mt-3">
                  <Accordion.Item eventKey="add-user">
                    <Accordion.Header>
                      {`Пользователи (${currentGroup.users.length})`}
                    </Accordion.Header>
                    <Accordion.Body>
                      {isGroupAdmin
                        ? (
                          <div className="d-grid gap-2 mb-3">
                            <Button
                              variant="outline-primary"
                              onClick={() => setAddUser(true)}
                            >
                              Добавить пользователя
                            </Button>
                          </div>
                        ) : null
                      }
                      {currentGroup.users.length
                        ? (
                          <ListGroup>
                            <VirtualScroll
                              data={currentGroup.users}
                              visibleRows={3}
                              rowHeight={42}
                              render={(user) =>
                                <ListGroupUser
                                  key={user.id}
                                  user={user}
                                  canManage={isGroupAdmin}
                                  deleteUser={() =>
                                    currentGroup.deleteUser(user.user_id)
                                  }
                                />
                              }
                            />
                          </ListGroup>
                        ) : null
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <Accordion>
                  <Accordion.Item eventKey="add-robot">
                    <Accordion.Header>
                      {'Роботы' + ' (' + currentGroup.robots.length + ')'}
                    </Accordion.Header>
                    <Accordion.Body>
                      <AddRobotModal
                        show={addRobot}
                        onHide={() => setAddRobot(false)}
                        onAddRobot={(
                          robotId: UUID,
                          read: boolean,
                          write: boolean,
                          create: boolean,
                          del: boolean
                        ) => {
                          robots.shareRobotHttp({
                            accessor_id: currentGroup.id,
                            read,
                            write,
                            create,
                            delete: del
                          }, robotId)
                        }}
                        robotList={robots.getMyRobots()}
                      />
                      <div className="d-grid gap-2">
                        <Button
                          onClick={() => setAddRobot(true)}
                          variant='outline-primary'
                        >
                          Добавить своего робота
                        </Button>
                      </div>
                      {currentGroup.robots.length
                        ? (
                          <ListGroup className="mt-3">
                            <VirtualScroll
                              data={currentGroup.robots}
                              visibleRows={3}
                              rowHeight={42}
                              render={(robot) =>
                                <ListGroup.Item key={robot.id}>
                                  {robot.robotname}
                                </ListGroup.Item>
                              }
                            />
                          </ListGroup>
                        )
                        : null
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>}
              {canAddGroup && <>
                <Accordion>
                  <Accordion.Item eventKey="add-group">
                    <Accordion.Header>
                      {!currentGroup
                        ? 'Создать группу'
                        : 'Создать подгруппу'
                      }
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form
                        onSubmit={AddGroupSubmit(
                          onValidAdding,
                          onInvalidAdding
                        )}
                      >
                        <Controller
                          name="name"
                          control={AddGroupControl}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <Form.Group>
                                <Form.Label>Название группы</Form.Label>
                                <Form.Control type="text" {...field} />
                              </Form.Group>
                            );
                          }}
                        />
                        <Controller
                          name="name_en"
                          control={AddGroupControl}
                          rules={{ required: true }}
                          render={({ field }) => {
                            return (
                              <Form.Group>
                                <Form.Label>Название группы на английском</Form.Label>
                                <Form.Control type="text" {...field} />
                              </Form.Group>
                            );
                          }}
                        />
                        <Button
                          disabled={!AddGroupIsDirty
                            && !AddGroupErrors?.name
                            && !AddGroupErrors?.name_en
                          }
                          className="mt-3"
                          variant="success"
                          type="submit"
                        >
                          Создать
                        </Button>
                      </Form>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <GroupsPageBreadcrumbs
              pathNames={pathNames}
              setPathNames={setPathNames}
            />
            {chunks.map((chunk, index) =>
              <Row key={index}>
                {Array.from(Array(CHUNK_SIZE)).map((val, index) => {
                  if (chunk[index] instanceof Group) {
                    const group = chunk[index] as Group
                    return (
                      <GroupItem
                        key={group.id}
                        name={getName(group)}
                        onClick={() => {
                          history.push('/groups/' + group.id);
                          watchGroup(group.id);
                        }}
                      />
                    )
                  } else if (chunk[index] instanceof Robot) {
                    const robot = chunk[index] as Robot;
                    return (
                      <RobotItem
                        name={robot.robotname}
                        key={robot.id}
                        onClick={() => watchRobot(robot.id)}
                      />
                    )
                  } else {
                    return (
                      <Col key={index} />
                    )
                  }
                })}
              </Row>
            )}
            {!chunks.length
              ? <Alert variant="primary" className="m-3">
                {!path.length
                  ? "Не найдено"
                  : 'Пусто'
                }
              </Alert>
              : null
            }
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default observer(GroupsPage);

const AddUserModal = observer((props: {
  show: boolean,
  onHide: () => void,
  onAddUser: (user_id: UUID, role: GroupRoleType) => void,
  userList: Array<IUser>,
  roleList: Array<GroupRoleType>
}) => {
  const [
    selectedCandidate,
    setSelectedCandidate
  ] = useState<Optional<IUser>>(null);

  const [
    role,
    setRole
  ] = useState<GroupRoleType>(GroupRoles.USER);

  const {
    userList,
    roleList,
    show,
    onHide,
    onAddUser
  } = props;

  const [search, setSearch] = useState('');
  let searchResult = userList;
  searchResult = useMemo(() => {
    return searchIn(userList, search)
  }, [search, userList])
  return (
    <Modal
      show={show}
      onHide={onHide}
    >
      <Modal.Body>
        <div className="d-flex">
          <Dropdown drop='up'>
            <Dropdown.Toggle
              variant='outline-primary'
            >
              {selectedCandidate?.login || 'Пользователь'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              <Form.Control
                size="sm"
                placeholder="Искать по логину"
                onChange={
                  (e) => setSearch(e.currentTarget.value)
                }
                value={search}
              />
              {!searchResult?.length ?
                <Alert>
                  Ничего не найдено
                </Alert>
                :
                <VirtualScroll
                  data={searchResult}
                  visibleRows={5}
                  rowHeight={33}
                  render={(user: IUser) =>
                    <Dropdown.Item
                      onClick={() =>
                        setSelectedCandidate(user)
                      }
                      key={user.id}
                    >
                      {user.login}
                    </Dropdown.Item>
                  }
                />
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown>
            <Dropdown.Toggle
              variant='outline-primary'
            >
              {role || 'Роль'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {
                roleList.map(role =>
                  <Dropdown.Item
                    onClick={() => setRole(role)}
                    key={role}
                  >
                    {role}
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={onHide}
        >
          Закрыть
        </Button>
        {!selectedCandidate
          ? (
            <Button
              disabled={true}
              variant='success'
            >
              Добавить
            </Button>
          )
          : (
            <Button
              disabled={!selectedCandidate}
              variant='success'
              onClick={() => {
                onAddUser(
                  selectedCandidate.id,
                  role
                );
                onHide();
              }}
            >
              Добавить
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  )
})




const AddRobotModal = observer((props: {
  show: boolean,
  onHide: () => void,
  onAddRobot: (
    robotId: UUID,
    read: boolean,
    write: boolean,
    create: boolean,
    del: boolean
  ) => void,
  robotList: Array<Robot>,
}) => {
  const [
    selectedRobot,
    setSelectedRobot
  ] = useState<Optional<Robot>>(null);

  const {
    show,
    robotList,
    onAddRobot,
    onHide,
  } = props;

  const [read, setRead] = useState(false);
  const [write, setWrite] = useState(false);
  const [create, setCreate] = useState(false);
  const [del, setDelete] = useState(false);

  const [search, setSearch] = useState('');
  let searchResult = robotList;
  searchResult = useMemo(() => {
    return searchIn(robotList, search)
  }, [search, robotList])

  const close = () => {
    onHide();
    setSelectedRobot(null);
    setRead(false);
    setWrite(false);
    setCreate(false);
    setDelete(false);
  }
  return (
    <Modal
      show={show}
      onHide={close}
    >
      <Modal.Body>
        <div className="d-flex">
          <Dropdown drop='up'>
            <Dropdown.Toggle
              variant='outline-primary'
            >
              {selectedRobot?.robotname || 'Робот'}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: '100%' }}>
              <Form.Control
                size="sm"
                placeholder="Искать"
                onChange={
                  (e) => setSearch(e.currentTarget.value)
                }
                value={search}
              />
              {!searchResult?.length ?
                <Alert>
                  Ничего не найдено
                </Alert>
                :
                <VirtualScroll
                  data={searchResult}
                  visibleRows={5}
                  rowHeight={33}
                  render={(robot: Robot) =>
                    <Dropdown.Item
                      onClick={() =>
                        setSelectedRobot(robot)
                      }
                      key={robot.id}
                    >
                      {robot.robotname}
                    </Dropdown.Item>
                  }
                />
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr />
        <Form.Check
          inline
          type='checkbox'
          id='read'
          label='Чтение'
          checked={read}
          onChange={() => setRead(!read)}
        />
        <Form.Check
          inline
          type='checkbox'
          id='write'
          label='Запись'
          checked={write}
          onChange={() => setWrite(!write)}
        />
        <Form.Check
          inline
          type='checkbox'
          id='create'
          label='Публикация'
          checked={create}
          onChange={() => setCreate(!create)}
        />
        <Form.Check
          inline
          type='checkbox'
          id='delete'
          label='Удаление'
          checked={del}
          onChange={() => setDelete(!del)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-danger"
          onClick={close}
        >
          Закрыть
        </Button>
        {!selectedRobot
          ? (
            <Button
              disabled={true}
              variant='success'
            >
              Добавить
            </Button>
          )
          : (
            <Button
              disabled={!selectedRobot}
              variant='success'
              onClick={() => {
                onAddRobot(selectedRobot.id, read, write, create, del);
                close();
              }}
            >
              Добавить
            </Button>
          )
        }
      </Modal.Footer>
    </Modal>
  )
})