import { makeAutoObservable } from "mobx";

export interface IClient {
  id: string;
  id_user: string;
  name: string;
  state?: boolean;
}

export default class Client implements IClient {
  id: IClient["id"] = "";
  id_user: IClient["id_user"] = "";
  name: IClient["name"] = "";
  state?: IClient["state"] = false;

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IClient): Client {
    this.id = json.id;
    this.id_user = json.id_user;
    this.name = json.name;
    this.state = json.state;

    return this;
  }
}
