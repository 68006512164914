import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "./StoreContext";

export const AuthContext = React.createContext(false);
AuthContext.displayName = "StoreContext";

interface AuthContextProps {
  children: React.ReactNode;
}

export const AuthProvider = observer(({ children }: AuthContextProps) => {
  const store = useStore();

  return (
    <AuthContext.Provider value={store.auth.isAuth}>
      {children}
    </AuthContext.Provider>
  );
});

export interface WithAuthProps {
  isAuthorized: boolean;
}

export const withAuth = <P extends object>(
  Wrapped: React.ComponentType<P>
): React.FC<Omit<P, keyof WithAuthProps>> => {
  // eslint-disable-next-line react/display-name
  return (props) => (
    <AuthContext.Consumer>
      {(isAuthorized) => (
        <Wrapped {...(props as P)} isAuthorized={isAuthorized} />
      )}
    </AuthContext.Consumer>
  );
};

export const useAuth = () => React.useContext(AuthContext);
