import { makeAutoObservable, flow, action } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { IRoleModel } from "../roles";
import { IUser } from "./type";
import User from "./user";
import qs from "qs";
import { IAuthUser } from "../auth";
import { diConstants } from "../../di/di.constants";
import { wire } from "../../di/di";
import { LangStore } from "../lang";
import { AxiosError } from "axios";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { searchIn } from "../../pages/AdminsPage/helpers";
import { ToastStore } from "../errors";
import { Modal } from "../../utils/helpers";

interface SetUserInfoAfterUpdateParam {
  login: string,
  email: string,
  password: string,
  fam: string,
  name: string,
  otch: string,
  birthday: string,
  position: string,
  license_key: string,
  license_begin_date: string,
  license_end_date: string,
}

interface IUserCreationAttrs {
  login: string,
  email: string,
  fam: string,
  name: string,
  otch: string,
  birthday: string,
  position: string,
  guid_license: string | null,
  user_lexema8?: string | null,
  isAdmin: boolean
}

export interface IUserInfo {
  user: User;
  license: {
    license: boolean;
    license_key: string;
    license_begin_date: string;
    license_end_date: string;
  };
  numberOfPublications?: string;
}

class UsersStore {
  state: LoadStatesType = LoadStates.INITIAL;

  errors: Array<any> = [];

  public readonly toasts!: ToastStore;
  public readonly lang!: LangStore;
  private readonly http = injectHttpClient();

  users = new Map<IUser["id"], IUser>();

  queryLimit = 500;
  queryOffset = 0;

  constructor() {
    makeAutoObservable(this, {
      toasts: false,
      lang: false
    });
    wire(this, "toasts", diConstants.TOASTS);
    wire(this, "lang", diConstants.LANG.LANG_STORE);

    this.refresh = this.refresh.bind(this)
    this.banUser = this.banUser.bind(this)
    this.search = this.search.bind(this)
    this.addRole = this.addRole.bind(this)
    this.deleteRole = this.deleteRole.bind(this)
    this.banUser = this.banUser.bind(this)
    this.watchUser = this.watchUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
  }

  get isReady() {
    return this.state === LoadStates.COMPLETED
  }
  get isError() {
    return this.state === LoadStates.FAILED
  }
  get isLoading() {
    return this.state === LoadStates.LOADING
  }

  get list(): IUser[] {
    return Array
      .from(this.users.values())
      .sort((prevUser, user) =>
        prevUser.login.localeCompare(user.login)
      );
  }

  _selectedUser: IUser | null = null
  _selectedUserInfo: IUserInfo | null = null

  get selectedUser() {
    return this._selectedUser;
  }
  set selectedUser(user: IUser | null) {
    this._selectedUser = user;
  }

  get selectedUserInfo() {
    return this._selectedUserInfo;
  }
  set selectedUserInfo(user: IUserInfo | null) {
    this._selectedUserInfo = user;
  }


  _searchTerm = '';

  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(term: string) {
    this._searchTerm = term;
  }

  search(term: string) {
    this._searchTerm = term;
  }
  /**
   * Поиск по списку
   */
  get listBySearch() {
    if (!this.searchTerm.length)
      return this.list;

    return searchIn(
      this.list,
      this.searchTerm
    )
  }

  watchAndEditUserModal = new Modal()
  createUserModal = new Modal()

  async watchUser(user: IUser) {
    this.selectedUser = user;
    const userInfo = await this.loadUserInfo(user.id);
    this.selectedUserInfo = userInfo;
    this.watchAndEditUserModal.open();
  }

  beginCreateUser() {
    this.createUserModal.open();
  }

  getById(id: string) {
    return this.users.get(id);
  }

  loadUsers = flow(function* (
    this: UsersStore,
    limit: number,
    offset: number
  ) {
    try {
      this.state = LoadStates.LOADING;
      const users: {
        count: number,
        rows: IUser[]
      } = yield this.http.get(`/api/users/all`, {
        limit,
        offset
      });
      this.state = LoadStates.COMPLETED;
      return users;
    } catch (err) {
      this.onError(err);
      return [];
    }
  });

  loadUserInfo = flow(function* (
    this: UsersStore,
    userId: string
  ) {
    this.state = LoadStates.LOADING;
    try {
      const user = yield this.http.get<IUserInfo>(
        '/api/users/' + userId
      )
      this.state = LoadStates.COMPLETED;
      return user;
    } catch (err) {
      this.onError(err)
    }
  })

  _updateUserRoles = flow(function* (
    this: UsersStore,
    login: string,
    roles: IRoleModel[]
  ) {
    try {
      this.state = LoadStates.LOADING;
      const result: any = yield this.http.post(
        "/api/users/updateUserRoles",
        {
          login,
          roles
        }
      );
      this.state = LoadStates.COMPLETED;
      return result;
    } catch (err) {
      this.onError(err);
    }
  });

  refresh() {
    this.queryOffset = 0;
    this.getAllUsers();
  }

  getAllUsers = flow(function* (this: UsersStore) {
    try {
      const users: {
        count: number,
        rows: IUser[]
      } = yield this.loadUsers(
        this.queryLimit,
        this.queryOffset
      );
      this.users.clear();
      users.rows.forEach((u) => {
        const user = new User();
        user.fromJSON(u);
        this.users.set(user.id, user);
      });
      this.retryingLoadToFull(users);
    } catch (err) {
      this.onError(err);
    }
  });

  // загружаем большие данные кусками, чтобы не тормозить сервер
  private retryingLoadToFull = flow(function* (
    this: UsersStore,
    result: {
      count: number,
      rows: Array<any>
    }
  ) {
    if (result.count > this.queryLimit && this.queryOffset < (result.count - this.queryLimit)) {
      this.queryOffset += this.queryLimit
      const users: any = yield this.loadUsers(
        this.queryLimit,
        this.queryOffset
      );
      users.rows.forEach((u: IUser) => {
        const user = new User();
        user.fromJSON(u);
        this.users.set(user.id, user);
      })
      this.retryingLoadToFull(users);
    }
  })

  // todo deprecated
  updateUserRoles = flow(function* (
    this: UsersStore,
    login: string,
    roles: IRoleModel[]
  ) {
    try {
      yield this._updateUserRoles(login, roles);

      yield this.getAllUsers();
    } catch (err) {
      this.onError(err);
    }
  });

  banUser = flow(function* (
    this: UsersStore,
    userId: string,
    value: boolean
  ) {
    try {
      this.state = LoadStates.LOADING;
      yield this.http.post(
        `/api/admin/users/${userId}/ban`,
        { ban: value }
      )
        .then(() => {
          this.onSuccess();
          const oldUser = this.users.get(userId);
          if (oldUser) {
            oldUser.banned = value;
            this.updateStateUser(oldUser);
          }
        })
    } catch (err) {
      this.onError(err);
    }
  })


  setUserInfoAfterUpdate = flow(function* setUserInfoAfterUpdate(
    this: UsersStore,
    param: SetUserInfoAfterUpdateParam,
    userId: string
  ) {
    this.state = LoadStates.LOADING;
    try {
      const data = qs.stringify(param);
      yield this.http.put<typeof data, Partial<IAuthUser>>(
        '/api/users/' + userId,
        data
      )
        .then((res) => {
          if (res != null) {
            const oldUser = this.users.get(userId);
            if (oldUser) {
              Object.assign(oldUser, res);
              this.updateStateUser(oldUser);
            }
            this.state = LoadStates.COMPLETED;
            this.toasts.show(
              this.lang.getWord('messages').informationSaved
            );
            this.watchAndEditUserModal.close();
          }
        });
    } catch (err) {
      this.onError(err);
    }
  });

  createUser = flow(function* (
    this: UsersStore,
    user: IUserCreationAttrs
  ) {
    try {
      this.state = LoadStates.LOADING;
      const result = yield this.http.post(
        `/api/admin/users/`,
        user
      )
      if (result.registration) {
        this.users.set(
          result.data.id,
          result.data
        );
        this.onSuccess()
      }
    } catch (err) {
      this.onError(err);
    }
  })

  addRole = flow(function* (
    this: UsersStore,
    userId: string,
    role: IRoleModel
  ) {
    try {
      this.state = LoadStates.LOADING;
      const result = yield this.http.post(
        `/api/admin/users/${userId}/roles`,
        { roleId: role.id }
      )
      if (result?.added) {
        const oldUser = this.users.get(userId);
        if (oldUser) {
          oldUser.roles.push(role);
          this.updateStateUser(oldUser);
        }
        this.onSuccess();
      }
    } catch (err) {
      this.onError(err);
    }
  })

  deleteRole = flow(function* (
    this: UsersStore,
    userId: string,
    role: IRoleModel,
  ) {
    try {
      this.state = LoadStates.LOADING;
      const result = yield this.http.delete(
        `/api/admin/users/${userId}/roles/${role.id}`
      )
      if (result?.deleted) {
        const oldUser = this.users.get(userId);
        if (oldUser) {
          oldUser.roles = oldUser.roles.filter((r) => r.id !== role.id);
          this.updateStateUser(oldUser);
        }
        this.onSuccess();
      }
    } catch (err) {
      this.onError(err);
    }
  })

  deleteUser = flow(function* (
    this: UsersStore,
    userId: string,
  ) {
    this.state = LoadStates.LOADING
    yield this.http.delete('/api/users/' + userId)
      .then(() => {
        this.users.delete(userId);
        this.watchAndEditUserModal.close();
        this.onSuccess();
      })
      .catch((err) => this.onError(err))
  })

  private updateStateUser = (oldUser: IUser) => {
    const user = new User();
    user.fromJSON(oldUser);
    this.users.delete(oldUser.id);
    this.users.set(oldUser.id, user);
  }

  onSuccess() {
    this.state = LoadStates.COMPLETED;
    this.toasts.show(
      this.lang.getWord('messages').success
    );
  }


  onError(err: unknown) {
    console.error(err);
    this.errors.push(err);
    this.state = LoadStates.FAILED;
    if (err instanceof AxiosError)
      this.toasts.addError(err)

  }
}

export default UsersStore;
