import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Tab, Table, Tabs } from "react-bootstrap";
import ManageRoles from "./sections/ManageRoles";
import ManageUsers from "./sections/ManageUsers";
import './AdminsPage.css';
import HttpLogs from "../LogsPage/HttpLogs/HttpLogs";
import SystemLogs from "../LogsPage/SystemLogs/SystemLogs";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import useStrings from "../../hooks/useStrings";
import moment from "moment";
import useInputBind from "../../hooks/useInputBind";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import UpdateStudio from "../PersonalAccountPage/UpdateStudio/UpdateStudio";
import DemoLicenseEdit from "../PersonalAccountPage/DemoLicenceEdit/DemoLicenseEdit";
import SendNotifications from "../../components/SendNotifications/SendNotifications";

const LicenseEdit = observer(function LicenseEdit() {
  const store = useStore();
  const { general, personalAccount } = useStrings(store);

  const beginDate = useInputBind(moment().format("YYYY-MM-DD"));
  const endDate = useInputBind(moment().add(1, "M").format("YYYY-MM-DD"));
  const [currentId, setCurrentId] = useState("");
  const usersArr = store.users.list;
  const licenseState = store.license.state;

  function loadLicenses() {
    store.license.getAllLicenses();
  }

  useEffect(() => {
    loadLicenses();
  }, []);

  const licenses = store.license.licensesArray;

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    store.license.updateLicense(
      currentId,
      new Date(beginDate.bind.value).toISOString(),
      new Date(endDate.bind.value).toISOString()
    );
  };

  if (licenseState === "LOADING") {
    return (
      <>
        <p>...Загрузка</p>
      </>
    );
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group as={Row} className="mb-3" controlId="userControl">
          <Form.Label column sm="4">
            {general.selectUser}
          </Form.Label>
          <Col sm="8">
            <Form.Control
              as={"select"}
              value={currentId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentId(e.target.value)
              }
            >
              <option value={""}>{general.selectUser}</option>
              {usersArr.map((user) => (
                <option value={user.id} key={user.id}>
                  {user.login}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="beginDateControl">
          <Form.Label column sm="4">
            {personalAccount.beginningOfTheLicense}
          </Form.Label>
          <Col sm="8">
            <Form.Control type="date" {...beginDate.bind} />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3" controlId="endDateControl">
          <Form.Label column sm="4">
            {personalAccount.licenseExpiration}
          </Form.Label>
          <Col sm="8">
            <Form.Control type="date" {...endDate.bind} />
          </Col>
        </Form.Group>
        <Row className="mb-3 justify-content-md-end">
          <Col sm="3">
            <Button variant="success" type="submit" disabled={false}>
              {personalAccount.upgradeLicense}
            </Button>
          </Col>
        </Row>
      </Form>
      {licenseState === "COMPLETED" ? (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>{general.user}</th>
              <th>{general.startDate}</th>
              <th>{general.expirationDate}</th>
              <th>{general.key}</th>
              <th>{general.state}</th>
            </tr>
          </thead>
          <tbody>
            {licenses.map((license, index) => {
              return (
                <tr key={license.id}>
                  <td>{index + 1}</td>
                  <td>{license.user}</td>
                  <td>{license.beginDate}</td>
                  <td>{license.endDate}</td>
                  <td>{license.key}</td>
                  <td>
                    <VpnKeyIcon fontSize={"large"} color={license.active} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : null}
      {licenseState === "FAILED" ? (
        <>
          <p>Ошибка при загрузке списка лицензий</p>
          <Button variant="warning" onClick={loadLicenses}>
            Попробовать загрузить
          </Button>
        </>
      ) : null}
    </>
  );
});

export const AdminsPage = () => {
  return (
    <Container fluid>
      <Tabs className="mt-3">
        <Tab
          eventKey="manage_users"
          title='Пользователи'
          className="mt-3 mb-3"
        >
          <ManageUsers />
        </Tab>
        <Tab
          eventKey="roles"
          title='Роли'
          className="mt-3 mb-3"
        >
          <ManageRoles />
        </Tab>
        <Tab
          key={"system_logs"}
          eventKey="system_logs"
          title='Системные логи'
          className="mt-3 mb-3"
        >
          <SystemLogs />
        </Tab>
        <Tab
          key={"http_logs"}
          eventKey="http_logs"
          title='HTTP логи'
          className="mt-3 mb-3"
        >
          <HttpLogs />
        </Tab>
        <Tab
          key={"send_msgs"}
          eventKey="send_msgs"
          title='Отправка уведомлений'
          className="mt-3 mb-3"
        >
          <SendNotifications />
        </Tab>
        <Tab
          key={"update_studio"}
          eventKey="update_studio"
          title='Обновление RPA Studio'
          className="mt-3 mb-3"

        >
          <UpdateStudio />
        </Tab>
      </Tabs>
    </Container>
  )
}