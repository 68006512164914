import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import AlertStore from "./AlertStore";

export function setupAlertStore(container: Container): Container {
  container
    .bind<AlertStore>(diConstants.ALERT)
    .to(AlertStore)
    .inSingletonScope();

  return container;
}
