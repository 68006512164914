import { makeAutoObservable } from "mobx";
import { Optional } from "../../types";

export interface ILog {
  id: number;
  pid: number;
  timestamp: number;
  level: string;
  class: Optional<string>;
  handler: Optional<string>;
  stack: Optional<string>;
  message: Optional<string>;
  username: Optional<string>;
  requestUuid: Optional<string>;
}

export default class Log implements ILog {
  id = 0;
  pid = 0;
  timestamp = 0;
  level = "";
  class: Optional<string> = "";
  handler: Optional<string> = "";
  stack: Optional<string> = "";
  message: Optional<string> = "";
  username: Optional<string> = "";
  requestUuid: Optional<string> = "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: ILog): Log {
    this.id = json.id;
    this.pid = json.pid;
    this.timestamp = json.timestamp;
    this.level = json.level;
    this.class = json.class;
    this.handler = json.handler;
    this.stack = json.stack;
    this.message = json.message;
    this.username = json.username;
    this.requestUuid = json.requestUuid;
    return this;
  }
}

export interface IHttpLog {
  id: number;
  uuid: string;
  pid: number;
  method: string;
  url: string;
  status: number;
  userAgent: string;
  host: string;
  login: string;
  mscs: number;
  content_length: number;
  timestamp: number;
}

export class HttpLog implements IHttpLog {
  id = 0;
  uuid = '';
  pid = 0;
  timestamp = 0;
  method = '';
  url = '';
  status = 0;
  userAgent = '';
  host = '';
  login = '';
  mscs = 0;
  content_length = 0;

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IHttpLog): HttpLog {
    this.id = json.id;
    this.pid = json.pid;
    this.timestamp = json.timestamp;
    this.uuid = json.uuid;
    this.method = json.method;
    this.url = json.url;
    this.status = json.status;
    this.userAgent = json.userAgent;
    this.host = json.host;
    this.login = json.login;
    this.mscs = json.mscs;
    this.content_length = json.content_length;
    return this;
  }
}