import React, { useMemo } from "react";

interface VirtualScrollProps {
  data: any,
  rowHeight: number,
  visibleRows: number,
  render: (elem: any) => any
}


function VirtualScroll({
  data,
  rowHeight,
  visibleRows,
  render
}: VirtualScrollProps) {
  const rootRef = React.useRef<HTMLDivElement>(null);
  const [start, setStart] = React.useState(0);

  function getTopHeight() {
    return rowHeight * start;
  }
  function getBottomHeight() {
    return rowHeight * (data.length - (start + visibleRows + 1));
  }

  React.useEffect(() => {
    function onScroll(e: Event) {
      const evt = e as unknown as React.UIEvent<HTMLElement>
      setStart(Math.min(
        data.length - visibleRows - 1,
        Math.floor(evt.currentTarget.scrollTop / rowHeight)
      ));
    }
    if (rootRef?.current)
      rootRef.current.addEventListener('scroll', onScroll);

    return () => {
      if (rootRef?.current)
        rootRef.current.removeEventListener('scroll', onScroll);
    }
  }, [data.length, visibleRows, rowHeight]);

  const height = useMemo(() => {
    if (data.length >= visibleRows)
      return rowHeight * visibleRows + 1
    return rowHeight * data.length + 1;
  }, [data.length])

  return (
    <div
      style={{
        height: height,
        overflow: 'auto',
        overflowX: 'hidden',
        overflowY: "scroll"
      }}
      ref={rootRef}
    >
      <div
        style={{
          height: getTopHeight()
        }}
      />
      {data
        .slice(start, start + visibleRows + 1)
        .map(render)
      }
      <div
        style={{
          height: getBottomHeight()
        }}
      />
    </div>
  )
}

export default VirtualScroll;