import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import Robot from "./robot";
import RobotStore from "./robots-store";

export function setupRobotsStoreDI(container: Container): Container {
  container.bind<Robot>(diConstants.ROBOTS.ROBOT).to(Robot);
  container
    .bind<RobotStore>(diConstants.ROBOTS.ROBOT_STORE)
    .to(RobotStore)
    .inSingletonScope();

  return container;
}
