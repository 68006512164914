import { reaction } from "mobx";
import { wire } from "../di/di";
import { diConstants } from "../di/di.constants";
import { AlertStore } from "./alert";
import { AppLicense } from "./app-license";
import { AuthStore } from "./auth";
import { ClientStore } from "./clients";
import { ToastStore } from "./errors";
import { GroupsStore } from "./groups";
import { InfoRobotsStore } from "./info_robots";
import { LangStore } from "./lang";
import { LicenseStore } from "./license";
import { LogsStore } from "./logs";
import HttpLogsStore from "./logs/http-logs-store";
import { NotificationStore } from "./notifications";
import { RobotStore } from "./robots";
import { RolesStore } from "./roles";
import TasksStore from "./tasks/tasks-store";
import { UsersStore } from "./users";
import { VarsStore } from "./vars";

class RootStore {
  errors: Array<any> = [];
  readonly auth!: AuthStore;
  readonly logs!: LogsStore;
  readonly httpLogs!: HttpLogsStore;
  readonly vars!: VarsStore;
  readonly roles!: RolesStore;
  readonly users!: UsersStore;
  readonly notifications!: NotificationStore;
  readonly alert!: AlertStore;
  readonly license!: LicenseStore;
  readonly clients!: ClientStore;
  readonly lang!: LangStore;
  readonly robots!: RobotStore;
  readonly appLicense!: AppLicense;
  readonly infoRobots!: InfoRobotsStore;
  readonly groups!: GroupsStore;
  readonly toasts!: ToastStore;
  readonly tasks!: TasksStore;

  subscriptions: (() => void)[] = [];

  constructor() {
    wire(this, "auth", diConstants.AUTH.AUTH_STORE);
    wire(this, "logs", diConstants.LOG.LOGS_STORE);
    wire(this, "httpLogs", diConstants.LOG.HTTP_LOGS_STORE);
    wire(this, "vars", diConstants.VARS.VARS_STORE);
    wire(this, "roles", diConstants.ROLES.ROLES_STORE);
    wire(this, "users", diConstants.USERS.USERS_STORE);
    wire(this, "notifications", diConstants.NOTIFICATION.NOTIFICATION_STORE);
    wire(this, "alert", diConstants.ALERT);
    wire(this, "license", diConstants.LICENSE.LICENSE_STORE);
    wire(this, "clients", diConstants.CLIENTS.CLIENT_STORE);
    wire(this, "lang", diConstants.LANG.LANG_STORE);
    wire(this, "robots", diConstants.ROBOTS.ROBOT_STORE);
    wire(this, "appLicense", diConstants.APP_LICENSE);
    wire(this, "infoRobots", diConstants.INFO_ROBOTS.INFO_ROBOT_STORE);
    wire(this, "groups", diConstants.GROUPS.GROUP_STORE);
    wire(this, "toasts", diConstants.TOASTS);
    wire(this, "tasks", diConstants.TASKS.TASKS_STORE);

    // makeAutoObservable(this);

    const dispose = reaction(
      () => this.auth.isAuthorized,
      (value, prevValue) => {
        if (value === true && prevValue === false) {
          this.afterAuthorized()
        }
      }
    );

    this.subscriptions.push(dispose);
  }

  onDestroy() {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }

  afterAuthorized() {
    this.users.refresh();
    this.groups.refresh();
    this.robots.getRobots();
    this.clients.getAllClients();
    this.tasks.refresh();
  }
}

export default RootStore;
