import { makeAutoObservable } from "mobx";
import { UUID } from "../../types";
import Group from "./group";
import GroupsStore from "./groups-store";

export default class GroupBreadcrumbs {
  groupsStore: GroupsStore;
  paths: UUID[] = [];

  constructor(groupsStore: GroupsStore) {
    this.groupsStore = groupsStore;
    makeAutoObservable(this);
  }

  setPath(paths: UUID[]): void {
    this.paths = paths;
  }

  get currentLevelGroups() {
    if (this.paths.length === 0) {
      const groups: Group[] = [];
      this.groupsStore.groups.forEach((group) => {
        const parent = this.groupsStore.groups.find((parent) =>
          parent.id === group.parent
        );
        if (!parent) {
          groups.push(group);
        }
      })
      return groups;
    }
    return this.groupsStore.groups.filter(
      (group) => group.parent === this.paths[this.paths.length - 1]
    );
  }
}
