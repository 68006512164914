import { Container } from "@owja/ioc";
import { diConstants } from "../di/di.constants";
import setupWebSocketDI from "../features/websocket/setup-ws-di";
import { pipe } from "../utils";
import { setupAlertStore } from "./alert";
import { setupAppLicense } from "./app-license";
import { setupAuthStoreDI } from "./auth";
import { setupClientsStoreDI } from "./clients";
import { setupToastStore } from "./errors";
import { setupGroupsStoreDI } from "./groups";
import { setupInfoRobotsStoreDI } from "./info_robots";
import { setupLangStoreDI } from "./lang";
import { setupLicenseStoreDI } from "./license";
import { setupLogsStoreDI } from "./logs";
import { setupHttpLogsStoreDI } from "./logs/di/setup-http-logs-store-di";
import { setupNotificationsStoreDI } from "./notifications";
import { setupRobotsStoreDI } from "./robots";
import { setupRolesStoreDI } from "./roles";
import RootStore from "./RootStore";
import { setupTasksStoreDI } from "./tasks/tasks-store";
import { setupUsersStoreDI } from "./users";
import { setupVarsStore } from "./vars";

export default function setupStoresDI(container: Container): Container {
  pipe(
    // mobx-stores
    setupAlertStore,
    setupAppLicense,
    setupAuthStoreDI,
    setupClientsStoreDI,
    setupGroupsStoreDI,
    setupLangStoreDI,
    setupLicenseStoreDI,
    setupLogsStoreDI,
    setupHttpLogsStoreDI,
    setupNotificationsStoreDI,
    setupRobotsStoreDI,
    setupInfoRobotsStoreDI,
    setupRolesStoreDI,
    setupUsersStoreDI,
    setupVarsStore,
    setupToastStore,
    setupWebSocketDI,
    setupTasksStoreDI
  )(container);

  container
    .bind<RootStore>(diConstants.ROOT_STORE)
    .to(RootStore)
    .inSingletonScope();

  return container;
}
