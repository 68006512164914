import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  ListGroup,
  Container,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import moment from "moment";
import useStrings from "../../hooks/useStrings";
import { Role } from "../../store/roles/type";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import { useForm, Controller } from "react-hook-form";
import { IRoleModel } from "../../store/roles";
import { yupResolver } from "@hookform/resolvers/yup";
import { Inputs, userEditSchema } from "./userEditSchema";
import { generatePassword } from "../AdminsPage/helpers";


const GeneralPersonalInfo = observer(function GeneralPersonalInfo() {
  const store = useStore();
  const { auth } = store;
  const {
    general,
    personalAccount
  } = useStrings(store);
  const [editForm, setEditForm] = useState(true);

  const authUser = auth.user;
  const {
    user,
    license,
  } = authUser;

  const {
    formState: {
      errors
    },
    reset,
    control,
    setValue,
    handleSubmit
  } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(userEditSchema),
    defaultValues: {
      fam: user.fam,
      name: user.name,
      otch: user.otch,
      position: user.position,
      password: '',
      license_key: license.license_key,
      birthday: moment(user.birthday).format("YYYY-MM-DD"),
      license_begin_date: moment(license.license_begin_date).format("YYYY-MM-DD"),
      license_end_date: moment(license.license_end_date).format("YYYY-MM-DD"),
    }
  });

  useEffect(() => () => reset(), [])

  const onSubmit = (data: Inputs) =>
    auth.setAuthInfoAfterUpdate(user.id, data);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3 justify-content-md-end">
        <Col sm="4">
          <Form.Check
            type="checkbox"
            id="editForm"
            label={personalAccount.allowEditing}
            defaultChecked={!editForm}
            onChange={() => setEditForm(!editForm)}
          />
        </Col>
      </Row>

      {/* surname input */}
      <Form.Group as={Row} className="mb-3" controlId="surname_input">
        <Form.Label column sm="2">
          {general.surname}
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name='fam'
            render={({ field }) =>
              <>
                <Form.Control
                  disabled={editForm}
                  type="text"
                  placeholder="Введите фамилию"
                  {...field}
                />
                {errors.fam?.message &&
                  <Alert>{errors.fam?.message}</Alert>
                }
              </>
            }
          />
        </Col>
      </Form.Group>


      {/* name input */}
      <Form.Group as={Row} className="mb-3" controlId="name_input">
        <Form.Label column sm="2">
          {general.name}
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name='name'
            render={({ field }) =>
              <>
                <Form.Control
                  disabled={editForm}
                  type="text"
                  placeholder="Введите имя"
                  {...field}
                />
                {errors.name?.message &&
                  <Alert>{errors.name?.message}</Alert>
                }
              </>
            }
          />
        </Col>
      </Form.Group>


      {/* otchestvo input */}
      <Form.Group as={Row} className="mb-3" controlId="otch_input">
        <Form.Label column sm="2">
          Отчество
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name='otch'
            render={({ field }) =>
              <>
                <Form.Control
                  disabled={editForm}
                  type="text"
                  placeholder="Введите отчество"
                  {...field}
                />
                {errors.otch?.message &&
                  <Alert>{errors.otch?.message}</Alert>
                }
              </>
            }
          />
        </Col>
      </Form.Group>

      {/* Password input */}
      <Form.Group as={Row} className="mb-3" controlId="password_input">
        <Form.Label column sm="2">
          Новый пароль
        </Form.Label>
        <Col sm="7">
          <Controller
            control={control}
            name='password'
            render={({ field }) =>
              <>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Введите новый пароль"
                    disabled={editForm}
                    value={field.value || ''}
                    name={field.name}
                    ref={field.ref}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                  <Button
                    variant="outline-success"
                    disabled={editForm}
                    onClick={() => setValue(
                      "password",
                      generatePassword()
                    )}
                  >
                    Сгенерировать пароль
                  </Button>
                </InputGroup>
                {errors.password?.message &&
                  <Alert>{errors.password?.message}</Alert>
                }
              </>
            }
          />

        </Col>
      </Form.Group>


      {/* birthday input */}
      <Form.Group as={Row} className="mb-3" controlId="birthday_input">
        <Form.Label column sm="2">
          {general.birthday}
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name="birthday"
            render={({ field }) => (
              <>
                <Form.Control
                  disabled={editForm}
                  type="date"
                  {...field}
                />
                {errors.birthday?.message &&
                  <Alert>{errors.birthday?.message}</Alert>
                }
              </>
            )}
          />
        </Col>
      </Form.Group>

      {/* Position input */}
      <Form.Group as={Row} className="mb-3" controlId="position_input">
        <Form.Label column sm="2">
          {general.position}
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name="position"
            render={({ field }) => (
              <>
                <Form.Control
                  disabled={editForm}
                  type="text"
                  placeholder="Введите должность"
                  {...field}
                />
                {errors.position?.message &&
                  <Alert>{errors.position?.message}</Alert>
                }
              </>
            )}
          />
        </Col>
      </Form.Group>
      <hr />

      {/* license_key input */}
      <Form.Group as={Row} className="mb-3" controlId="key_input">
        <Form.Label column sm="2">
          {personalAccount.licenseKey}
        </Form.Label>
        <Col sm="10">
          <Controller
            control={control}
            name="license_key"
            render={({ field }) => (
              <>
                <Form.Control
                  disabled={true}
                  type="text"
                  placeholder="Введите лицензионный ключ"
                  {...field}
                />
                {errors.license_key?.message &&
                  <Alert>{errors.license_key?.message}</Alert>
                }
              </>
            )}
          />
        </Col>
      </Form.Group>

      {/* license_begin_date input */}
      <Form.Group as={Row} className="mb-3" controlId="date_input">
        <Form.Label column sm="2">
          {general.actsWith}
        </Form.Label>
        <Col sm="4">
          <Controller
            control={control}
            name="license_begin_date"
            render={({ field }) => (
              <>
                <Form.Control
                  disabled={true}
                  type="date"
                  {...field}
                />
                {errors.license_begin_date?.message &&
                  <Alert>{errors.license_begin_date?.message}</Alert>
                }
              </>
            )}
          />
        </Col>

        {/* license_end_date input */}
        <Form.Label column sm="2">
          {general.by}
        </Form.Label>
        <Col sm="4">
          <Controller
            control={control}
            name="license_end_date"
            render={({ field }) => (
              <>
                <Form.Control
                  disabled={true}
                  type="date"
                  {...field}
                />
                {errors.license_end_date?.message &&
                  <Alert>{errors.license_end_date?.message}</Alert>
                }
              </>
            )}
          />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="id_input">
        <Form.Label column sm="2">
          {personalAccount.customerKey}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            type="text"
            disabled={true}
            value={user.id}
          />
        </Col>
      </Form.Group>
      <Row className="mb-3 justify-content-md-end">
        <Col sm="2">
          <Button
            variant="primary"
            type="submit"
            disabled={editForm}
            onClick={() => console.log(errors)}
          >
            {general.save}
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

interface RightComponentProps extends AccessRightsProps {
  right: Role;
}

interface AccessRightsProps {
  disabledCheckBox: boolean;
  authRoles: Role[];
  onChangeRoles: (name: string, value: any) => void;
}

const AccessRights = observer(function AccessRights({
  disabledCheckBox,
  authRoles,
  onChangeRoles,
}: AccessRightsProps) {
  const store = useStore();

  const roles = store.roles;
  const { general } = useStrings(store);

  useEffect(() => {
    store.roles.getAllRoles();
  }, []);

  const RightComponent: React.FC<RightComponentProps> = ({
    right,
    disabledCheckBox,
    authRoles,
    onChangeRoles,
  }) => {
    return (
      <Form>
        <Form.Group
          as={Row}
          className="mb-3 align-items-center"
          controlId="formPlaintextPosition"
        >
          <Form.Label column sm="6">
            {right.value}
          </Form.Label>
          <Col sm="6">
            <Form.Check
              type="checkbox"
              id="right"
              label={general.allow}
              disabled={disabledCheckBox}
              defaultChecked={!!authRoles.find((r) => r.value === right.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChangeRoles(right.value, e.target.checked);
              }}
            />
          </Col>
        </Form.Group>
      </Form>
    );
  };
  return (
    <ListGroup>
      {roles.roles.map((r) => {
        return (
          <ListGroup.Item key={r.id}>
            <RightComponent
              right={r}
              disabledCheckBox={disabledCheckBox}
              authRoles={authRoles}
              onChangeRoles={onChangeRoles}
            />
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
});

const AccessRightsEdit = observer(function AccessRightsEdit() {
  const store = useStore();
  const { general, personalAccount } = useStrings(store);
  const [currentId, setCurrentId] = useState("");
  const users = store.users.users;
  const usersArr = store.users.list;
  const [authRoles, setAuthRoles] = useState<IRoleModel[] | null>(null);

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const id = currentId.trim();
    if (id.length > 0) {
      const user = users.get(id);
      if (user) {
        setAuthRoles(user.roles);
      }
    } else {
      setAuthRoles([]);
    }
  };

  const saveRoles = () => {
    if (authRoles) {
      const user = users.get(currentId);
      store.users.updateUserRoles(user?.login ?? '', authRoles); // todo trash
    }
  };

  const onChangeRoles = (name: string, value: boolean) => {
    if (authRoles?.length) {
      if (value === true) {
        if (!authRoles.find((r) => r.value == name)) {
          authRoles.push();
        }
      } else {
        setAuthRoles(authRoles.filter((s) => s.value !== name));
      }
      // setAuthRoles(authRoles.join(","));
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col>
            <Form.Control
              as={"select"}
              value={currentId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCurrentId(e.target.value);
              }}
            >
              <option value={""}>{general.selectUser}</option>
              {usersArr.map((user) => {
                return (
                  <option value={user.id} key={user.id}>
                    {user.login}
                  </option>
                );
              })}
            </Form.Control>
          </Col>
          <Col>
            <Button variant="success" type="submit">
              {personalAccount.uploadRights}
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="mt-5">
        {authRoles?.length ? (
          <div>
            <Button className="mb-3" variant="success" onClick={saveRoles}>
              {general.save}
            </Button>
            <AccessRights
              disabledCheckBox={false}
              authRoles={authRoles}
              onChangeRoles={onChangeRoles}
            />
          </div>
        ) : null}
      </div>
    </>
  );
});







const PersonalAccountPage = observer(function PersonalAccountPage() {
  const store = useStore();
  const user = store.auth.user?.user;
  const roles = store.auth.user?.user.roles;
  const { personalAccount } = useStrings(store);

  if (!user) return null

  return (
    <Container>
      <Tabs
        defaultActiveKey="general_personal_info"
        id="uncontrolled-tab-example"
        className="mb-3 mt-3"
      >
        <Tab
          eventKey="general_personal_info"
          title={personalAccount.generalInfo}
        >
          <GeneralPersonalInfo />
        </Tab>
      </Tabs>
    </Container>
  )
});

export default PersonalAccountPage;
