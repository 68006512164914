import { observer } from "mobx-react-lite";
import useInputBind from "../../../hooks/useInputBind";
import useStrings from "../../../hooks/useStrings";
import { useStore } from "../../../providers/StoreContext";
import React, { useState, useEffect, useRef } from 'react';
import { injectHttpClient } from "../../../features/requests/inject-http-client";
import { Button, Col, Form, Row } from "react-bootstrap";
import moment from "moment";
import { Table } from "react-bootstrap";
import GetAppIcon from "@mui/icons-material/GetApp";

type File = {
  id: string;
  name: string;
  version: string;
  cdate: string;
};

const UpdateStudio = observer(function UpdateStudio() {
  const store = useStore();
  const { general } = useStrings(store);

  const version = useInputBind("");
  const [file, setFile] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    getFiles();
  }, []);

  const [files, setFiles] = useState<File[]>([]);

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.value);
  };

  function downloadFile(fileId: string, fileName: string) {
    const http = injectHttpClient();
    http.get(
      `/api/files/${fileId}`, [],
      { responseType: 'blob' }
    )
      .then(async (response: any) => {
        const dowloadUrl = window.URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = dowloadUrl;
        link.download = fileName || fileId;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
  }

  const getFiles = async () => {
    const http = injectHttpClient();

    const response = await http.get<File[]>("/api/files");
    setFiles(response);
  };

  const uploadFile = async () => {
    if (fileRef.current != null) {
      const http = injectHttpClient();
      const data = new FormData();
      data.append(
        "file",
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        fileRef?.current?.files?.[0]!,
        // eslint-disable-next-line
        // @ts-ignore
        // eslint-disable-next-line
        fileRef.current?.files?.[0]!.name
      );
      data.append("version", version.bind.value);

      const url = "/api/files";
      await http.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        onUploadProgress(progressEvent) {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
        },
      })
        .then((result) => {
          const file = result as File;
          if (files.filter((f) => f.name == file.name).length) {
            setFiles([...files.filter((f) => f.name !== file.name), file]) // update file by name
          } else {
            setFiles([...files, file]); // new file
          }
        })
    }
  };

  useEffect(() => {
    getFiles();
  }, []);

  return (
    <>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextVersion">
          <Form.Label column sm="4">
            {general.version}
          </Form.Label>
          <Col sm="8">
            <Form.Control type="text" {...version.bind} />
          </Col>
        </Form.Group>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Control
            type="file"
            ref={fileRef}
            value={file}
            onChange={onChangeFile}
          />
        </Form.Group>
        <Row className="mb-3 justify-content-md-end">
          <Col sm="3">
            <Button variant="success" onClick={uploadFile} disabled={false}>
              {general.upload}
            </Button>
          </Col>
        </Row>
      </Form>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>{general.name}</th>
            <th>{general.version}</th>
            <th>{general.date}</th>
            <th>{general.actions}</th>
          </tr>
        </thead>
        <tbody>
          {files.map((f) => {
            return (
              <tr key={f.id}>
                <td>{f.id}</td>
                <td>{f.name}</td>
                <td>{f.version}</td>
                <td>{moment(f.cdate).format("DD-MM-YYYY")}</td>
                <td
                  onClick={() => downloadFile(f.id, f.name)}
                >
                  <GetAppIcon fontSize={"large"} color={"action"} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
});

export default UpdateStudio;