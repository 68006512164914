import React, { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./RegistrationForm.css";
import AppModal from "../AppModal/AppModal";
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { registrationSchema } from "./RegistrationSchema";

type RegistrationFormProps = {
  match: any;
};

type Inputs = {
  login: string
  email: string
  fam: string
  name: string
  otch: string
  birthday: string
  position: string
  password: string
  passwordTwo?: string
  guid_license: string | null
};

const RegistrationForm = ({ match }: RegistrationFormProps) => {
  const [error, setError] = useState<string | null>(null);
  const { control, reset, handleSubmit, formState: { errors, isDirty } } = useForm<Inputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(registrationSchema),
    defaultValues: {
      login: '',
      email: '',
      fam: '',
      name: '',
      otch: '',
      birthday: '',
      position: '',
      password: '',
      passwordTwo: '',
      guid_license: match.params.guid_license || null,
    }
  });

  const onSubmit: SubmitHandler<Inputs> = data => queryRegistration(data);

  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [positive, setPositive] = useState(false);
  const history = useHistory();

  const queryRegistration = (user: Inputs) => {
    const http = injectHttpClient();
    delete user.passwordTwo;

    return http.post<typeof user, { registration: boolean }>(
      "/api/auth/external_registration",
      user
    )
      .then((result) => {
        if (result.registration) {
          setTitleModal("Поздравляем!");
          setPositive(true);
        }
      })
      .catch((err) => {
        const text = err.response?.data.message ?? err.message;
        setError(text)
        setTitleModal("Ошибка!");
        setPositive(false);
      })
      .finally(() => {
        setShowModal(true);
      });
  };

  const BodyComponentModal = () => {
    const text = positive
      ? "Вы успешно завершили регистрацию!"
      : "Пользователь с таким логином уже существует!";
    return (
      <>
        <h5>{text}</h5>
        {positive ? (
          <Button onClick={() => history.push("/login")}>
            Перейти на форму входа
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <>
      <AppModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={titleModal}
        BodyComponent={BodyComponentModal}
      />
      <div className="Login">
        {error &&
          <Alert variant="danger" dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        }
        <img
          src="/imgLogin.png"
          style={{
            // transform: 'scale(0.5, 0.5)',
            maxWidth: "30%",
          }}
        />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group controlId="fam">
            <Form.Label>Фамилия</Form.Label>
            <Controller
              control={control}
              name='fam'
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Введите фамилию"
                    {...field}
                  />
                  {errors.fam?.message &&
                    <Alert>{errors.fam?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="name">
            <Form.Label>Имя</Form.Label>
            <Controller
              control={control}
              name='name'
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Введите имя"
                    {...field}
                  />
                  {errors.name?.message &&
                    <Alert>{errors.name?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="otch">
            <Form.Label>Отчество</Form.Label>
            <Controller
              control={control}
              name='otch'
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Введите отчество"
                    {...field}
                  />
                  {errors.otch?.message &&
                    <Alert>{errors.otch?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="birthday">
            <Form.Label>День рождения</Form.Label>
            <Controller
              control={control}
              name="birthday"
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="date"
                    {...field}
                  />
                  {errors.birthday?.message &&
                    <Alert>{errors.birthday?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="position">
            <Form.Label>Должность</Form.Label>
            <Controller
              control={control}
              name="position"
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Введите должность"
                    {...field}
                  />
                  {errors.position?.message &&
                    <Alert>{errors.position?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="login">
            <Form.Label>Логин</Form.Label>
            <Controller
              control={control}
              name='login'
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="text"
                    placeholder="Введите логин"
                    {...field}
                  />
                  {errors.login?.message &&
                    <Alert>{errors.login?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>E-mail</Form.Label>
            <Controller
              control={control}
              name='email'
              render={({ field }) =>
                <>
                  <Form.Control
                    autoFocus
                    type="email"
                    placeholder="Введите Email"
                    {...field}
                  />
                  {errors.email?.message &&
                    <Alert>{errors.email?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Пароль</Form.Label>
            <Controller
              control={control}
              name='password'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="password"
                    placeholder="Введите пароль"
                    {...field}
                  />
                  {errors.password?.message &&
                    <Alert>{errors.password?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Form.Group controlId="password2">
            <Form.Label>Пароль еще раз</Form.Label>
            <Controller
              control={control}
              name='passwordTwo'
              render={({ field }) =>
                <>
                  <Form.Control
                    type="password"
                    placeholder="Введите пароль еще раз"
                    {...field}
                  />
                  {errors.passwordTwo?.message &&
                    <Alert>{errors.passwordTwo?.message}</Alert>
                  }
                </>
              }
            />
          </Form.Group>
          <Row className="pt-1 pb-1 mt-2">
            <Col>
              <Button
                style={{ width: "inherit" }}
                size={"lg"}
                variant={"success"}
                type="submit"
                disabled={!isDirty || Boolean(Object.keys(errors).length)}
              >
                Зарегистрироваться
              </Button>
            </Col>
          </Row>
          <Row className="pt-1 pb-1">
            <Col>
              <Button
                style={{ width: "inherit" }}
                size={"lg"}
                variant={"primary"}
                onClick={() => history.push("/login")}
              >
                У меня уже есть логин и пароль
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

export default RegistrationForm;
