import { autorun } from "mobx";
import { useEffect, useState } from "react";
import LocalizedStrings from "react-localization";
import localization from "../localization";
import { Localization } from "../localization/en";
import RootStore from "../store/RootStore";

const useStrings = (store: RootStore): Localization => {
  const lang = store.lang;

  // Hack to rerender component, on language change
  const [_, rerender] = useState(0);
  const [strings] = useState(
    () => new LocalizedStrings<Localization>(localization)
  );

  useEffect(() => {
    return autorun(() => {
      strings.setLanguage(lang.currentLang.code);
      rerender((v) => v + 1);
    });
  }, []);

  return strings;
};

export default useStrings;
