import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import ToastStore from "./ToastStore";

export function setupToastStore(container: Container): Container {
  container
    .bind<ToastStore>(diConstants.TOASTS)
    .to(ToastStore)
    .inSingletonScope();

  return container;
}
