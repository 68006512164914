import React from "react";
import { Modal, Button } from "react-bootstrap";
import useStrings from "../../hooks/useStrings";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";

type AppModalProps = {
  show: boolean;
  onHide: () => void;
  title: string;
  BodyComponent: React.FC;
};

function AppModal({ show, onHide, title, BodyComponent }: AppModalProps) {
  const store = useStore();
  const strings = useStrings(store);
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <BodyComponent />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>{strings.general.close}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default observer(AppModal);
