/* eslint-disable @typescript-eslint/ban-ts-comment */
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { makeAutoObservable, flow, when } from "mobx";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { Modal, Searcher } from "../../utils/helpers";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { ToastStore } from "../errors";
import { Optional, UUID } from "../../types";
import { Container } from "@owja/ioc";
import * as yup from 'yup';
import WsClient, { availableMessageType, SetTaskStarted } from "../../features/websocket/ws-client";
import { injectWebSocketClient } from "../../features/websocket/inject-ws-client";
import { rootStore } from "../../providers/StoreContext";

export function setupTasksStoreDI(container: Container): Container {
  container.bind<Task>(diConstants.TASKS.TASKS).to(Task);
  container
    .bind<TasksStore>(diConstants.TASKS.TASKS_STORE)
    .to(TasksStore)
    .inSingletonScope();

  return container;
}

export interface ITask {
  count_povtor: number,
  first_start_task: Date,
  last_date_finish_work: Date | null,
  last_start_task: Date | null,
  robotvcode: string,
  machine_name: string,
  id: string,
  taskName: string,
  last_work_status: string,
  robotname: string,
  task_time_interval: Date,
  count_works: number,
  inv_year: number,
  inv_month: number,
  inv_day: number,
  inv_hour: number,
  inv_minute: number,
  inv_sec: number
}

export class Task implements ITask {
  id: ITask['id'] = '';
  taskName: ITask['taskName'] = '';
  robotname: ITask['robotname'] = '';
  robotvcode: ITask['robotvcode'] = '';
  machine_name: ITask['machine_name'] = '';
  count_povtor: ITask['count_povtor'] = 0;
  first_start_task: ITask['first_start_task'] = new Date();
  last_date_finish_work: ITask['last_date_finish_work'] = null;
  last_start_task: ITask['last_start_task'] = null;
  last_work_status: ITask['last_work_status'] = '';
  task_time_interval: ITask['task_time_interval'] = new Date();
  count_works: ITask['count_works'] = 0;
  inv_year: ITask['inv_year'] = 0;
  inv_month: ITask['inv_month'] = 0;
  inv_day: ITask['inv_day'] = 0;
  inv_hour: ITask['inv_hour'] = 0;
  inv_minute: ITask['inv_minute'] = 0;
  inv_sec: ITask['inv_sec'] = 0;
  constructor() {
    makeAutoObservable(this)
  }

  fromJSON(json: ITask): Task {
    this.id = json.id;
    this.taskName = json.taskName;
    this.robotname = json.robotname;
    this.robotvcode = json.robotvcode;
    this.machine_name = json.machine_name;
    this.count_povtor = json.count_povtor;
    this.first_start_task = json.first_start_task;
    this.last_date_finish_work = json.last_date_finish_work;
    this.last_start_task = json.last_start_task;
    this.last_work_status = json.last_work_status;
    this.task_time_interval = json.task_time_interval;
    this.count_works = json.count_works;
    this.inv_year = json.inv_year;
    this.inv_month = json.inv_month;
    this.inv_day = json.inv_day;
    this.inv_hour = json.inv_hour;
    this.inv_minute = json.inv_minute;
    this.inv_sec = json.inv_sec;
    return this;
  }
}

export default class TasksStore {
  public readonly toasts!: ToastStore;
  ws: WsClient = injectWebSocketClient();
  state: LoadStatesType = LoadStates.INITIAL;
  tasks: Task[] = [];

  // eslint-disable-next-line
  errors: any[] = [];

  subscribeWsMessages() {
    // eslint-disable-next-line
    this.ws.subscribeOnMessage((ev: MessageEvent<any>) => {
      const message = JSON.parse(ev.data) as availableMessageType;
      switch (message.event) {
        case 'SET_TASK_STARTED': {
          const msg = message as SetTaskStarted;
          const {
            id_task,
          } = msg.data;

          rootStore.infoRobots._psuhInfo({
            ...msg.data,
            isActive: true,
            detail: []
          })

          const task = this._getTaskById(id_task);

          if (task) rootStore.toasts.show(
            "Задача " + task.taskName + " выполняется"
          );
          break;
        }
        case 'SET_TASK_STOPED': {
          const msg = message as SetTaskStarted;
          const {
            id_task,
            id
          } = msg.data;
          const infoRobot = rootStore.infoRobots.info_robots.find((ir) =>
            ir.id == id
          )
          if (infoRobot) {
            Object.assign(infoRobot, msg.data);
            infoRobot.isActive = false;
          }
          const task = this._getTaskById(id_task);

          if (task) rootStore.toasts.show(
            "Задача " + task.taskName + " остановлена"
          );

          break;
        }
      }
    })
  }

  constructor() {
    makeAutoObservable(this, {
      toasts: false
    });
    wire(this, "toasts", diConstants.TOASTS);
    this.subscribeWsMessages();

    this.refresh = this.refresh.bind(this);
    this.watchTask = this.watchTask.bind(this);
  }
  _currentTask: Optional<Task> = null;

  _searchTerm = '';
  get searchTerm() {
    return this._searchTerm;
  }

  set searchTerm(term: string) {
    this._searchTerm = term;
  }

  get currentTask() {
    return this._currentTask;
  }

  set currentTask(task: Optional<Task>) {
    this._currentTask = task;
  }

  taskSearcher = new Searcher(() => this.tasks)

  // модалки
  watchModal = new Modal()
  createTaskModal = new Modal()

  // показать модалки
  async watchTask(taskId: string) {
    when(
      () => this.state === LoadStates.COMPLETED,
      async () => {
        const task = this.tasks.find((task) =>
          task.id === taskId
        )
        if (!task) {
          this.watchModal.close();
          this.toasts.addError(
            new Error('Такой задачи не существует')
          );
          return;
        }
        this.currentTask = task;
        this.watchModal.open();
      }
    )
  }
  beginCreateTask() {
    this.createTaskModal.open();
  }

  get isLoading() {
    return this.state === LoadStates.LOADING;
  }

  get isError() {
    return this.state === LoadStates.FAILED;
  }

  refresh() {
    this.getTasks()
  }

  getTasks = flow(function* (this: TasksStore) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const tasks: Task[] = yield http.get<Task[]>("/api/tasks");
      this.tasks = [];
      tasks.forEach((task) => {
        this.tasks.push(
          new Task().fromJSON(task)
        );
      });

      this.onSuccess();
    } catch (err) {
      this.onError(err);
    }
  });


  deleteTask = flow(function* (this: TasksStore, taskId: string) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      yield http.delete(`/api/tasks/${taskId}`)

      this.tasks = [...this.tasks.filter((task) =>
        taskId !== task.id
      )]
      this.toasts.show('Задача успешно удалена');
      this.watchModal.close();
      this.onSuccess();
    } catch (err) {
      this.onError(err);
    }
  });

  updateTask = flow(function* (
    this: TasksStore,
    body: UpdateTaskType
  ) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      const updatedTask: Task = yield http.put('api/tasks', body);
      if (updatedTask) {
        const oldTask = this.tasks.find((taks) =>
          taks.id == body._task_id
        );
        if (oldTask) {
          Object.assign(oldTask, updatedTask);
          this.updateStateTask(oldTask);
        }
        this.state = LoadStates.COMPLETED;
        this.toasts.show('Информация сохранена');
        this.watchModal.close();
      }
    } catch (err) {
      this.onError(err)
    }
  })

  createTask = flow(function* (
    this: TasksStore,
    task: CreateTaskType
  ) {
    this.onBegin();
    const http = injectHttpClient();
    try {
      const result = yield http.post('api/tasks', task)
      if (result) {
        const newTask: Task = result;
        this.tasks = [...this.tasks, new Task().fromJSON(newTask)]
        this.state = LoadStates.COMPLETED;
        this.toasts.show('Информация сохранена');
        this.createTaskModal.close();
      }
    } catch (err) {
      this.onError(err);
    }
  })

  private updateStateTask = (oldTask: Task) => {
    const newTask = new Task();
    newTask.fromJSON(oldTask);
    this.tasks = [...this.tasks.filter((task) =>
      task.id !== oldTask.id
    ), newTask]
  }

  _getTaskById(taskId: UUID) {
    return this.tasks.find((taks) =>
      taks.id == taskId
    )
  }

  onBegin() {
    this.state = LoadStates.LOADING;
  }

  onSuccess() {
    this.state = LoadStates.COMPLETED;
  }

  onError(err: unknown) {
    console.error(err);
    this.errors.push(err);
    this.state = LoadStates.FAILED;
    this.toasts.addError(err);
  }
}

export interface CreateTaskType {
  _interval: string;
  _start_date: string;
  _task_name: string;
  _robot_guid: string;
  _count_povtor: string;
  _machine_name: string;
  inv_year: string;
  inv_month: string;
  inv_day: string;
  inv_hour: string;
  inv_minute: string;
  inv_sec: string;
}

export interface UpdateTaskType extends CreateTaskType {
  _task_id: string;
}

export const taskValidationSchema = yup.object().shape({
  _interval: yup.string().required('Обязательное поле'), // todo lang
  _task_name: yup.string().required('Обязательное поле'),

  _start_date: yup
    .date()
    .typeError('Некорректная дата')
    .default(() => new Date())
    .required('Обязательное поле'),

  _robot_guid: yup
    .string()
    .required('Обязательное поле'),

  _task_id: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .uuid()
    .optional(),

  _count_povtor: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .required('Обязательное поле'),

  _machine_name: yup
    .string()
    .required('Обязательное поле'),

  inv_year: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
  inv_month: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
  inv_day: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
  inv_hour: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
  inv_minute: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
  inv_sec: yup
    .string()
    .matches(/^\d+$/, {
      message: 'Некорректное значение'
    })
    .default('0'),
});