import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import Group from "./group";
import GroupUser from "./group-user";
import GroupsStore from "./groups-store";

export function setupGroupsStoreDI(container: Container): Container {
  container.bind<GroupUser>(diConstants.GROUPS.GROUP_USER).to(GroupUser);
  container.bind<Group>(diConstants.GROUPS.GROUP).to(Group);
  container
    .bind<GroupsStore>(diConstants.GROUPS.GROUP_STORE)
    .to(GroupsStore)
    .inSingletonScope();
  return container;
}
