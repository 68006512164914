import { Optional } from "../types";

const HOSTNAME = 'localhost:3030';

const wsProtocol = (): Optional<string> =>
  window.location.origin.split('://')[0] == 'https'
    ? 'wss'
    : 'ws'

const sslDevelopment = false

const isProduction = process.env.NODE_ENV == 'production';

const configApp = {
  apiUrl: isProduction
    ? window.location.origin
    : (sslDevelopment ? 'https' : 'http') + '://' + HOSTNAME,

  wsUrl: isProduction
    ? wsProtocol() + '://' + window.location.host
    : (sslDevelopment ? 'wss' : 'ws') + '://' + HOSTNAME
  ,
};

export default configApp;
