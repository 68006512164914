import React, { useEffect } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import "./LoginForm.css";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";
import { useForm, Controller, FieldErrors } from "react-hook-form";

interface UpdateLicenseForm {
  license: string;
}

function UpdateLicense() {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      license: "",
    },
  });
  const store = useStore();
  const onValidSubmit = (data: UpdateLicenseForm) => {
    store.appLicense.updateLicense(data.license);
  };
  const onInvalidSubmit = (errors: FieldErrors<UpdateLicenseForm>) => {
    if (errors.license?.type === "required") {
      store.alert.error("Укажите лицензионный ключ");
    }
    errors.license?.ref?.focus?.();
  };

  return (
    <Form onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}>
      <Controller
        control={control}
        name="license"
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <Form.Group controlId="login">
            <Form.Label>Введите лицензионный ключ</Form.Label>
            <Form.Control autoFocus {...field} />
          </Form.Group>
        )}
      />

      <Row className="pt-1 pb-1 mt-2">
        <Col>
          <Button
            style={{ width: "inherit" }}
            size={"lg"}
            variant={"success"}
            type="submit"
          >
            Обновить лицензию
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

type LoginFields = {
  login: string;
  password: string;
};

const LoginFormComponent = observer(function LoginFormComponent() {
  const history = useHistory();
  const location = useLocation<{ from?: { pathname: string } }>();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      login: "",
      password: "",
    },
    mode: "onSubmit",
  });
  const store = useStore();

  useEffect(() => {
    if (store.auth.isAuthorized) {
      history.push({
        pathname: location.state?.from?.pathname ?? "/",
      });
    }
  }, [store.auth.isAuthorized]);

  const onValidSubmit = (data: LoginFields) => {
    store.auth.setAuthInfo(data.login, data.password);
  };
  const onInvalidSubmit = (errors?: FieldErrors<LoginFields>) => {
    if (errors?.login && errors?.password) {
      if (
        errors.login.type === "required" &&
        errors.password.type === "required"
      ) {
        store.alert.error("Укажите логин и пароль");
        errors.login.ref?.focus?.();
      }
      return;
    }
    if (errors?.login) {
      if (errors.login.type === "required") {
        store.alert.error("Укажите логин");
        errors.login.ref?.focus?.();
      }
      return;
    }
    if (errors?.password) {
      if (errors.password.type === "required") {
        store.alert.error("Укажите пароль");
        errors.password.ref?.focus?.();
      }
      return;
    }
  };

  return (
    <Form onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}>
      <Controller
        control={control}
        name="login"
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <Form.Group controlId="login">
            <Form.Label>Логин</Form.Label>
            <Form.Control autoFocus type="login" {...field} />
          </Form.Group>
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{
          required: true,
        }}
        render={({ field }) => {
          return (
            <Form.Group controlId="password">
              <Form.Label>Пароль</Form.Label>
              <Form.Control type="password" {...field} />
            </Form.Group>
          );
        }}
      />
      <Row className="pt-1 pb-1 mt-2">
        <Col>
          <Button
            style={{ width: "inherit" }}
            size={"lg"}
            variant={"success"}
            type="submit"
          >
            Войти
          </Button>
        </Col>
      </Row>
      <Row className="pt-1 pb-1">
        <Col>
          <Button
            size={"lg"}
            variant={"primary"}
            onClick={() => history.push("/registration")}
          >
            У меня еще нет логина и пароля
          </Button>
        </Col>
      </Row>
      <Row className="pt-1 pb-1">
        <Col>
          <Button
            size={"lg"}
            variant={"primary"}
            onClick={() => history.push("/recovery_password")}
          >
            Я забыл пароль от аккаунта
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

function LoginForm() {
  const store = useStore();
  const appLicense = store.appLicense.isLicenseActive;

  if (store.auth.isCheckingAuth || store.auth.isAuthorizing) {
    return (
      <div
        style={{
          position: "absolute",
          inset: 0,
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" style={{ margin: "auto" }} />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="Login">
        <img
          src="/imgLogin.png"
          style={{
            maxWidth: "30%",
          }}
        />
        {appLicense === 'ACTIVE' ? <LoginFormComponent /> : <UpdateLicense />}
      </div>
    </>
  );
}

export default observer(LoginForm);
