import React, { useEffect, useState, useMemo } from "react";
import { Container, Col, Row, Spinner, Form } from "react-bootstrap";
//import AppStackedBarChart from "../../components/Charts/AppStackedBarChart/AppStackedBarChart"; todo
import ComputerIcon from "@mui/icons-material/Computer";
import useStrings from "../../hooks/useStrings";
import AppModal from "../../components/AppModal/AppModal";
import { useStore } from "../../providers/StoreContext";
//import { injectHttpClient } from "../../features/requests/inject-http-client"; todo
import { splitIntoChunks } from "../../utils";
import { IClient } from "../../store";
import { observer } from "mobx-react-lite";

interface ClientProps {
  name: string;
  state: boolean;
  onClick: () => void;
}

function Client({ name, state, onClick }: ClientProps) {
  return (
    <Col>
      <Container
        className="d-flex flex-column align-items-center"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <Row>
          <Col>
            <ComputerIcon
              fontSize={"large"}
              color={state ? "success" : "action"}
            />
          </Col>
        </Row>
        <Row>
          <Col>{name}</Col>
        </Row>
      </Container>
    </Col>
  );
}

interface IClients2 {
  clients: IClient[];
  openModal: (id_client: string | number) => void;
}

function Clients({ clients, openModal }: IClients2) {
  const store = useStore();
  const { clients: cls, general } = useStrings(store);

  const chunks = useMemo(() => {
    return splitIntoChunks(clients ?? [], 4);
  }, [clients]);

  return clients.length ? (
    <Container>
      {chunks.map((clMass, index) => {
        return (
          <Row className="mt-2" key={index}>
            {clMass[0] ? (
              <Client
                name={clMass[0].name}
                state={clMass[0].state!}
                onClick={() => openModal(clMass[0].id)}
              />
            ) : (
              <Col />
            )}
            {clMass[1] ? (
              <Client
                name={clMass[1].name}
                state={clMass[1].state!}
                onClick={() => openModal(clMass[1].id)}
              />
            ) : (
              <Col />
            )}
            {clMass[2] ? (
              <Client
                name={clMass[2].name}
                state={clMass[2].state!}
                onClick={() => openModal(clMass[2].id)}
              />
            ) : (
              <Col />
            )}
            {clMass[3] ? (
              <Client
                name={clMass[3].name}
                state={clMass[3].state!}
                onClick={() => openModal(clMass[3].id)}
              />
            ) : (
              <Col />
            )}
          </Row>
        );
      })}
    </Container>
  ) : (
    <Container className="d-flex flex-column align-items-center mt-5">
      <Row>
        <Col>
          <h5>{cls.noRegisteredCustomersYet}</h5>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h5>{general.searching}</h5>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Spinner animation="grow" style={{ margin: "auto" }} />
        </Col>
      </Row>
    </Container>
  );
}

function ClientsPage() {
  const store = useStore();
  const {
    clients: text
  } = useStrings(store);
  const clients: IClient[] = store.clients.clients;
  const { general, clients: clientsStrings } = useStrings(store);

  // type Statistic = unknown;

  //const [statisticsData, setStatisticsData] = useState<Statistic[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [clientModal, setClientModal] = useState<IClient | null>(null);
  const activeCount = clients.filter((c) => c.state).length;
  const clientCount = clients.length;


  useEffect(() => {
    // getClientRunStatistics(); todo выдернул статистику
    store.clients.getAllClients();
  }, []);

  // const getClientRunStatistics = async () => {
  //   const http = injectHttpClient();
  //   const response = await http.get<Statistic[]>(
  //     "/api/statistics/clientRunStatistics"
  //   );
  //   setStatisticsData(response);
  // };

  const openModal = (id_client: string | number): void => {
    const currentClient: IClient | undefined = clients.find(
      (c: IClient) => c.id === id_client
    );
    if (currentClient) {
      setClientModal(currentClient);
      setTitleModal(`${clientsStrings.client} ${currentClient.name}`);
      setShowModal(true);
    }
  };

  const BodyComponent: React.FC = () => (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextId">
        <Form.Label column sm="4">
          {general.id}
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" disabled={true} value={clientModal?.id} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextId">
        <Form.Label column sm="4">
          {general.name}
        </Form.Label>
        <Col sm="8">
          <Form.Control type="text" disabled={true} value={clientModal?.name} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextId">
        <Form.Label column sm="4">
          {general.state}
        </Form.Label>
        <Col sm="8">
          <Form.Control
            type="text"
            disabled={true}
            value={clientModal?.state ? general.active : general.inactive}
          />
        </Col>
      </Form.Group>
    </Form>
  );
  return (
    <>
      <AppModal
        show={showModal}
        onHide={() => setShowModal(false)}
        title={titleModal}
        BodyComponent={BodyComponent}
      />
      <Container className="mainContainer" fluid>
        <Row className="h-100 pt-3">
          <Col sm={4}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm="6">
                {text.activeNow}
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  disabled={true}
                  value={`${activeCount} / ${clientCount}`}
                />
              </Col>
            </Form.Group>
            <Row className="h-50">
              {/* <AppStackedBarChart
                data={statisticsData}
                dataKeyXAxis="name"
                labelYAxis={text.startCount}
                labelXAxis={text.date}
                dataBars={[
                  { name: messages.success, key: "success", color: "#00C49F" },
                  { name: messages.error, key: "error", color: "#FF8042" },
                ]}
              /> */}
            </Row>

          </Col>
          <Col>
            <Clients clients={clients} openModal={openModal} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default observer(ClientsPage);
