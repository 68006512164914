import React from "react";
import { Toast } from "react-bootstrap";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { observer } from "mobx-react-lite";
import { Notice } from "../../../store/notifications/type";
import { useStore } from "../../../providers/StoreContext";

type NotificationToastProps = {
  notice: Notice;
};

function NotificationToast({
  notice: { id, title, msg, cdate },
}: NotificationToastProps) {
  const store = useStore();
  const onCloseNotifiation = () => {
    store.notifications.deleteNotification(id);
  };

  return (
    <Toast
      style={{ margin: "10px 0" }}
      onClose={onCloseNotifiation}
      animation={true}
    >
      <Toast.Header>
        <NotificationsNoneIcon className="rounded me-2" />
        <strong className="mr-auto">{title}</strong>
        <small>{cdate}</small>
      </Toast.Header>
      <Toast.Body>{msg}</Toast.Body>
    </Toast>
  );
}

export default observer(NotificationToast);
