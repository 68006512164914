import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, ButtonGroup, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CenterSpinner from '../../../components/Spinner/Spinner';
import VirtualScroll from '../../../components/VirtualScroll/VirtualScroll';
import UserLogo from '../../../icons/User.png';
import { useStore } from '../../../providers/StoreContext';
import { IUser } from '../../../store';
import { IRoleModel } from '../../../store/roles';
import { RoleList } from './modals/components/RoleList';
import CreateUserModal from './modals/CreateUser/CreateUserModal';
import WatchAndEditUserModal from './modals/WatchEditUser/WatchAndEditUserModal';
import RefreshIcon from '../../../icons/Refresh.png';


const ManageUsers = () => {
  const { users } = useStore();
  const {
    search,
    addRole,
    deleteRole,
    banUser,
    watchUser,
    refresh,
    isLoading,
    selectedUser,
    selectedUserInfo,
    watchAndEditUserModal,
    createUserModal,
    listBySearch,
    searchTerm,
  } = users;

  return (
    <>
      <CreateUserModal
        show={createUserModal.show}
        onHide={() => createUserModal.close()}
      />
      {selectedUser &&
        selectedUserInfo &&
        <WatchAndEditUserModal
          show={watchAndEditUserModal.show}
          onHide={() => watchAndEditUserModal.close()}
          currentUser={selectedUser}
        />
      }
      <CenterSpinner
        isLoad={isLoading}
      />
      <Row className="mx-3 justify-content-md-between">
        <Col md="auto">
          <Form.Group as={Row}>
            <Form.Label column>
              {'Всего пользователей' + ': ' + listBySearch.length}
            </Form.Label>
            <Col>
              <InputGroup>
                <InputGroup.Text>
                  Поиск
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Искать"
                  onChange={(e) =>
                    search(e.currentTarget.value)
                  }
                  value={searchTerm}
                />
              </InputGroup>
            </Col>
            <Col>
              <Button
                variant='outline-secondary'
                onClick={() => createUserModal.open()}
              >
                Создать пользователя
              </Button>
            </Col>
          </Form.Group>
        </Col>
        <Col sm={1}>
          <img
            src={RefreshIcon}
            className='refresh_btn'
            onClick={() => refresh()}
          />
        </Col>
      </Row>
      <div className='userList'>
        <VirtualScroll
          data={listBySearch}
          rowHeight={65}
          visibleRows={5}
          render={(user: IUser) => {
            return (
              <UserItem
                user={user}
                key={user.id}
                handleBan={banUser}
                handleWatch={watchUser}
                addRole={addRole}
                deleteRole={deleteRole}
              />
            )
          }}
        />
      </div>
    </>
  )
}

interface UserItemProps {
  user: IUser,
  handleBan: (
    userId: string,
    value: boolean
  ) => void
  handleWatch: (user: IUser) => void
  addRole: (
    userId: string,
    role: IRoleModel
  ) => void
  deleteRole: (
    userId: string,
    role: IRoleModel
  ) => void
}

const UserItem = observer((props: UserItemProps) => {
  const { user, handleBan, handleWatch, deleteRole, addRole } = props;
  const [banned, setBanned] = useState<boolean>(user.banned);

  const toggle = (value: boolean) => {
    setBanned(value);
    handleBan(user.id, value);
  }

  const watch = (e: React.MouseEvent) => {
    e.stopPropagation()
    handleWatch(user);
  }

  const onDeleteRole = (role: IRoleModel) => deleteRole(user.id, role);
  const onAddRole = (role: IRoleModel) => addRole(user.id, role);

  const [isHover, setIsHover] = useState(false);
  const onHover = () => setIsHover(true);
  const onHoverOut = () => setIsHover(false);

  return (
    <div className='user'
      onClick={watch}
      onMouseOver={onHover}
      onMouseLeave={onHoverOut}
    >
      <img src={UserLogo} alt='' className='user__img' />
      <div className='user__login'>
        <span>{user.login}</span>
      </div>
      <div className='user__fio'>
        <span>{`${user.name} ${user.fam} ${user.otch}`}</span>
      </div>
      <div className='user__position'>
        <span>{user.position}</span>
      </div>
      <div className='user__roles'>
        <RoleList
          userId={user.id}
          roles={user.roles}
          isHover={isHover}
          addRole={onAddRole}
          deleteRole={onDeleteRole}
        />
      </div>
      <div className='user__banned'>
        <ToggleSwitch
          id={user.id}
          value={banned}
          setValue={toggle}
        />
      </div>
    </div>
  )
})

export default observer(ManageUsers);

interface ISwitchProps {
  id: string,
  value: boolean,
  setValue: (bool: boolean) => void
}

const ToggleSwitch = ({ id, value, setValue }: ISwitchProps) => {
  const handleSwitch = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    const target = Boolean(Number(e.currentTarget.value));
    if (target !== value) {
      setValue(target);
    }
  }
  return (
    <ButtonGroup key={id + 'btnGroup'} size='sm'>
      {[false, true].map((bool) => {
        return (
          <Button
            value={Number(bool)}
            onClick={handleSwitch}
            key={`${id}-${bool}`}
            id={`${id}-${bool}`}
            variant={!bool
              ? value == bool
                ? 'success'
                : 'outline-success'
              : value == bool
                ? 'danger'
                : 'outline-danger'
            }
          >
            {bool
              ? 'Banned'
              : 'Not banned'
            }
          </Button>
        )
      })}
    </ButtonGroup>
  )
}