import { makeAutoObservable } from "mobx";
import { Optional } from "../../types";

export interface INotification {
  id: number;
  title: Optional<string>;
  msg: Optional<string>;
  sender: Optional<string>;
  receiver: Optional<string>;
  cdate?: string;
}

export default class Notification implements INotification {
  id: INotification["id"] = 0;
  title: INotification["title"] = null;
  msg: INotification["msg"] = null;
  sender: INotification["sender"] = null;
  receiver: INotification["receiver"] = null;
  cdate: INotification["cdate"] = "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: INotification): Notification {
    this.id = json.id;
    this.title = json.title;
    this.msg = json.msg;
    this.sender = json.sender;
    this.receiver = json.receiver;
    this.cdate = json.cdate;
    return this;
  }
}
