const en = {
  appNavBar: {
    dashboard: "Dashboard",
    robots: "Robots",
    clients: "Clients",
    docs: "Documentations",
    publicRobots: "Public robots",
    activeRobots: "Active robots",
    logs: "Logging log",
    groups: "Groups",
    lk: "Private area",
    admin: "Admin panel",
    exit: "Exit",
    notifications: "Notifications",
    tasks: 'Tasks'
  },
  notice: {
    notifications: "Notifications",
    noNotifications: "You have no notifications yet...",
  },
  globalVariable: {
    nameVar: "Variable name",
    valueVar: "Variable value",
  },
  settingPage: {
    info: "There will be some general settings here",
    generalSettings: "General settings",
    globalVariable: "Global variable",
  },
  personalAccount: {
    generalInfo: "General info",
    rights: "Rights",
    editingRights: "Editing rights",
    sendingNotifications: "Sending notifications",
    licenseManagement: "License management",
    demoLicenseManagement: "Demo-license management",
    licenseKey: "License key",
    allowEditing: "Allow editing",
    uploadRights: "Upload rights",
    beginningOfTheLicense: "Beginning of the license",
    licenseExpiration: "License expiration",
    upgradeLicense: "Upgrade license",
    customerKey: "Customer key",
    numberOfPublicationsAvailable: "Number of publications available",
    updateStudio: "Update Lexema RPA Studio",
  },
  clients: {
    noRegisteredCustomersYet: "No registered customers yet",
    client: "Client",
    activeNow: "Active now",
    startCount: "Start count",
    date: "Date"
  },
  robots: {
    robot: "Robot",
    descriptionOfTheRobot: "Description of the robot",
    info: "A short description of the robot or some information",
    info2: "More information about the robot",
    currentProgress: "Current progress",
    clicker: "There is a clicker",
    machineName: "Machine name",
    completedSuccessfully: "Completed successfully",
    additionalValuesAndErrors: "Additional values and errors",
    robotExecutionProcess: "Robot execution process",
    completedWithAnError: "Completed with an error",
    activeNow: "Active now",
    successfullyEnded: "Successfully completed",
    watchStatistics: "Watch statistics",
    robots: "Robots",
    modules: "Modules",
    seacrh: "Search",
    notAvailableRobots: "There are not robots available",
    notAvailableModules: "There are not modules available",
    tryToLoad: "Try to load",
    working: "Working...",
    resting: "Resting",
    common: "Common",
    deleteRobot: "Delete robot",
    activity: "Activity",
    runningNow: "Running now",
    completed: "Completed",
    downloading: "Downloading",
    editing: "Editing",
    publication: "Publication",
    deleting: "Deleting",
    dateOfIssue: "Date of issue",
    accessHasNotBeenGrantedToAnyoneYet: "Access has not been granted to anyone yet",
    doYouWantDeleteAccess: "Do you really want to delete access?",
    grantAccess: "Grant access",
    loadError: "Something bad happened and the robot failed to load((",
    access: "Access",
    pass_owner: 'Pass owner',
    newOwner: 'New owner',
    choseUserOrGroup: 'Choose user or group',
    seacrByLoginOrGroupName: 'Search by login or group name',
    its_your_robot: 'This is your robot. You have the right to all actions with the robot',
    its_your_group_robot: 'You are the admin of the group that this robot belongs to. You have the right to all actions with the robot',
    you_have_access: 'You have been granted permissions for certain actions with this robot',
    your_group_have_access: 'The group you belong to has been granted permissions for certain actions with this robot',
    deletedTask: 'Deleted task',
    deletedClient: 'Deleted client'
  },
  forAlert: {
    informationSaved: "Information saved!",
    failedToSaveInformation: "Failed to save information!",
    welcome: "Welcome",
    licenseHasExpired: "The license has expired!",
    licenseHasExpiredServer: "Orchestrator Server License Expired!",
    contactYourSystemAdministrator: "contact your system administrator!",
    invalidUsernamePassword: "Invalid username / password",
    linkWasSuccessfullyCopiedToTheClipboard:
      "The link was successfully copied to the clipboard!",
    failedToCopyLinkToClipboard: "Failed to copy link to clipboard!",
    licenseRenewedSuccessfully: "License renewed successfully!",
    error: "Error",
    licenseHasBeenSuccessfullyCreated: "License has been successfully created!",
  },
  general: {
    search: "Search",
    delete: "Delete",
    version: "Version",
    upload: "Upload",
    notLimited: "Not limited",
    status: "Status",
    currentDate: "Current date",
    comment: "Comment",
    percent: "Percent",
    error: "Error",
    value: "Value",
    notFound: "Not found",
    download: "Download",
    owner: "Owner",
    id: "Id",
    create: "Create",
    save: "Save",
    close: "Close",
    add: "Add",
    actions: "Actions",
    surname: "Surname",
    name: "Name",
    patronymic: "Patronymic",
    birthday: "Birthday",
    position: "Position",
    actsWith: "Acts with",
    by: "by",
    allow: "Allow",
    selectUser: "Select user",
    whom: "Whom",
    caption: "Caption",
    message: "Message",
    send: "Send",
    user: "User",
    startDate: "Start date",
    expirationDate: "Expiration date",
    key: "Key",
    state: "State",
    place: "Place",
    type: "Type",
    level: "Level",
    date: "Date",
    host: "Host",
    entity: "Entity",
    searching: "Searching...",
    active: "Active",
    inactive: "Inactive",
    detailedInformation: "Detailed information",
    link: "Link",
    pid: "PID",
    timestamp: "Timestamp",
    class: "Class",
    handler: "Handler",
    stack: "Stack",
    username: "User",
    requestUuid: "RequestUuid",
    uuid: "UUID",
    method: "Method",
    url: "URL",
    userAgent: "userAgent",
    mscs: "mscs",
    login: "login",
    content_length: "Content length",
    invalidFormat: 'Invalid format',
    description: "Description"
  },
  graph: {
    notEnoughDataToBuildAGraph: "Not enough data to build a graph",
  },
  messages: {
    success: "Success",
    error: "Error",
    informationSaved: 'Information saved',
    informationHasNotSaved: 'Failed to save information'
  },
  tasks: {
    addTask: "Add Task",
    taskName: "Task name",
    robot: "Robot",
    selectRobot: "Select robot",
    selectClient: "Select client",
    firstStart: "First Start",
    repeats: "Repeats",
    starts: "Execution",
    startInterval: "Start Interval",
    status: "Status",
    rpaClient: "RPA Client",
    year: "Year",
    month: "Month",
    day: "Day",
    hour: "Hour",
    minute: "Minute",
    second: "Second"
  },
  validationAlerts: {}
};

export type Localization = typeof en;

export default en;
