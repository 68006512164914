export function searchIn(
  data: any[],
  searchTerm: string
) {
  if (!searchTerm.length)
    return data;

  return data.filter((d) => {
    const bools = Object.keys(d).map((key) => {
      if (typeof d[key] === 'string') {
        return d[key]
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      } else {
        return false
      }
    })
    return bools.includes(true);
  })
}

export function generatePassword() {
  const minLength = Number(process.env.PASSWORD_LENGTH_MIN) || 4;
  const maxLength = Number(process.env.PASSWORD_LENGTH_MAX) || 20;
  const resultlength = Math.floor(Math.random() * (maxLength - minLength + 1) + minLength);
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0, n = charset.length; i < resultlength; i++) {
    result += charset.charAt(Math.floor(Math.random() * n));
  }
  return result;
}