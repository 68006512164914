import { makeAutoObservable } from "mobx";

export interface RequestRobot {
  id: string;
  robotname: string;
  robot_owner: string;
  description: string;
  noclicker: number | boolean;
  remote_path: string;
  read: boolean;
  write: boolean;
  create: boolean;
  delete: boolean;
  info: string;
  ownership_type: string;
  owner_type: string;
  isModule: boolean;
  isEncrypted: boolean;
  isActive: boolean;
  iconBuffer?: string;
  darkIconBuffer?: string;
  wdate?: string;
}

export const MY_ROBOT = 'MY_ROBOT';
export const MY_GROUP_ROBOT = 'MY_GROUP_ROBOT';
export const ROBOT_ACCESSED_TO_ME = 'ROBOT_ACCESSED_TO_ME';
export const ROBOT_ACCESSED_TO_GROUP_WITH_ME = 'ROBOT_ACCESSED_TO_GROUP_WITH_ME';

export default class Robot implements RequestRobot {
  id = "";
  robotname = "";
  robot_owner = "";
  description = "";
  noclicker: number | boolean = false;
  remote_path = "";
  read = false;
  write = false;
  create = false;
  delete = false;
  info = '';
  isModule = false;
  isActive = false;
  isEncrypted = false;
  ownership_type = '';
  owner_type = '';
  wdate?= "";
  iconBuffer?= "";
  darkIconBuffer?= "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: RequestRobot): Robot {
    this.id = json.id;
    this.robotname = json.robotname;
    this.robot_owner = json.robot_owner;
    this.description = json.description;
    this.noclicker = json.noclicker;
    this.remote_path = json.remote_path;
    this.read = json.read;
    this.write = json.write;
    this.create = json.create;
    this.delete = json.delete;
    this.info = json.info;
    this.isModule = json.isModule
    this.isEncrypted = json.isEncrypted
    this.owner_type = json.owner_type;
    this.ownership_type = json.ownership_type;
    this.wdate = json.wdate;
    this.iconBuffer = json.iconBuffer;
    this.darkIconBuffer = json.darkIconBuffer;
    this.isActive = json.isActive;
    return this;
  }
}
