import { flow, makeAutoObservable } from "mobx";
import { wire } from "../../di/di";
import { diConstants } from "../../di/di.constants";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { LoadStates, LoadStatesType } from "../../types/load-states";
import { ToastStore } from "../errors";
import { GroupRoles, GroupRoleType } from "./type";

export interface IGroupUser {
  id: number;
  createdAt: string;
  updatedAt: string;
  role: GroupRoleType;
  group_id: string;
  user_id: string;
  direct_membership: boolean;
}


interface AddGroupUserRequestBody {
  user_id: string;
  role: GroupRoleType;
}

type ChangeUsersRoleRequestBody = Pick<AddGroupUserRequestBody, "role">;

export default class GroupUser implements IGroupUser {
  id: IGroupUser["id"] = 0;
  createdAt: IGroupUser["createdAt"] = "";
  updatedAt: IGroupUser["updatedAt"] = "";
  role: IGroupUser["role"] = GroupRoles.USER;
  group_id: IGroupUser["group_id"] = "";
  user_id: IGroupUser["user_id"] = "";
  direct_membership: IGroupUser["direct_membership"] = false;
  public readonly toasts!: ToastStore;
  state: LoadStatesType = LoadStates.INITIAL;

  constructor() {
    makeAutoObservable(this, {
      toasts: false
    });
    wire(this, "toasts", diConstants.TOASTS);
  }

  fromJSON(json: IGroupUser): GroupUser {
    this.id = json.id;
    this.role = json.role;
    this.user_id = json.user_id;
    this.group_id = json.group_id;
    this.createdAt = json.createdAt;
    this.updatedAt = json.updatedAt;

    return this;
  }

  get roleString() {
    if (this.role === GroupRoles.ADMIN) {
      return "Администратор"; // todo lang
    }
    if (this.role === GroupRoles.USER) {
      return "Обычный пользователь"; // todo lang
    }
    return "";
  }

  changeRole = flow(function* (
    this: GroupUser,
    role: ChangeUsersRoleRequestBody
  ) {
    try {
      const http = injectHttpClient();
      yield http.patch(
        `/api/groups/${this.group_id}/users/${this.user_id}`,
        role
      );
      this.role = role.role

      this.onSuccess();
    } catch (err) {
      this.onError(err);
    }
  });

  destroy = flow(function* (this: GroupUser) {
    try {
      this.onBegin();
      const http = injectHttpClient();
      yield http.delete(
        `/api/groups/${this.group_id}/users/${this.user_id}`
      );
      this.onSuccess();
    } catch (err) {
      this.onError(err);
    }
  });


  onBegin() {
    this.state = LoadStates.LOADING;
  }

  onSuccess() {
    this.state = LoadStates.COMPLETED;
  }

  onError(err: unknown) {
    console.error(err);
    this.state = LoadStates.FAILED;
    this.toasts.addError(err);
  }
}
