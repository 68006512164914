import en, { Localization } from "./en";
import ru from "./ru";

export type SupportedLanguages = "ru" | "en";

const localization: Record<SupportedLanguages, Localization> = {
  ru,
  en,
};

export default localization;
