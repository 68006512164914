import { makeAutoObservable, flow } from "mobx";
import qs from "qs";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import GlobalVariable, { IGlobalVariable } from "./global-variable";

class VarsStore {
  vars: IGlobalVariable[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getAllVars = flow(function* (this: VarsStore) {
    try {
      const http = injectHttpClient();
      const vars: IGlobalVariable[] = yield http.get<IGlobalVariable[]>(
        "/api/vars/all"
      );
      this.vars = [];
      vars.forEach((v) => {
        this.vars.push(new GlobalVariable().fromJSON(v));
      });
    } catch (err) {
      console.error(err);
    }
  });

  addVar = flow(function* (this: VarsStore, name: string, value: string) {
    try {
      const _data = { name, value };
      const data = qs.stringify(_data);
      const http = injectHttpClient();

      const varId: IGlobalVariable["id"] = yield http.post<
        typeof _data,
        IGlobalVariable["id"]
      >("/api/vars/add", _data);

      const newVar = new GlobalVariable().fromJSON({
        id: varId,
        name,
        value,
      });
      this.vars.push(newVar);
    } catch (err) {
      console.error(err);
    }
  });

  deleteVar = flow(function* (
    this: VarsStore,
    id: number | string | undefined
  ) {
    try {
      const _data = { id };
      const http = injectHttpClient();

      yield http.post("/api/vars/delete", _data);
      const i = this.vars.findIndex((v) => v.id === id);
      if (i >= 0) {
        this.vars.splice(i, 1);
      }
    } catch (err) {
      console.error(err);
    }
  });
}

export default VarsStore;
