import { Container } from "@owja/ioc";
import { diConstants } from "../../../di/di.constants";
import Log from "../log";
import LogsStore from "../sys-logs-store";

export function setupLogsStoreDI(container: Container): Container {
  container.bind<Log>(diConstants.LOG.LOG).to(Log);
  container
    .bind<LogsStore>(diConstants.LOG.LOGS_STORE)
    .to(LogsStore)
    .inSingletonScope();

  return container;
}
