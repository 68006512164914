import React from "react";
import { Alert, Fade } from "react-bootstrap";
import { useStore } from "../../providers/StoreContext";
import { observer } from "mobx-react-lite";

function AppToast() {
  const { toasts } = useStore();
  const {
    errors,
    messages,
    closeError,
    closeMessage
  } = toasts;

  if (toasts.isEmpty) {
    return null;
  }

  return (
    <>
      {
        messages.map((msg, index) =>
          <Alert
            key={index}
            variant={'info'}
            style={{ margin: 10 }}
            dismissible
            onClose={() => closeMessage(index)}
            transition={Fade}
          >
            {msg}
          </Alert>
        )
      }
      {
        errors.map((err, index) =>
          <Alert
            key={index}
            variant={'danger'}
            style={{ margin: 10 }}
            dismissible
            onClose={() => closeError(index)}
            transition={Fade}
          >
            {'Error ' + (err.response?.data?.message ?? err?.message ?? '')}
          </Alert>
        )
      }
    </>
  );
}

export default observer(AppToast);
