import React, { useState, useMemo } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import useInput from "../../hooks/useInput";
import useStrings from "../../hooks/useStrings";
import { useStore } from "../../providers/StoreContext";
import { IUser } from "../../store/users/type";

const SendNotifications: React.FC = () => {
  const store = useStore();
  const { general, personalAccount } = useStrings(store);
  const users = useMemo(() => {
    return Array.from(store.users.users.values());
  }, [store.users.users]);
  const title = useInput();
  const msg = useInput();
  const [currentId, setCurrentId] = useState("");

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const user = users.find((user: IUser) => user.id === currentId);
    if (user != null) {
      store.notifications.addNotification(title.value, msg.value, user.login);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextTitle">
        <Form.Label column sm="2">
          {general.whom}
        </Form.Label>
        <Col sm="10">
          <Form.Control
            as={"select"}
            value={currentId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setCurrentId(e.target.value)
            }
          >
            <option value={""}>{general.selectUser}</option>
            {users.map((user) => {
              return (
                <option value={user.id} key={user.id}>
                  {user.login}
                </option>
              );
            })}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextTitle">
        <Form.Label column sm="2">
          {general.caption}
        </Form.Label>
        <Col sm="10">
          <Form.Control type="text" {...title} />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="formPlaintextMsg">
        <Form.Label column sm="2">
          {general.message}
        </Form.Label>
        <Col sm="10">
          <Form.Control type="text" {...msg} />
        </Col>
      </Form.Group>
      <Row>
        <Col sm="10"></Col>
        <Col sm="2">
          <Button variant="success" type="submit">
            {general.send}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SendNotifications;
