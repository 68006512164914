import { makeAutoObservable, flow } from "mobx";
import { injectHttpClient } from "../../features/requests/inject-http-client";
import { injectWebSocketClient } from "../../features/websocket/inject-ws-client";
import WsClient, { availableMessageType, SetInfoAbouTask } from "../../features/websocket/ws-client";
import { UUID } from "../../types";
import { LoadStatesType } from "../../types/load-states";
import InfoRobot, { IInfoRobot } from "./info-robot";
import InfoRobotDetail from "./info-robot-detail";

export default class InfoRobotsStore {
  state: LoadStatesType = 'INITIAL'
  ws: WsClient = injectWebSocketClient();
  info_robots: InfoRobot[] = [];
  constructor() {
    this.subscribeWsMessages();
    makeAutoObservable(this, {
      http: false,
    });
  }

  get completed() {
    return this.info_robots.filter((ir) => ir.status)
  }
  get active() {
    return this.info_robots.filter((ir) => ir.isActive)
  }

  subscribeWsMessages() {
    // eslint-disable-next-line
    this.ws.subscribeOnMessage((ev: MessageEvent<any>) => {
      const message = JSON.parse(ev.data) as availableMessageType;
      switch (message.event) {
        case 'SET_INFO_ABOUT_TASK': {
          const msg = message as SetInfoAbouTask;
          const { id_info_robots } = msg.data;
          const infoRobot = this._getInfoRobotById(id_info_robots)
          if (infoRobot) {
            infoRobot.addDetail(new InfoRobotDetail().fromJSON(msg.data))
          }
          break;
        }
      }
    })
  }

  get http() {
    return injectHttpClient();
  }

  _getInfoRobotById(id: UUID) {
    return this.info_robots.find((ir) =>
      ir.id == id
    )
  }

  loadInfoRobots = flow(function* (
    this: InfoRobotsStore,
    robotId: string
  ) {
    this.state = 'LOADING';
    try {
      const response: IInfoRobot[] = yield this.http.get(`/api/robots/${robotId}/info`);
      this.info_robots = [];

      response.forEach(async (robot) => {
        const infoRobot = await new InfoRobot().fromJSON(robot);

        this.info_robots.push(infoRobot);
      });
      this.state = 'COMPLETED'
    } catch (error) {
      this.state = 'FAILED'
      console.error(error);
    }
  });

  _updateStateInfoRobot(
    id_info_robot: UUID,
    state: boolean
  ) {
    const targetInfoRobot = this._getInfoRobotById(id_info_robot);
    if (!targetInfoRobot) return;
    targetInfoRobot.isActive = state;
    // просто обновляем ссылки, чтобы была перерисовка
    this.info_robots = [
      ...this.info_robots.filter((ir) =>
        ir.id != id_info_robot
      ),
      targetInfoRobot
    ]
  }

  async _psuhInfo(info: IInfoRobot) {
    const newInfo = await new InfoRobot().fromJSON(info)
    this.info_robots.push(newInfo);
    // костыль, обноляем сслыку, чтобы перерисовать
    this.info_robots = [
      ...this.info_robots
    ]
  }

}
