import React, { useState } from "react";

const useInputBind = (defaultValue = "") => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);
  return {
    bind: {
      value,
      onChange,
    },
    setValue,
  };
};

export default useInputBind;
