import * as yup from 'yup';

export const roleValidationSchema = yup.object().shape({

  value: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  description: yup
    .string()
    .min(4, 'the value is too short')
    .max(50, 'the value is too long')
    .required('field is required please !'),

  resources: yup.array()
    .of(
      yup.object().shape({
        name: yup.string(),
        read: yup.boolean(),
        write: yup.boolean(),
        create: yup.boolean(),
        delete: yup.boolean()
      })
    )
});