import { Spinner } from "react-bootstrap";
import React from 'react';

const CenterSpinner = (props: { isLoad: boolean }) => {
  if (props.isLoad) return (
    <div
      style={{
        inset: 0,
        zIndex: "3",
        display: "flex",
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <Spinner
          animation="border"
          style={{ margin: "auto" }}
        />
      </div>
    </div>
  )
  return null;
}

export default CenterSpinner