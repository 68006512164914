import React, { ReactNode } from "react";
import { Button, Modal } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';

export interface ErrorBoundaryState {
  error: Error | null;
  hasError: boolean;
  errorInfo: React.ErrorInfo | null
}

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    error: null,
    hasError: false,
    errorInfo: null
  }
  constructor(props: Props) {
    super(props);
  }

  static getDerivedStateFromError = (error: Error) => {
    return {
      error: error,
      hasError: true,
      errorInfo: error.message
    };
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      hasError: true,
      errorInfo
    });
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorAlert
          show={this.state.hasError}
          error={this.state.error}
          errorInfo={this.state.errorInfo}
        />
      )
    }

    return (
      <>
        {this.props.children}
      </>
    )
  }
}

interface IErrorAlertProps {
  show: boolean,
  error: Error,
  errorInfo: React.ErrorInfo | null
}

const ErrorAlert = ({ show, error, errorInfo }: IErrorAlertProps) => {
  const onReloadPage = () => window.location.reload();
  return (
    <Modal
      show={show}
      onHide={() => {
        onReloadPage();
      }}
      aria-labelledby="example-custom-modal-styling-title"
      dialogAs={Alert}
      variant='danger'
      centered
    >
      <Alert.Heading>Что-то пошло не так...</Alert.Heading>
      <p>
        {`Error ${error}`}
      </p>
      {errorInfo?.componentStack &&
        <pre>
          {`componentStack: ${errorInfo.componentStack}`}
        </pre>
      }
      <hr />
      <div className="d-flex justify-content-end">
        <Button variant="outline-success" onClick={onReloadPage}>
          Обновить страницу
        </Button>
      </div>
    </Modal>
  )
}

export default ErrorBoundary;