import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import AuthStore from "./auth-store";
import AuthUser from "./auth-user";

export function setupAuthStoreDI(container: Container): Container {
  container.bind<AuthUser>(diConstants.AUTH.AUTH_USER).to(AuthUser);
  container
    .bind<AuthStore>(diConstants.AUTH.AUTH_STORE)
    .to(AuthStore)
    .inSingletonScope();

  return container;
}
