import { makeAutoObservable } from "mobx";
import { AlertType, AlertTypes } from "./type";

export interface IAlertStore {
  show: boolean;
  msg: string;
  type: AlertType;

  showAlert(type: AlertType, msg: string): void;
  closeAlert(): void;
  error(msg: string): void;
  info(msg: string): void;
  success(msg: string): void;
}

class AlertStore implements IAlertStore {
  show = false;
  msg = "";
  type: AlertType = AlertTypes.Error;

  constructor() {
    makeAutoObservable(this);
  }

  showAlert(type: AlertType, msg: string) {
    this.type = type;
    this.msg = msg;
    this.show = true;
  }
  closeAlert() {
    this.show = false;
    this.msg = "";
  }
  error(msg: string) {
    this.type = AlertTypes.Error;
    this.msg = msg;
    this.show = true;
  }
  info(msg: string) {
    this.type = AlertTypes.Info;
    this.msg = msg;
    this.show = true;
  }
  success(msg: string) {
    this.type = AlertTypes.Success;
    this.msg = msg;
    this.show = true;
  }
}

export default AlertStore;
