import * as yup from 'yup';

export type Inputs = {
  login: string,
  email: string,
  password: string,
  fam: string,
  name: string,
  otch: string,
  birthday: string,
  position: string,
  license_key: string,
  license_begin_date: string,
  license_end_date: string,
};

export const userEditSchema = yup.object().shape({

  password: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .optional()
    .min(4, 'Password is too short')
    .matches(/(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z]).*$/, 'Password can only contain Latin letters A-Z, a-z, 0-9'),


  fam: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  name: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  otch: yup
    .string()
    .min(4, 'the value is too short')
    .max(20, 'the value is too long')
    .required('field is required please !'),

  birthday: yup
    .date()
    .required('field is required please !'),

  position: yup
    .string()
    .max(20, 'the value is too long')
    .required('field is required please !'),

  user_lexema8: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .uuid('field have uuid type')
    .optional(),

  license_key: yup
    .string()
    .transform((curr, orig) => orig === '' ? null : curr)
    .nullable(true)
    .uuid('field have uuid type')
    .optional(),

  license_begin_date: yup
    .date()
    .required('field is required please !'),

  license_end_date: yup
    .date()
    .required('field is required please !'),
});
