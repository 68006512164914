import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import WsClient from "./ws-client";

function setupWebSocketDI(container: Container) {
  container
    .bind<WsClient>(diConstants.WS)
    .to(WsClient)
    .inSingletonScope();

  return container;
}

export default setupWebSocketDI;
