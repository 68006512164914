import { makeAutoObservable } from "mobx";

export interface IRoleModel {
  id: number;
  value: string;
  description: string;
  roleResources: IRoleResource[];
}

export interface IRoleResource {
  id: number;
  roleId: number;
  resourceId: number;
  read: boolean;
  write: boolean;
  create: boolean;
  delete: boolean;
  resource: IResource
}

interface IResource {
  id: number,
  name: string
}

export default class RoleModel {
  id: IRoleModel["id"] = 0;
  value: IRoleModel["value"] = "";
  description: IRoleModel["description"] = "";
  roleResources: IRoleResource[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IRoleModel): RoleModel {
    this.id = json.id;
    this.value = json.value;
    this.description = json.description;
    this.roleResources = json.roleResources.map((r) => r)
    return this;
  }
}
