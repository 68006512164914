import { makeAutoObservable } from "mobx";
import { Optional } from "../../types";

export interface IInfoRobotDetail {
  id: string;
  id_info_robots: string;
  state: string;
  percent: number;
  // current_percent: string;
  current_date: string;
  comment: string;
  status: boolean;
  error: string;
  module_name: Optional<string>;
}

export default class InfoRobotDetail implements IInfoRobotDetail {
  id: IInfoRobotDetail["id"] = "";
  id_info_robots: IInfoRobotDetail["id_info_robots"] = "";
  state: IInfoRobotDetail["state"] = "";
  percent: IInfoRobotDetail["percent"] = 0;
  // current_percent: IInfoRobotDetail["current_percent"] = "";
  current_date: IInfoRobotDetail["current_date"] = "";
  comment: IInfoRobotDetail["comment"] = "";
  status: IInfoRobotDetail["status"] = false;
  error: IInfoRobotDetail["error"] = "";
  module_name: IInfoRobotDetail["module_name"] = "";

  constructor() {
    makeAutoObservable(this);
  }

  fromJSON(json: IInfoRobotDetail): InfoRobotDetail {
    this.id = json.id;
    this.id_info_robots = json.id_info_robots;
    this.state = json.state;
    this.percent = json.percent;
    // this.current_percent = json.current_percent;
    this.current_date = json.current_date;
    this.comment = json.comment;
    this.status = json.status;
    this.error = json.error;
    this.module_name = json.module_name;

    return this;
  }
}
