import { observer } from "mobx-react-lite";
import React from "react";
import { Card, Nav } from "react-bootstrap";
import { useStore } from "../../../providers/StoreContext";
import ArrowDown from '../../../icons/ArrowDown.png';
import ArrowRight from '../../../icons/ArrowRight.png';

interface GroupBreadcrumbsProps {
  pathNames: string[];
  setPathNames: (paths: Array<string>) => void;
}

const style = {
  height: '1rem',
  marginLeft: '1.5rem'
}

function GroupsPageBreadcrumb({
  pathNames,
  setPathNames,
}: GroupBreadcrumbsProps) {
  const store = useStore();
  const path = store.groups.breadcrumbs.paths;

  function toTopLevel() {
    store.groups.breadcrumbs.setPath([]);
    setPathNames([]);
  }

  function onBreadcrumbClick(index: number) {
    store.groups.breadcrumbs.setPath(
      store.groups.breadcrumbs.paths.slice(0, index + 1)
    );
    setPathNames(pathNames.slice(0, index + 1));
  }

  const isInRoot = !pathNames.length;

  return (
    <Card.Header>
      <Nav variant="tabs">
        <Nav.Item onClick={toTopLevel}>
          <Nav.Link
            active={isInRoot}
          >
            Список групп
            <img
              style={style}
              src={isInRoot ? ArrowDown : ArrowRight}
            />
          </Nav.Link>
        </Nav.Item>
        {pathNames.map((pathName, index) => {
          const isActive = pathNames[pathNames.length - 1] === pathName;
          return (
            <Nav.Item
              key={path[index]}
              onClick={() => onBreadcrumbClick(index)}
            >
              <Nav.Link active={isActive}>
                {pathName}
                <img
                  style={style}
                  src={isActive ? ArrowDown : ArrowRight}
                />
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
    </Card.Header>
  )
}

export default observer(GroupsPageBreadcrumb);
