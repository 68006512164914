import React, { useState } from 'react';
import { Alert, Button, Dropdown, Form, Modal } from 'react-bootstrap';
import { IRoleModel } from '../../../../../store/roles';
import UserLogo from '../../../../../icons/User.png'
import { useStore } from '../../../../../providers/StoreContext';

interface AddRoleModalProps {
  show: boolean,
  onHide: () => void,
  userId: string
}
const AddRoleModal = ({ show, onHide, userId }: AddRoleModalProps) => {
  const store = useStore();
  const roles = store.roles.roles;
  const [error, setError] = useState<string | null>(null);
  const [selectedRole, setSelectedRole] = useState<IRoleModel | null>(null);
  const close = () => {
    onHide();
    setSelectedRole(null);
  }

  const handleAdd = () => {
    if (selectedRole) store.users.addRole(userId, selectedRole)
      .then(() => close())
      .catch((err) => {
        const text = err.response?.data.message ?? err.message;
        setError(text);
      });
  }
  return (
    <Modal
      show={show}
      onHide={close}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Добавить роль
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error &&
          <Alert variant="danger" dismissible onClose={() => setError(null)}>
            {error}
          </Alert>
        }
        <Form>
          <p>Выберите роль</p>
          <Dropdown className="mt-2 mb-2">
            <Dropdown.Toggle size='sm' variant='outline-primary'>
              {selectedRole?.value || 'Выберите роль'}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!roles || !roles.length ?
                <div>Ничего не найдено</div>
                :
                roles.map(r =>
                  <Dropdown.Item
                    onClick={() => setSelectedRole(r)}
                    key={r.value}
                  >
                    <img
                      src={UserLogo}
                      height={20}
                      style={{ marginRight: '5px' }}
                    />
                    {r.value}
                  </Dropdown.Item>
                )
              }
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-danger" onClick={close}>Закрыть</Button>
        <Button
          disabled={!selectedRole}
          variant="success"
          onClick={handleAdd}
        >
          Отправить
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AddRoleModal;