import { Container } from "@owja/ioc";
import { diConstants } from "../../di/di.constants";
import InfoRobot from "./info-robot";
import InfoRobotDetail from "./info-robot-detail";
import InfoRobotsStore from "./info-robot-store";

export function setupInfoRobotsStoreDI(container: Container): Container {
  container
    .bind<InfoRobotDetail>(diConstants.INFO_ROBOTS.INFO_ROBOT_DETAIL)
    .to(InfoRobotDetail);
  container.bind<InfoRobot>(diConstants.INFO_ROBOTS.INFO_ROBOT).to(InfoRobot);
  container
    .bind<InfoRobotsStore>(diConstants.INFO_ROBOTS.INFO_ROBOT_STORE)
    .to(InfoRobotsStore);

  return container;
}
